import React, { createContext, useReducer, useEffect, useState } from 'react';
import axios from 'axios';
import humps from 'humps';
import { API } from '../backend';
// -----------------------------------------------------------

const ReportingContext = createContext();
const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
const grpArr = loggedUserData?.groups?.map((obj) => obj.id);

const handlers = {
  USERS_LEVEL: (state, action) => {
    // console.log(action.payload);
    const userLevels = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      userLevels
    };
  },
  GET_CUSTOM_DASHBOARD_DATA: (state, action) => {

    const customDashboardData = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      customDashboardData
    };
  },
  GET_GASHBOARD_DATA: (state, action) => {

    const dashboardData = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      dashboardData
    };
  },
  GET_VISULAIZATION_DATA: (state, action) => {

    const visulaizationData = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      visulaizationData
    };
  },
  GET_MULTIPLE_VISULAIZATION_DATA: (state, action) => {
    console.log(state)
    console.log(action.payload)
    const newVisulaizationData = action.payload; // New data from the API
    const existingVisulaizationData = state.multipleVisulaizationData || []; // Existing data
    console.log(existingVisulaizationData)

    return {
      ...state,
      isAuthenticated: true,
      multipleVisulaizationData: [...existingVisulaizationData, ...newVisulaizationData] // Append new data to existing data
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

export const ReportingProvider = ({ children }) => {
  const accessToken = window.localStorage.getItem('accessToken');
  const initialState = {
    userLevels: [],
    groups: [],
    Teams: [],
    allUsers: [],
    multipleVisulaizationData: []
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const [showPreviewComponent, setShowPreviewComponent] = useState(false);
  const [phraseBuilderObj, setPhraseBuilderObj] = useState({
    builder: []
  });
  const [eventDialogOpen, setEventDialogOpen] = useState(false);
  const [exploreDialogOpen, setExploreDialogOpen] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState();
  const [selectedToDate, setSelectedToDate] = useState();
  const [selectedDateRange, setSelectedDateRange] = useState();
  const [indDashboardData, setIndDashboardData] = useState();

  // Create new dashboard
  const createNewDashboard = async (name) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const response = await axios.post(`${API}/reporting_dashboard/dashboard`, JSON.stringify({ name }), {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    });
  };

  // Get dashboard table data
  const getDashboardData = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const response = axios.get(`${API}/reporting_dashboard/dashboard`, {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      const { count, pageRange, results  } = humps.camelizeKeys(response.data);
      dispatch({
        type: 'GET_GASHBOARD_DATA',
        payload: { count, pageRange, results }
      });
    })
      .catch((error) => {
        console.error(error)
      })
  }

  // Delete User
  const deleteDashboard = async (dashboardId) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const response = await axios.delete(`${API}/reporting_dashboard/dashboard/${dashboardId}`, {
      headers: {
        Authorization: `Token ${accessToken}`
      }
    });
  };

  // Run Filters
  const runFilters = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');

    console.log(obj)
    // Check if obj is empty
    if (!obj || Object.keys(obj).length === 0) {
      dispatch({
        type: 'GET_VISULAIZATION_DATA',
        payload: [] // Dispatch empty payload
      });
      return; // Exit the function early
    }

    const response = await axios.post(`${API}/reporting_dashboard/dashboard/get_data`, JSON.stringify(obj), {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      console.log(response);
      const { data } = humps.camelizeKeys(response.data);
      // Access the first 10 objects
      const firstFiveObjects = data?.slice(0, 10);

      // console.log(firstFiveObjects);
      // console.log(firstFiveObjects);
      dispatch({
        type: 'GET_VISULAIZATION_DATA',
        payload: data
      });
    })
      .catch((error) => {
        console.error(error)
      })
  };




  // Save dashboard
  const saveDashboard = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const response = await axios.post(`${API}/reporting_dashboard/dashboard/save_graph`, JSON.stringify(obj), {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      console.log(response);
      // const { data } = humps.camelizeKeys(response.data);
      // Access the first 10 objects
      // const firstFiveObjects = data?.slice(0, 10);

      // console.log(firstFiveObjects);
      // console.log(firstFiveObjects);
      // dispatch({
      //   type: 'GET_VISULAIZATION_DATA',
      //   payload: data
      // });
    })
      .catch((error) => {
        console.error(error)
      })
  };



  // Get custom dashboard data
  const getCustomDashboardData = async (dashboardId) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const data = axios.get(`${API}/reporting_dashboard/dashboard/get_saved_graph?dashboard_id=${dashboardId}`, {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      console.log(response);
      // const { data } = humps.camelizeKeys(response);
      const { data } = response;
      dispatch({
        type: 'GET_CUSTOM_DASHBOARD_DATA',
        payload: data
      });
      return data;
      // dispatch({
      //   type: 'GET_CUSTOM_DASHBOARD_DATA',
      //   payload: data
      // });
    })
      .catch((error) => {
        console.error(error)
      })

    return data;
  }

  // Run Multiple Filters
  const runMultipleFilters = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const data = axios.post(`${API}/reporting_dashboard/dashboard/get_data`, obj, {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      console.log(response);
      const { data } = humps.camelizeKeys(response.data);
      return data;
      // dispatch({
      //   type: 'GET_CUSTOM_DASHBOARD_DATA',
      //   payload: data
      // });
    })
      .catch((error) => {
        console.error(error)
      })

    return data;

    // try {
    //   const response = await axios.post(
    //     `${API}/reporting_dashboard/dashboard/get_data`,
    //     obj, // No need to stringify, axios will do it
    //     {
    //       headers: {
    //         'Authorization': `Token ${accessToken}`,
    //         'Content-Type': 'application/json'
    //       }
    //     }
    //   );

    //   const { data } = humps.camelizeKeys(response.data);

    //   // dispatch({
    //   //   type: 'GET_MULTIPLE_VISULAIZATION_DATA',
    //   //   payload: data
    //   // });

    //   return data; // Return the data for potential further use
    // } catch (error) {
    //   console.error('Error in runMultipleFilters:', error);
    //   throw error; // Re-throw the error for the caller to handle
    // }


    // return data;
  };

  // Delete custom dashboard panel
  const deletePanel = async (panelId) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const response = await axios.delete(`${API}/reporting_dashboard/dashboard/delete_graph?id=${panelId}`, {
      headers: {
        'Authorization': `Token ${accessToken}`
      }
    });
  };


  // Run Filters
  const getScDropdownValues = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');

    console.log(obj)

    const data = await axios.post(`${API}/reporting_dashboard/dashboard/get_data?filter=true`, JSON.stringify(obj), {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      console.log(response);
      const { data } = humps.camelizeKeys(response.data);

      return data;

    })
      .catch((error) => {
        console.error(error)
      })
    return data;
  };

  // ----------------------------------------------------------------------------------------------

  return (
    <ReportingContext.Provider
      value={{
        dashboardData: state.dashboardData,
        customDashboardData: state.customDashboardData,
        visulaizationData: state.visulaizationData,
        multipleVisulaizationData: state.multipleVisulaizationData,
        showPreviewComponent,
        setShowPreviewComponent,
        eventDialogOpen,
        setEventDialogOpen,
        phraseBuilderObj,
        setPhraseBuilderObj,
        createNewDashboard,
        getDashboardData,
        exploreDialogOpen,
        setExploreDialogOpen,
        selectedFromDate,
        setSelectedFromDate,
        selectedToDate,
        setSelectedToDate,
        selectedDateRange,
        setSelectedDateRange,
        runFilters,
        deleteDashboard,
        runMultipleFilters,
        saveDashboard,
        getCustomDashboardData,
        indDashboardData,
        setIndDashboardData,
        deletePanel,
        getScDropdownValues
      }}
    >
      {children}
    </ReportingContext.Provider>
  );
};

export default ReportingContext;
