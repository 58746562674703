import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material'; // Ensure these imports are present
import '../assets/NewYearCountdown.css';


const NewYearCountdown = () => {
  const [time, setTime] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
    isNewYear: false,
  });

  useEffect(() => {
    const calculateTimeLeft = (endtime) => {
      const t = Date.parse(endtime) - Date.parse(new Date());
      const seconds = Math.floor((t / 1000) % 60);
      const minutes = Math.floor((t / 1000 / 60) % 60);
      const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
      const days = Math.floor(t / (1000 * 60 * 60 * 24));
      return {
        total: t,
        days,
        hours,
        minutes,
        seconds,
      };
    };

    const today = new Date();
    let deadline = `January 1 ${today.getFullYear() + 1} 00:00:00`;
    if (today.getMonth() === 0 && today.getDate() === 1) {
      deadline = `January 1 ${today.getFullYear()} 00:00:00`;
    }

    const interval = setInterval(() => {
      const t = calculateTimeLeft(deadline);

      // console.log("new year", t)
      if (t.total <= 0) {
        clearInterval(interval);
        const now = new Date();
        const yearStr = now.getFullYear().toString();
        setTime({
          isNewYear: true,
          days: yearStr[0],
          hours: yearStr[1],
          minutes: yearStr[2],
          seconds: yearStr[3],
        });

      } else {
        setTime({
          days: t.days,
          hours: t.hours,
          minutes: `${t.minutes}`.padStart(2, '0'),
          seconds: `${t.seconds}`.padStart(2, '0'),
          isNewYear: false,
        });
        // setTime({
        //   isNewYear: true,
        //   days: '00', // Set to '00' or any value you want to simulate
        //   hours: '00',
        //   minutes: '00',
        //   seconds: '00',
        // });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="wrapper">
      <div className="text-center">
        {/* {time.isNewYear ? ( */}
        <img
          src="/static/illustrations/2015-new.gif"
          width="100px"
          height="70px"
          alt="2015"
        />
        {/* ) : (
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={3}>
              <div className="clock">
                <div className="well top-pane">
                  <div id="days" className="num" >{time.days}</div>
                </div>
                <div className="well bottom-pane">
                  <div id="days-text" className="text">
                    {time.isNewYear ? 'Happy' : 'Days'}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="clock">
                <div className="well top-pane">
                  <div id="hours" className="num">{time.hours}</div>
                </div>
                <div className="well bottom-pane">
                  <div id="hours-text" className="text">
                    {time.isNewYear ? 'New' : 'Hours'}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="clock">
                <div className="well top-pane">
                  <div id="mins" className="num">{time.minutes}</div>
                </div>
                <div className="well bottom-pane">
                  <div id="mins-text" className="text">
                    {time.isNewYear ? 'Year' : 'Minutes'}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={3} >
              <div className="clock">
                <div className="well top-pane">
                  <div id="secs" className="num">{time.seconds}</div>
                </div>
                <div className="well bottom-pane">
                  <div id="secs-text" className="text">
                    {time.isNewYear ? '!!!' : 'Seconds'}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        )} */}
      </div>
    </div>
  );
};

export default NewYearCountdown;
