import PropTypes from 'prop-types';
// material
import { Paper, Typography, Box, Stack } from '@material-ui/core';
import NoDataIcon from '../assets/custom_icon/no-data-icon.svg';

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string
};

export default function SearchNotFound({ searchQuery = '', ...other }) {
  return (
    <Paper {...other}>
      <Stack direction='row' alignItems='center' justifyContent='center' sx={{ mb: 1 }}>
        <Box component='img' alt="Custom SVG Icon" src={NoDataIcon} sx={{ width: { lg: '35px', newLg: '35px', xl: '45px' }, height: { lg: '35px', newLg: '35px', xl: '45px' } }} />&nbsp;
        <Typography align="center" sx={{ fontWeight: 'bold', fontSize: { lg: '14px', xl: '16px' } }}>
          No Data Found
        </Typography>
      </Stack>

      {(searchQuery && searchQuery !== '') && <><Typography fontStyle='italic' sx={{ fontSize: { lg: '13.5px', xl: '16px' } }} align="center">
        No results found for &nbsp;
        <strong>&quot;{searchQuery}&quot;</strong>. Try checking for typos or using complete words.
      </Typography></>}
    </Paper>
  );
}
