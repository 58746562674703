// import { Navigate } from "react-router-dom";
// ----------------------------------------------------------------------


export function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';
const ROOTS_EMP = '/employee';
const ROOTS_SUP = '/supervisor';
const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));





// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  mustChangePassword: path(ROOTS_AUTH, '/must-change-password'),
  verify: path(ROOTS_AUTH, '/verify')
};


export const PATH_EMP = {
  root: ROOTS_EMP,
  dashboard: path(ROOTS_EMP, '/dashboard'),
  scores: path(ROOTS_EMP, '/scores'),
  selfscore: path(ROOTS_EMP, '/selfscore'),
  tasks: path(ROOTS_EMP, '/tasks'),
  comments: path(ROOTS_EMP, '/comments'),
  activity: path(ROOTS_EMP, '/activity'),
  goals: path(ROOTS_EMP, '/goals'),
  coaching: {
    index: path(ROOTS_EMP, '/coaching'),
    viewCoaching: path(ROOTS_EMP, '/coaching/view_coaching_session'),
    AgentReport: path(ROOTS_EMP, '/coaching/agent_report_coaching'),
    createCoaching: path(ROOTS_EMP, '/coaching/create_coaching_session')
  },
  // verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_SUP = {
  root: ROOTS_SUP,
  dashboard: path(ROOTS_SUP, '/dashboard')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
  userProfile: '/userProfile',
  companySettings: '/companySettings',
  support: path(ROOTS_DASHBOARD, `/support`)

};

export const PATH_DASHBOARD = {
  // root: ROOTS_DASHBOARD,
  root: path(ROOTS_DASHBOARD, '/dashboard'),
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
    evaluatorDashboard: path(ROOTS_DASHBOARD, '/evaluator_dashboard'),
    billing: path(ROOTS_DASHBOARD, '/billing'),
    // login: path(ROOTS_DASHBOARD, '/login'),
    score: {
      index: path(ROOTS_DASHBOARD, '/score'),
      selectGroup: path(ROOTS_DASHBOARD, '/score/selectgroup'),
      selectEmployee: path(ROOTS_DASHBOARD, '/score/select_emp'),
      selectScorecard: path(ROOTS_DASHBOARD, '/score/select_scorecard'),
    },
    coaching: {
      index: path(ROOTS_DASHBOARD, '/coaching'),
      viewCoaching: path(ROOTS_DASHBOARD, '/coaching/view_coaching_session'),
      AgentReport: path(ROOTS_DASHBOARD, '/coaching/agent_report_coaching'),
      createCoaching: path(ROOTS_DASHBOARD, '/coaching/create_coaching_session')
    },
    calibration: {
      'index': path(ROOTS_DASHBOARD, '/calibration'),
      'addList': path(ROOTS_DASHBOARD, '/calibration/list_builder'),
      'calibrate': path(ROOTS_DASHBOARD, '/calibration/calibrate'),
      'editCalibateList': path(ROOTS_DASHBOARD, '/calibration/edit_calibrate_list'),
      'editCalibratePerformance': path(ROOTS_DASHBOARD, '/calibration/edit_calibrate_performance'),
      'calibratePerformance': path(ROOTS_DASHBOARD, '/calibration/calibrate_performance'),
      'report': path(ROOTS_DASHBOARD, '/calibration/calibrate_report'),
      'draftCalibrate': path(ROOTS_DASHBOARD, '/calibration/selectDraft'),
      'viewCalibratePerformance': path(ROOTS_DASHBOARD, '/calibration/view_calibrate_performance')
    },
    scoreGrp: path(ROOTS_DASHBOARD, '/score/selectgroup'),
    scoreEmp: path(ROOTS_DASHBOARD, '/score/select_emp'),
    scoreScrCrd: path(ROOTS_DASHBOARD, '/score/select_scorecard'),
    Staff: path(ROOTS_DASHBOARD, '/staff'),
    emp: path(ROOTS_DASHBOARD, '/staff?tab=employees'),
    Scorecards: path(ROOTS_DASHBOARD, '/scorecards'),
    admin: path(ROOTS_DASHBOARD, '/admin'),
    adminUsers: path(ROOTS_DASHBOARD, '/admin?tab=Users'),
    adminDevelopers: path(ROOTS_DASHBOARD, '/admin?tab=Developers'),
    editScorecard: path(ROOTS_DASHBOARD, '/scorecards/editScoreCard'),
    scorecardArchive: path(ROOTS_DASHBOARD, '/scorecards/scorecardArchive'),
    scorecardCategory: path(ROOTS_DASHBOARD, '/scorecards/scorecardCategory'),
    scorecardSection: path(ROOTS_DASHBOARD, '/scorecards/scorecardSection'),
    scorecardComments: path(ROOTS_DASHBOARD, '/scorecards/scorecardComments'),
    scorecardCauses: path(ROOTS_DASHBOARD, '/scorecards/scorecardCauses'),
    scorecardCustomFields: path(ROOTS_DASHBOARD, '/scorecards/scorecardCustomFields'),
    scorecardSettings: path(ROOTS_DASHBOARD, '/scorecards/scorecardSettings'),
    editCustomFields: path(ROOTS_DASHBOARD, '/scorecards/editCustomFields'),
    questionDetails: path(ROOTS_DASHBOARD, '/scorecards/questionDetails'),
    scorecardPreview: path(ROOTS_DASHBOARD, '/scorecards/scorecardPreview'),
    scorecardEventTypes: path(ROOTS_DASHBOARD, '/scorecards/scorecardEventTypes'),
    reports: path(ROOTS_DASHBOARD, '/reports'),
    reportsFromSnapshot: path(ROOTS_DASHBOARD, '/reports?tab=Snapshot'),
    reportsFromStaffReview: path(ROOTS_DASHBOARD, '/reports?tab=Staffreview'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    editScore: path(ROOTS_DASHBOARD, '/scorecards/editScoreScoreLog'),
    viewSnapshot: path(ROOTS_DASHBOARD, '/scorecards/viewSnapshot'),
    reportResultDetails: path(ROOTS_DASHBOARD, '/analytics/ReportResultDetails'),
    reportResultIndDetails: path(ROOTS_DASHBOARD, '/analytics/ReportResultIndDetails'),
    questionResultDetails: path(ROOTS_DASHBOARD, '/analytics/QuetionResultDetails'),
    overallStaffTrendReportInd: path(ROOTS_DASHBOARD, '/staff/OverallStaffTrendReportInd'),
    staffTrendReport: path(ROOTS_DASHBOARD, '/analytics/staff/staffTrendReport'),
    sectionTrendReport: path(ROOTS_DASHBOARD, '/analytics/scorecards/sectionTrendReport'),
    setionTrendDetailsTable: path(ROOTS_DASHBOARD, '/analytics/scorecards/SetionTrendDetailsTable'),
    teamTrendStaffResultReport: path(ROOTS_DASHBOARD, '/analytics/team/staffTrendsByStaffResult'),
    teamTrendTeamSupervisorReport: path(ROOTS_DASHBOARD, '/analytics/team/teamSupervisor'),
    teamTrendTeamReport: path(ROOTS_DASHBOARD, '/analytics/team/overAllTeamsTrends'),
    causeTrendReport: path(ROOTS_DASHBOARD, '/analytics/scorecards/causesTrendDetails'),
    reportsFromCompliance: path(ROOTS_DASHBOARD, '/reports?tab=ComplianceReport'),
    complianceSection: path(ROOTS_DASHBOARD, '/reports/complianceBySection'),
    complianceSectionDetails: path(ROOTS_DASHBOARD, '/reports/complianceSectionDetails'),
    scorecardNonNumeric: path(ROOTS_DASHBOARD, '/scorecards/scorecardNonNumeric'),
    analyticsStaffs: {
      'subModule1': path(ROOTS_DASHBOARD, '/analytics/staff/StaffTrendReport'),
      'subModule2': path(ROOTS_DASHBOARD, '/analytics/staff/QuestionByStaff'),
      'subModule3': path(ROOTS_DASHBOARD, '/analytics/staff/AnswersByStaff'),
      'subModule4': path(ROOTS_DASHBOARD, '/analytics/staff//OverallStaffTrendReportInd'),
      'subModule5': path(ROOTS_DASHBOARD, '/analytics/staff/section_trend_individual'),
      'subModule6': path(ROOTS_DASHBOARD, '/analytics/staff/question_trend_individual'),
      'subModule7': path(ROOTS_DASHBOARD, '/analytics/staff/SectionByResult'),
      'subModule8': path(ROOTS_DASHBOARD, '/analytics/staff/SectionByEventType'),
      'subModule9': path(ROOTS_DASHBOARD, '/analytics/staff/kudos_by_staff'),
      'subModule10': path(ROOTS_DASHBOARD, '/analytics/staff/causes_by_staff'),
      'subModule11': path(ROOTS_DASHBOARD, '/analytics/staff/acceptance_by_agent'),
    },
    analyticsSupervisors: {
      'subModule1': path(ROOTS_DASHBOARD, '/analytics/supervisor/supervisor_by_section'),
      'subModule2': path(ROOTS_DASHBOARD, '/analytics/supervisor/points_of_failure_supervisors'),
      'subModule3': path(ROOTS_DASHBOARD, '/analytics/supervisor/supervisor_by_question'),
      'subModule4': path(ROOTS_DASHBOARD, '/analytics/supervisor/result_acceptence_by_supervisor')
    },
    analyticsEvaluation: {
      'subModule1': path(ROOTS_DASHBOARD, '/analytics/evaluation/scoring_patterns'),
      'subModule2': path(ROOTS_DASHBOARD, '/analytics/evaluation/evaluation_per_period'),
      'subModule3': path(ROOTS_DASHBOARD, '/analytics/evaluation/evaluation_per_scorecard'),
      'subModule4': path(ROOTS_DASHBOARD, '/analytics/evaluation/evaluation_per_scorecard_by_staff'),
      'subModule5': path(ROOTS_DASHBOARD, '/analytics/evaluation/evaluation_per_scorecard_evaluator'),
      'subModule6': path(ROOTS_DASHBOARD, '/analytics/evaluation/evaluation_review_by_evaluator'),
      'subModule7': path(ROOTS_DASHBOARD, '/analytics/evaluation/evaluation_review_by_supervisor'),
    },
    analyticsScoreCards: {
      'subModule1': path(ROOTS_DASHBOARD, '/analytics/scorecards/overall_trend'),
      'subModule2': path(ROOTS_DASHBOARD, '/analytics/scorecards/event_type_trend'),
      'subModule3': path(ROOTS_DASHBOARD, '/analytics/scorecards/performance_against_target'),
      'subModule4': path(ROOTS_DASHBOARD, '/analytics/scorecards/question_trend'),
      'subModule5': path(ROOTS_DASHBOARD, '/analytics/scorecards/sectionTrendReport'),
      'subModule6': path(ROOTS_DASHBOARD, '/analytics/scorecards/comments_scorecard'),
      'subModule7': path(ROOTS_DASHBOARD, '/analytics/scorecards/CausesByScorecard'),
      'subModule8': path(ROOTS_DASHBOARD, '/analytics/scorecards/causesTrend'),
      'subModule9': path(ROOTS_DASHBOARD, '/analytics/scorecards/compliance_by_question'),
      'subModule10': path(ROOTS_DASHBOARD, '/analytics/scorecards/data_tag_by_eventType'),
      'subModule11': path(ROOTS_DASHBOARD, '/analytics/scorecards/customer_reference_by_data_tags'),

    },
    analyticsTeams: {
      'subModule1': path(ROOTS_DASHBOARD, '/analytics/team/overAllTeamsTrends'),
      'subModule2': path(ROOTS_DASHBOARD, '/analytics/team/pointsOfFailureByTeam'),
    },
    analyticsGroups: {
      'subModule1': path(ROOTS_DASHBOARD, '/analytics/group/summary'),
      'subModule2': path(ROOTS_DASHBOARD, '/analytics/group/scorecard_categroies'),
      'subModule3': path(ROOTS_DASHBOARD, '/analytics/group/points_of_failure'),
      'subModule4': path(ROOTS_DASHBOARD, '/analytics/group/customer_reference'),
      'subModule5': path(ROOTS_DASHBOARD, '/analytics/group/compliance_overview'),
      'subModule6': path(ROOTS_DASHBOARD, '/analytics/group/calibration_trend'),
      'subModule7': path(ROOTS_DASHBOARD, '/analytics/group/acceptance_by_group'),

    }
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  },
  report: {
    complianceResults: path(ROOTS_DASHBOARD, '/reports/complianceResults'),
  },
  beyondqaAi: {
    events: path(ROOTS_DASHBOARD, '/events'),
    archivedEvents: path(ROOTS_DASHBOARD, '/events/archivedEvents'),
    eventsCategory: path(ROOTS_DASHBOARD, '/events/eventsCategory'),
    processEvents: path(ROOTS_DASHBOARD, '/events/processEvents'),
    calls: path(ROOTS_DASHBOARD, '/calls'),
    analyze: path(ROOTS_DASHBOARD, '/analyze?tab=one'),
    autoqa: path(ROOTS_DASHBOARD, '/auto_qa_rules'),
    coaching: path(ROOTS_DASHBOARD, '/coaching?csId=CD'),
    assignment: path(ROOTS_DASHBOARD, '/assignment')
  }
};

switch (loggedUserData?.userLevel?.name) {
  case 'Calibrate Only':
    PATH_DASHBOARD.general.dashboard = path('/', `/calibration`);
    break;
  case 'Score, Calibrate, Report & Analytics':
    PATH_DASHBOARD.general.dashboard = path('/', `/score/selectgroup`);
    break;
  case 'Score, Reports & Analytics':
    PATH_DASHBOARD.general.dashboard = path('/', `/score/selectgroup`);
    break;

  case 'Reports & Analytics':
    PATH_DASHBOARD.general.dashboard = path('/', `/reports`);
    break;
  case 'Reports Only':
    PATH_DASHBOARD.general.dashboard = path('/', `/reports`);
    break;
  case 'Score Only':
    PATH_DASHBOARD.general.dashboard = path('/', `/score/selectgroup`);
    break;
  case "Score & Calibrate":
    PATH_DASHBOARD.general.dashboard = path('/', `/score/selectgroup`);
    break;
  case "Score & Reports":
    PATH_DASHBOARD.general.dashboard = path('/', `/score/selectgroup`);
    break;


  default:
    if (!loggedUserData?.staff) {
      PATH_DASHBOARD.general.dashboard = path('/', `/dashboard`);
    } else {
      if (loggedUserData?.staff?.staffType === 10) {
        PATH_DASHBOARD.general.dashboard = path('/', `/employee/dashboard`);
      }
      if (loggedUserData?.staff?.staffType !== 10) {
        PATH_DASHBOARD.general.dashboard = path('/', `/supervisor/dashboard`);
      }
    }
    break;
}

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
