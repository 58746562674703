import { useEffect, useState, useCallback, useContext } from 'react';
// REDUX IMPORTS
import { useSelector, useDispatch } from 'react-redux';
// REACT ROUTE IMPORTS
import { Link, useSearchParams } from 'react-router-dom';
// NPM IMPORTS
import _ from 'lodash';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack5';
// MATERIAL IMPORTS
import {
    Box, Button,
    CircularProgress,
    Divider,
    FormControlLabel,
    InputAdornment,
    OutlinedInput,
    Stack, Switch,
    Pagination,
    Table, TableRow, TableBody, TableCell, TableContainer, TablePagination, tableCellClasses, Typography, Toolbar, Tooltip
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@mui/styles';
// COMPONENT IMPORTS
import SortingSelectingHeadScore from './sortSelectingHeadStaff';
import ScoreWrapper from '../../../../scorecomponent/scorewrapper';
import { PATH_DASHBOARD } from "../../../../../routes/paths";
import Scrollbar from '../../../../../components/Scrollbar';
import UsersGroupsCountDialog from '../../dialog/UsersGroupsCountDialog';
// API IMPORTS
import { getGdashboardGroupApi } from "../../../../../_apis_/authApi.js/globalDashboardApi";
import { grpDraftListApi } from "../../../../../_apis_/authApi.js/empapi";
import { getGrpByEmpApi } from '../../../../../_apis_/authApi.js/staffApi';
// CONTEXT IMPORTS
import { StaffContext } from "../../../../../contexts/staffContext";
import { SettingsContext } from "../../../../../contexts/SettingsContext";
import AddUserContext from '../../../../../contexts/AddUserContext';
// REDUX ACTION IMPORTS
import { getAllStaff } from '../../../../../redux/action/staffAction';
import SearchBox from '../../../../Common Component/SearchBox';
import PaginationTable from '../../../../Common Component/PaginationTable';
import GlobalLoaders from '../../../../Common Component/GlobalLoaders';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'ID'
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Employee'
    },
    {
        id: 'external_id',
        numeric: false,
        disablePadding: false,
        label: 'Ext-ID'
    },
    {
        id: 'groups',
        numeric: false,
        disablePadding: false,
        label: 'Groups'
    },
    {
        id: 'teams',
        numeric: false,
        disablePadding: false,
        label: 'Teams'
    },
    {
        id: 'supervisor',
        numeric: false,
        disablePadding: false,
        label: 'Supervisor'
    },
];

const useStyles = makeStyles({
    pagination: {
        '& .MuiTablePagination-actions > button:first-of-type': {
            display: 'none', // Hide the "Previous" button
        },
        '& .MuiTablePagination-actions > button:last-of-type': {
            display: 'none', // Hide the "Next" button
        },
        '& .MuiTablePagination-toolbar': {
            paddingLeft: 1,
        },
    },
    paginationText: {
        color: '#212668', // Change this to the color you want,
        fontWeight: '600 !important'
    }
});


const RootStyle = styled(Toolbar)(({ theme }) => ({
    // height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 150,
    height: 45,
    color: `${theme.palette.functionalityBtn} !important`,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': { width: 150, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
        border: 'none !important',
        color: `${theme.palette.functionalityBtn} !important`, fontWeight: '100 !important'
    },
    '& .MuiOutlinedInput-input::placeholder': {
        color: `${theme.palette.functionalityBtn} !important`, // Change this color to your desired placeholder color,
        fontFamily: 'Nunito, sans-serif'
    }
}));

// ----------------------------------------------------------------------

const buttonStyle = {
    padding: '0px 15px',
    fontWeight: '400',
    marginRight: '5px',
    marginBottom: '2px',
    fontSize: '12px',
    border: '1px solid #676A9D',
    color: '#212668',
    textOverflow: 'ellipsis',
    height: '25px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    minWidth: '100px',
    '&:hover': {
        bgColor: '#D9E2EF', color: '#212668', border: '1px solid #676A9D',
        backgroundColor: 'transparent'
    }
}


export default function SelectEmployeeTable() {
    const { getUserGroupsCount, getUserTeamsCount } = useContext(AddUserContext)
    const classes = useStyles();
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    const grpArr = loggedUserData?.groups?.map(obj => obj.id);
    // REDUX STATES
    const dispatch = useDispatch();
    const employee = useSelector(state => state.staff);
    // REACT ROUTER STATES
    const [searchParams, setSearchParams] = useSearchParams();
    // CONTEXT STATES
    const { dateObj, setGroupList } = useContext(StaffContext);
    const { setColor, themeMode } = useContext(SettingsContext);
    // REACT STATE
    const [draftInfo, setDraftInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(1);
    const [dense, setDense] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [filterName, setFilterName] = useState('');
    const [tableOrder, setTableOrder] = useState(false);
    const [pageRange, setPageRange] = useState(0);
    const [showSelectedItem, setShowSelectedItem] = useState();
    const [selectedUserData, setSelectedUserData] = useState();
    const [openCountingDialog, setOpenCountingDialog] = useState(false);
    const [tabName, setTabName] = useState();

    useEffect(() => {
        setIsLoading(true);
        setData([])
        getData();
        // dispatch(getAllStaff(searchParams.get("id"), 1))
        refreshDashboard();
        if (loggedUserData?.staff?.staffType) {
            getDraftData();
        }
    }, [])

    const getData = async (pg, query) => {
        try {
            const res = await getGrpByEmpApi(searchParams.get("id"), pg || 1, query || '')
            setIsLoading(false);
            setData(res?.data?.results?.map(obj => ({ id: obj.id, fName: obj?.user?.first_name, lName: obj?.user?.last_name, access: obj.is_agent_access, username: obj.user.email, name: `${obj?.user?.first_name} ${obj?.user?.last_name}`, groups: obj.user.groups, external_id: obj.external_id, teams: obj.user.teams, user: { staff_type: obj?.staff_type, teams: obj?.user?.teams?.map(item => ({ id: item?.id, name: item?.name })), name: `${obj?.user?.first_name} ${obj?.user?.last_name}`, supervisor_id: obj?.supervisor?.id }, supervisor: (obj?.supervisor ? `${obj?.supervisor?.first_name} ${obj?.supervisor?.last_name}` : null) })));
            setCount(res?.data?.count)
            setPageRange(res?.data?.page_range?.length)


        } catch {
            setIsLoading(false);
            setData([])
            setPageRange(1);
            setCount(0)
            setPage(1)
        }
    }



    const getDraftData = async () => {
        const { data } = await grpDraftListApi(1, 20);
        setDraftInfo(data?.results);
    }



    const refreshDashboard = () => {
        async function getList() {
            if (dateObj) {
                const { data } = await getGdashboardGroupApi(dateObj?.date1, dateObj?.date2)
                // console.log(data?.data)
                setGroupList(data?.data);
            }

        }
        getList()
    }




    const handleChangePage = (event, newPage) => {
        setData([]);
        setCount(0)
        setIsLoading(true);
        getData(newPage, filterName)
        return setPage(newPage);

    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };


    const sendQuery = (queryText) => {
        if (queryText?.length === 0) {
            return getData(1)
        }
        if (queryText?.length > 0) {
            return getData(1, queryText?.trim());
        }
    }

    const delayedQuery = useCallback(_.debounce((query) => sendQuery(query), 1000), []);

    const handleFilterByName = (event) => {
        setPage(1);
        setData([]);
        setCount(0)
        setIsLoading(true);
        setFilterName(event.target.value);
        delayedQuery(event.target.value);
    };


    const handleTableOrder = () => {
        setData(data.reverse())
        setTableOrder(!tableOrder)
    }


    const getGroupButton = (grp, row) => {
        if (grp?.length > 1) {
            return <Button
                sx={{
                    ...buttonStyle
                }} variant="outlined" color="inherit"
                onClick={() => handleGroupTeams(row, 'group')}

            > {`${grp?.length} Groups`} </Button>
        }

        return grp?.map((obj, i) => {
            if (obj?.name?.length <= 23) {
                return <Button
                    sx={{
                        cursor: 'default',
                        ...buttonStyle

                    }} variant="outlined" color="inherit"

                > {obj?.name} </Button>
            }

            return <Tooltip title={obj?.name}>
                <Button
                    sx={{
                        cursor: 'default',
                        ...buttonStyle

                    }} variant="outlined" color="inherit"


                > {`${obj?.name?.slice(0, 20)}...`} </Button>
            </Tooltip>
        })


    }



    const getTeamButton = (tm, row) => {
        if (tm?.length > 1) {
            return <Button
                sx={{

                    ...buttonStyle

                }} variant="outlined" color="inherit"
                onClick={() => handleGroupTeams(row, 'team')}

            > {`${tm?.length} Teams`} </Button>
        }

        return tm?.map((obj, i) => {
            if (obj?.name?.length <= 23) {
                return <Button
                    sx={{
                        cursor: 'default',
                        ...buttonStyle

                    }} variant="outlined" color="inherit"

                > {obj?.name} </Button>
            }

            return <Tooltip title={obj?.name}>
                <Button
                    sx={{
                        cursor: 'default',
                        ...buttonStyle

                    }} variant="outlined" color="inherit"


                > {`${obj?.name?.slice(0, 20)}...`} </Button>
            </Tooltip>
        })


    }


    const handleGroupTeams = async (userData, fromName) => {
        // console.log('user data from name', userData, fromName)
        setTabName(fromName);
        setOpenCountingDialog(!openCountingDialog);

        if (fromName === 'group') {
            setSelectedUserData({
                firstName: userData?.fName,
                lastName: userData?.lName,
                groups: userData?.groups?.length
            })
            // const data = await getUserGroupsCount(userData?.userId);
            setShowSelectedItem(userData?.groups);
        } else {
            setSelectedUserData({
                firstName: userData?.fName,
                lastName: userData?.lName,
                teams: userData?.teams?.length
            })
            setShowSelectedItem(userData?.teams);
        }
    };

    const closeGroupTeamDialog = (data) => {
        setOpenCountingDialog(data);
    };


    return (
        <>
            <ScoreWrapper title="Select Employees"
                btnType={<SearchBox filterName={filterName}
                    handleFilterByName={handleFilterByName} />}
                fnDraft={getDraftData} draftData={draftInfo} link={[{ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Select Groups", href: (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? `${PATH_DASHBOARD.general.score.selectEmployee}?id=${grpArr?.join(",")}&sup_dash=${searchParams.get("sup_dash")}` : PATH_DASHBOARD.general.scoreGrp }, { name: 'Select Employees' }]}>

                <Scrollbar sx={{ mt: 1 }}>

                    <TableContainer variant='standard' sx={{ minWidth: "800px", py: 2 }}>
                        <Table sx={{ overflowX: 'scroll' }} size={dense ? 'small' : 'medium'}>
                            <SortingSelectingHeadScore
                                headLabel={TABLE_HEAD}
                                numSelected={selected.length}
                                rowCount={data?.length}
                                tableOrder={tableOrder}
                                handleTableOrder={handleTableOrder}
                            />
                            <TableBody>
                                {data?.map((row, index) =>

                                    <TableRow
                                        key={row.id}
                                        sx={{
                                            [`& .${tableCellClasses.root}`]: {
                                                py: 1.35,
                                                borderBottom: (theme) => `1px solid ${(index !== data?.length - 1) ? `${theme.palette.text.primaryBorder}` : 'transparent'}`,
                                            },
                                        }}
                                    >

                                        <TableCell align='center' component="th" scope="row" padding="normal">



                                            <Typography align='center' variant='tableCell'>{row.id}</Typography>



                                        </TableCell >



                                        <TableCell align="center" scope="row" padding="normal">
                                            <Link
                                                to={(loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? `${PATH_DASHBOARD.general.score.selectScorecard}?grpId=${searchParams.get("id")}&empId=${row.id}&sup_dash=True&empTable=${JSON.stringify(row?.user)}` : `${PATH_DASHBOARD.general.score.selectScorecard}?grpId=${searchParams.get("id")}&empId=${row.id}&empTable=${JSON.stringify(row?.user)}`}
                                                style={{ color: '#212668', cursor: 'pointer', textDecoration: 'underline', /* fontSize: '1rem' */ }}
                                            >
                                                <Typography variant='tableCell' >
                                                    {`${row?.fName} ${row?.lName}`}
                                                </Typography>

                                            </Link>
                                        </TableCell>
                                        <TableCell align='center' scope="row" padding="none">

                                            <Typography variant='tableCell' >{row.external_id || ""}</Typography>

                                        </TableCell>
                                        <TableCell align="center">
                                            <Box sx={{ minWidth: "150px" }}>
                                                {row.groups === null ? '' : getGroupButton(row.groups, row)}
                                            </Box></TableCell>
                                        <TableCell align="center">
                                            <Box sx={{ minWidth: "150px" }}>
                                                {row.teams === null ? '' : getTeamButton(row?.teams, row)}
                                            </Box>
                                        </TableCell>

                                        <TableCell align="center">
                                            <Typography noWrap variant='tableCell'>
                                                {row?.supervisor ? row.supervisor : " "}
                                            </Typography>
                                            {/* <div style={{ minWidth: "80px" }}></div> */}
                                        </TableCell>
                                    </TableRow>

                                )}


                            </TableBody>
                        </Table>
                        <>
                            {data?.length === 0 && <GlobalLoaders isLoading={isLoading} query={filterName} />}
                        </>
                    </TableContainer>

                </Scrollbar>
                <PaginationTable
                    count={count || 0}
                    tablePage={(page - 1)}
                    // tablePage={showPage ? cPage - 1 : page - 1} // for tablepagination component
                    pageRange={pageRange}
                    // page={showPage ? cPage : page} // for pagination component
                    page={page}
                    handlePagination={handleChangePage}
                    style={{ ml: 'auto', color: '#212668', fontWeight: 700 }}
                />

            </ScoreWrapper >
            <UsersGroupsCountDialog
                isDialogOpened={openCountingDialog}
                closeGroupTeamDialog={closeGroupTeamDialog}
                selectedRow={showSelectedItem}
                userData={selectedUserData}
                tabHeading={tabName}
                addUserButton={false}
                setAddMode={false}
                disableBackdropClick
            />
        </>
    );
}
