import { useState, useContext, useEffect, useCallback } from 'react';
// REACT REDUX IMPORTS
import { useDispatch } from 'react-redux';
// MATERIAL IMPORTS
import {
  Autocomplete, Avatar,
  Box, Button,
  CircularProgress, Checkbox,
  Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl, FormControlLabel,
  IconButton, InputLabel, InputAdornment,
  List, ListItem, ListItemText,
  MenuItem,
  Stack,
  TextField, Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { Visibility, VisibilityOff, Close, PersonAdd } from '@material-ui/icons';
import AddIcon from '@mui/icons-material/Add';

// FORMIK IMPORT
import { useFormik } from 'formik';
// NPM IMPORTS
import _ from "lodash";
import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack5';
// COMPONENT IMPORTS
import { addStaffApi, editStaffApi, listTeamApi, getStaffCityNameApi, getStaffCountryNameApi } from '../../../../../../_apis_/authApi.js/staffApi';
import { editSup, newSup, delSup, newEmp, getListAllSup } from '../../../../../../redux/action/staffAction';
import { MIconButton } from '../../../../../../components/@material-extend';
import GlobalButton from '../../../../../Common Component/GlobalButton';
// CONTEXT IMPORTS
import { StaffContext } from '../../../../../../contexts/staffContext';
import { SettingsContext } from '../../../../../../contexts/SettingsContext';



export default function SupervisorDialog() {
  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
  const aiEnabled = loggedUserData?.aiEnabled

  // THEME STATE
  const theme = useTheme();
  // CONTEXT STATE
  const { allTeam, setT11, allGroup, getSupervisor, supervisrID, setSupervisrID, supForm, setSupForm, setSup } = useContext(StaffContext);
  const { setColor } = useContext(SettingsContext);
  // REACT STATE
  const [formGroup, setFormGroup] = useState([]);
  const [formTeam, setFormTeam] = useState([]);
  const [teamErr, setTeamErr] = useState("none");
  const [grpErr, setGrpErr] = useState("none");
  const [errExist, setErrExist] = useState("none");
  const [errUserNameExist, setErrUserNameExist] = useState("none");
  const [isVisible, setVisible] = useState(false);
  const [disBtn, setDisBtn] = useState(false);
  const [disField, setDisField] = useState(false);
  const [teamOption, setTeamOption] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [cityDetailsData, setCityDetailsData] = useState([]);
  const [countryDetailsData, setCountryDetailsData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputCountryValue, setInputCountryValue] = useState("");
  const [locationCountryValue, setLocationCountryValue] = useState("");
  const [locationCityValue, setLocationCityValue] = useState("");

  // SNACKBAR STATES
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // REDUX STATES
  const dispatch = useDispatch();

  const checkNameValidation = (value) => {
    if (/^[\s0-9]*$/.test(value)) {
      return '*Field with only numbers are not allowed .'
    }
    if (/^[\s0-9][\sa-zA-Z0-9'-]*$/.test(value)) {
      return '*Field should start with [a-z]'
    }
    return '*Field should not have special characters [!@#$%^&*()]'
  }
  const checkPasswordValidation = (value) => {
    return '*No Spaces are allowed .'
  }

  // FORMIK STATE
  const formik = useFormik({
    initialValues: { email: '', fname: '', lname: '', agentAccess: false, password: '', group: '', team: '', notes: '', extID: "", staff_type: '20', sup: '', isScored: false, readOnly: false, stffAccess: false, location1: null, location2: null, short_username: null, is_ai_access: false },
    validationSchema: Yup.object({
      email: Yup.string().email('invalid email').test((value, { createError }) => {
        if (/[#$%^&*(){}|?/><=+]/.test(value) === false) {
          return true
        }
        return createError({ message: 'invalid email(*no special characters allowed)' })
      }),
      fname: Yup.string().required('*Required').test((value, { createError }) => {

        if (/^[a-zA-Z][\sa-z0-9A-Z'-]*$/.test(value) === false) {

          return createError({ message: checkNameValidation(value) })
        }
        return true
      }),
      lname: Yup.string().required('*Required').test((value, { createError }) => {

        if (/^[a-zA-Z][\sa-z0-9A-Z'-]*$/.test(value) === false) {

          return createError({ message: checkNameValidation(value) })
        }
        return true
      }),
      password: Yup.string().test((value, { createError }) => {
        if (/^[^\s]+$/.test(value) === false) {
          return createError({ message: checkPasswordValidation(value) })
        }
        return true
      }).min(8, "Password should be atleast 8 charaters"),
      location1: Yup.string().required('*Required').nullable(),
      location2: Yup.string().required('*Required').nullable(),
      staff_type: Yup.string().required('*Required'),
      extID: Yup.string().min(4, `External Id should have atleast length of 4`).max(8, `External Id length should not exceed more than 8`).matches(/^[0-9a-zA-Z]{4,8}$/, 'Only AlphaNumeric allowed').nullable()

    }),
    onSubmit: values => {
      if (formGroup?.length === 0) {
        return setGrpErr("block");
      }
      if (formTeam?.length === 0) {
        return setTeamErr("block")
      }

      async function edit() {
        setDisBtn(true);
        try {
          const newObj = {
            user: {
              first_name: values.fname,
              last_name: values.lname,
              username: values.email.toLowerCase(),
              email: values.email.toLowerCase(),
              password: values.password || null,
              city: values.location2 === '' ? null : values.location2,
              short_username: !values.short_username ? '' : values.short_username?.trim().toLowerCase(),
              country: values.location1 === '' ? null : values.location1,
              is_ai_access: values.is_ai_access
            },
            notes: values.notes?.trim(),
            groups: formGroup,
            teams: formTeam,
            staff_type: values.staff_type,
            external_id: values.extID === '' ? null : values?.extID,
            // is_agent_access: true,
            can_scored: values.isScored,
            is_agent_access: values?.agentAccess,
            staff_access: values?.stffAccess,
            read_only: values.readOnly,
          }
          const res = await editStaffApi(newObj, supervisrID);
          if (res?.data?.staff_type === 10) {
            dispatch(delSup((res?.data?.id)));
            dispatch(newEmp(res?.data));
            dispatch(getListAllSup());
          }
          else {
            dispatch(editSup(res.data));
            dispatch(getListAllSup());
          }
          setDisBtn(false)
          enqueueSnackbar('Updated Supervisor Successfully', {
            variant: 'success',
            action: (key) => (

              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
          console.log(res.data);
          handleClose();
        } catch (err) {
          if (!navigator.onLine) {
            enqueueSnackbar('No Internet Connection Found', {
              variant: 'error',
              action: (key) => (

                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              )
            });
            return setDisBtn(false);
          }
          if (err?.response?.data?.message?.includes("short_username") || err?.response?.data?.message?.includes("Short username")) {
            setErrUserNameExist("block");
            setDisBtn(false);
            return enqueueSnackbar('User Name Already Exists', {
              variant: 'error',
              action: (key) => (

                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              )
            });
          }

          if (err?.response?.data?.message?.includes("already exists")) {
            setErrExist("block");
            setDisBtn(false);
            return enqueueSnackbar('Staff Already exists', {
              variant: 'error',
              action: (key) => (

                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              )
            });
          }

          setDisBtn(false);
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
        }

      }

      if (supervisrID) return edit()

      async function newStaff() {
        try {
          const newObj = {
            user: {
              first_name: values.fname,
              last_name: values.lname,
              username: values.email.toLowerCase(),
              email: values.email.toLowerCase(),
              city: values.location2 === '' ? null : values.location2,
              short_username: values.short_username === null ? '' : values.short_username?.trim().toLowerCase(),
              country: values.location1 === '' ? null : values.location1,
              is_ai_access: values.is_ai_access
              // username: "hunt12@il.com"

            },
            notes: values?.notes?.trim(),
            groups: formGroup,
            teams: formTeam,
            employment_type: 10,
            is_agent_access: true,
            staff_type: values.staff_type,
            external_id: values.extID === '' ? null : values?.extID,
            can_scored: values.isScored,
            staff_access: true,
            read_only: false,
          }
          setDisBtn(true)

          const res = await addStaffApi(newObj);
          console.log(res.data);
          dispatch(newSup(res.data.data))
          dispatch(getListAllSup());
          setDisBtn(false)

          enqueueSnackbar('Added Supervisor Successfully', {
            variant: 'success',
            action: (key) => (

              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });

          handleClose();

        } catch (err) {
          if (!navigator.onLine) {
            enqueueSnackbar('No Internet Connection Found', {
              variant: 'error',
              action: (key) => (

                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              )
            });
            return setDisBtn(false);
          }
          if (err?.response?.data?.message?.includes("short_username") || err?.response?.data?.message?.includes("Short username")) {
            setErrUserNameExist("block");
            setDisBtn(false);
            return enqueueSnackbar('User Name Already Exists', {
              variant: 'error',
              action: (key) => (

                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              )
            });
          }

          if (err?.response?.data?.message?.includes("already exists")) {
            setErrExist("block");
            setDisBtn(false);
            return enqueueSnackbar('Staff Already exists', {
              variant: 'error',
              action: (key) => (

                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              )
            });
          }

          setDisBtn(false);
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });

        }
      }
      newStaff()
    }
  })

  const delayedQuery = useCallback(_.debounce((query1, query2) => handleFindLocationCity(query1, query2), 500), []);


  // find location function
  const handleFindLocationCountry = async (string) => {
    if (string.length > 0 && string !== formik?.values?.location1) {
      const { data } = await getStaffCountryNameApi(string);
      console.log(data?.data);
      setCountryDetailsData(data?.data);
    }
  }

  const handleFindLocationCity = async (country, cityQuery) => {
    if (cityQuery.length > 0 && cityQuery !== formik?.values?.location2) {
      const { data } = await getStaffCityNameApi(country, cityQuery);
      setCityDetailsData(data?.data);
    }

    if (cityQuery.length < 1) {
      setCityDetailsData([]);
    }
  }


  // useffect

  useEffect(async () => {
    setVisible(false);
    setIsSubmitted(false);
    setGrpErr("none");
    setTeamErr("none")
    if (supervisrID) {
      const emp = getSupervisor?.find((obj) => {
        if (obj?.id === supervisrID) {
          return obj;
        }
        return null
      })
      setSup(emp)
      if (emp?.user?.username) {
        setDisField(true)
      }
      formik.setFieldValue('email', emp?.user?.email)
      formik.setFieldValue('fname', emp?.user?.first_name)
      formik.setFieldValue('lname', emp?.user?.last_name)
      formik.setFieldValue('extID', emp?.external_id)
      formik.setFieldValue('short_username', emp?.user?.short_username)
      // formik.setFieldValue('password', emp?.user?.password)
      setFormGroup(emp?.user?.groups.map(obj => obj.id))
      setFormTeam(emp?.user?.teams.map(obj => obj.id))
      formik.setFieldValue('sup', emp?.supervisor?.id)
      formik.setFieldValue('notes', emp?.notes)
      formik.setFieldValue('staff_type', emp?.staff_type)
      formik.setFieldValue('isScored', emp?.can_scored)
      formik.setFieldValue('readOnly', emp?.read_only)
      formik.setFieldValue('stffAccess', emp?.staff_access);
      formik.setFieldValue('agentAccess', emp?.is_agent_access);
      formik.setFieldValue('is_ai_access', emp?.user?.is_ai_access)
      setTeamList(emp?.user?.groups.map(obj => obj.id));
      if (emp?.user?.country) {
        const { data } = await getStaffCountryNameApi(emp?.user?.country);
        setCountryDetailsData(data?.data);
        setLocationCountryValue(data?.data?.find((option) => option?.country_name === emp?.user?.country))
        formik.setFieldValue("location1", emp?.user?.country)
      }

      if (emp?.user?.city && emp?.user?.country) {
        const { data } = await getStaffCityNameApi(emp?.user?.country, emp?.user?.city);
        setCityDetailsData(data?.data);
        setLocationCityValue(data?.data?.find((option) => option?.city_name === emp?.user?.city))
        formik.setFieldValue("location2", emp?.user?.city)
      }
    }

  }, [supForm])



  useEffect(() => {
    if (_.isEmpty(formik.errors) === false && isSubmitted === true) {
      if (formGroup?.length === 0) {
        setGrpErr("block");
      }
      if (formTeam?.length === 0) {
        setTeamErr("block");
      }
    }
  }, [formik.errors, isSubmitted])

  useEffect(() => {
    if (formGroup?.length === 0 && isSubmitted === true) {
      setGrpErr("block");
    }
    if (formTeam?.length === 0 && isSubmitted === true) {
      setTeamErr("block");
    }
    if (formGroup?.length > 0 && isSubmitted === true) {
      setGrpErr("none");
    }
    if (formTeam?.length > 0 && isSubmitted === true) {
      setTeamErr("none");
    }
  }, [formGroup, formTeam])

  // Functions

  // FUNCTIONS

  async function setTeamList(id) {
    const { data } = await listTeamApi(id);

    setTeamOption(data?.data);
  }


  // console.log(allTeam)

  const handleClose = () => {
    setSup("");
    setSupervisrID("");
    formik.resetForm();
    setFormGroup([]); setFormTeam([]);
    setTeamErr("none"); setGrpErr("none")
    setSupForm(false);
    setErrExist("none");
    setErrUserNameExist("none")
    setDisField(false);
    setTeamOption([]);
    setCityDetailsData([])
    setCountryDetailsData([])
    setLocationCountryValue("")
    setLocationCityValue("")
    setInputCountryValue("")

  };

  // Multiple select
  const handleAddGroups = async (value) => {
    if (formGroup?.includes(value)) {
      const arr3 = formGroup?.filter((val) => { if (val !== value) { return val } return null })
      setFormGroup([...arr3])
      const { data } = await listTeamApi([...arr3]);
      console.log(data?.data);
      setTeamOption(data?.data);
      const arr = []
      allTeam?.map(obj => { if (obj.groups.id === value) { return arr.push(obj.id) } return null })
      const arr1 = [];
      console.log(arr, allTeam);
      if (arr3?.length === 0) {
        return setFormTeam([])
      }
      formTeam.map(val => {
        if (arr.includes(val)) {
          return null
        }
        return arr1.push(val)
      })
      setFormTeam(arr1)
    }
    else {
      setFormGroup([...formGroup, value])
      setT11([])
      const { data } = await listTeamApi([...formGroup, value]);
      setTeamOption(data?.data);
      console.log(data?.data);
    }
  };

  const handleAddTeams = (value) => {
    if (formTeam?.includes(value)) {
      const arr = formTeam.filter((val) => { if (val !== value) { return val } return null })
      setFormTeam([...arr])
    }
    else {
      setFormTeam([...formTeam, value])
    }
  };

  const handleOpenForm = () => {
    setSupForm(true);
  }


  return (
    <div>
      <GlobalButton className="halloween-animation"
        label='Add Supervisor'
        variant="contained"
        startIcon={
          <PersonAdd
            sx={{
              color: '#fff',
              // stroke: (theme) => theme.palette.background.buttonPaper, strokeWidth: '1px'
            }}
          />
        }

        fn={
          handleOpenForm
        }
      />


      <Dialog open={supForm} onClose={handleClose} fullWidth maxWidth='sm' >
        <form autoComplete='off'>
          <Stack
            sx={{ py: 1, pr: 1, borderBottom: (theme) => `2px solid ${theme.palette.text.primaryBolderBorder}` }}
            direction="row"
            alignContent="center"
            alignItems="center"
            justifyContent="space-between"
          >
            <DialogTitle> {supervisrID ? 'Update Supervisor' : 'Add New Supervisor'}</DialogTitle>
            <IconButton
              sx={{ mt: 1 }}
              onClick={() => {
                formik.resetForm();
                setFormTeam('');
                setFormGroup('');
                handleClose();
              }}
            >
              <Close />
            </IconButton>
          </Stack>
          {/* <Divider /> */}

          <DialogContent sx={{ pt: 3 }}>
            <Stack spacing={3}>
              {supervisrID && <Stack alignItems="center" direction="row" sx={{ width: '100%' }} >
                <Stack
                  direction={{ xs: 'column', sm: 'column' }}
                  sx={{ width: '50%' }}
                  spacing={2}
                >
                  <Box>

                    <TextField
                      fullWidth
                      label={
                        <>
                          First Name
                          <Typography variant="body" sx={{ color: setColor?.main, fontWeight: 'bold' }}>
                            &nbsp;*
                          </Typography>
                        </>
                      }
                      name="fname"
                      inputProps={{
                        autocomplete: 'new-password',
                        form: {
                          autocomplete: 'off'
                        }
                      }}
                      {...formik.getFieldProps('fname')}

                      onChange={(event) => {
                        formik.setFieldValue('fname', event.target.value);
                      }}
                    />



                    {formik.errors.fname && formik.touched.fname ? (
                      <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.fname}</Typography>
                    ) : null}
                  </Box>
                  <Box>

                    <TextField
                      fullWidth
                      label={
                        <>
                          Last Name
                          <Typography variant="body" sx={{ color: setColor?.main, fontWeight: 'bold' }}>
                            &nbsp;*
                          </Typography>
                        </>
                      }
                      name="lname"
                      inputProps={{
                        autocomplete: 'new-password',
                        form: {
                          autocomplete: 'off'
                        }
                      }}
                      {...formik.getFieldProps('lname')}
                    />



                    {formik.errors.lname && formik.touched.lname ? (
                      <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.lname}</Typography>
                    ) : null}
                  </Box>
                </Stack>
                <Stack
                  justifyContent="center"
                  direction="row"
                  sx={{ width: '50%', display: supervisrID ? 'flex' : 'none' }}
                >
                  <Avatar sx={{ width: 130, height: 130, border: '8px solid #ff7675' }} />
                </Stack>
              </Stack>}




              {!supervisrID && <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Box sx={{ width: '50%' }}>

                  <TextField
                    fullWidth
                    required
                    type="text"
                    label="First Name"
                    name="fname"
                    inputProps={{
                      autocomplete: 'new-password',
                      form: {
                        autocomplete: 'off'
                      }
                    }}
                    {...formik.getFieldProps('fname')}
                    autoComplete="chrome-off"
                  // inputProps={{
                  //     autoComplete: 'new-password'
                  // }}

                  />



                  {formik.errors.fname && formik.touched.fname ? (
                    <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.fname}</Typography>
                  ) : null}
                </Box>


                <Box sx={{ width: '50%' }}>

                  <TextField
                    fullWidth
                    required
                    label="Last Name"
                    name="lname"
                    type='text'
                    sx={{
                      'input::-ms-clear': {
                        display: 'none'
                      },
                      'input::-ms-reveal': {
                        display: 'none'
                      }
                    }}
                    inputProps={{
                      autocomplete: 'new-password',
                      form: {
                        autocomplete: 'off'
                      }
                    }}
                    {...formik.getFieldProps('lname')}

                  />


                  {formik.errors.lname && formik.touched.lname ? (
                    <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.lname}</Typography>
                  ) : null}
                </Box>
              </Stack>}






              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Box sx={{ width: supervisrID ? '50%' : '100%' }}>
                  <TextField
                    fullWidth
                    // required
                    label="Email"
                    // label={<>
                    //     Email
                    //     <Typography variant='body' sx={{ color: setColor?.main, fontWeight: "bold" }}>&nbsp;*</Typography>
                    // </>}
                    name="email"
                    inputProps={{
                      autocomplete: 'new-password',
                      form: {
                        autocomplete: 'off'
                      }
                    }}
                    {...formik.getFieldProps('email')}
                  />
                  <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', display: errExist }}>
                    User Already Exists
                  </Typography>
                  {formik.errors.email && formik.touched.email ? (
                    <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.email}</Typography>
                  ) : null}
                </Box>
                <Box sx={{ width: supervisrID ? '50%' : '100%' }}>
                  <TextField
                    fullWidth
                    label="User name"
                    value={formik.values.short_username}
                    inputProps={{
                      autocomplete: 'new-password',
                      form: {
                        autocomplete: 'off'
                      }
                    }}
                    autoComplete="chrome-off"
                    {...formik.getFieldProps('short_username')}
                  //   error={Boolean(touched.short_username && errors.short_username)}
                  //   helperText={touched.short_username && errors.short_username}
                  />
                  <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', display: errUserNameExist }}>
                    User Name Already Exists
                  </Typography>

                  {formik.errors.short_username && formik.touched.short_username ? (
                    <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>
                      {formik.errors.short_username}
                    </Typography>
                  ) : null}
                </Box>
                <Box sx={{ width: '50%', display: supervisrID ? 'block' : 'none' }}>
                  <TextField
                    fullWidth
                    label="Password"
                    name="password"
                    type={isVisible ? 'text' : 'password'}
                    sx={{
                      'input::-ms-clear': {
                        display: 'none'
                      },
                      'input::-ms-reveal': {
                        display: 'none'
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                          {isVisible ? (
                            <Visibility
                              onClick={() => {
                                setVisible(false);
                              }}
                            />
                          ) : (
                            <VisibilityOff
                              onClick={() => {
                                setVisible(true);
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                      autocomplete: 'new-password',
                      form: {
                        autocomplete: 'off'
                      },
                    }}
                    {...formik.getFieldProps('password')}
                    autoComplete="new-password"
                  />
                  {formik.errors.password && formik.touched.password ? (
                    <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.password}</Typography>
                  ) : null}
                </Box>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <FormControlLabel
                  checked={formik.values.isScored}
                  label="Can Be Scored"
                  name="isScored"
                  {...formik.getFieldProps('isScored')}
                  control={<Checkbox size="small" />}
                />

                {aiEnabled && <FormControlLabel
                  checked={formik.values.is_ai_access}
                  label="Speech AI Hub"
                  name="is_ai_access"
                  {...formik.getFieldProps('is_ai_access')}
                  control={<Checkbox size="small" />}
                />}

                {supervisrID && (
                  <>
                    <FormControlLabel
                      checked={formik.values.stffAccess}
                      label="Staff Access"
                      name="stffAccess"
                      {...formik.getFieldProps('stffAccess')}
                      control={<Checkbox size="small" />}
                    />
                    <FormControlLabel
                      checked={formik.values.readOnly}
                      label="Read Only"
                      name="readOnly"
                      {...formik.getFieldProps('readOnly')}
                      control={<Checkbox size="small" />}
                    />
                  </>
                )}
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Box sx={{ width: '50%' }}>
                  <InputLabel shrink id="groups">
                    Groups
                    <Typography variant="body" sx={{ color: setColor?.main, fontWeight: 'bold' }}>
                      &nbsp;*
                    </Typography>
                  </InputLabel>

                  <Box
                    sx={{
                      width: '100%',
                      borderRadius: 1,
                      overflow: 'hidden',
                      border: '1px solid #dcdde1',
                      height: '150px'
                    }}
                  >
                    <List sx={{ width: '100%', overflowY: 'scroll', height: 'inherit' }}>
                      {allGroup?.map((obj) => {
                        if (formGroup?.includes(obj.id)) {
                          return (
                            <ListItem
                              key={obj.id}
                              sx={{
                                cursor: 'pointer',
                                my: 1,
                                userSelect: 'none',
                                width: '100%',
                                backgroundColor: setColor?.main,
                                color: 'white',
                                p: 1
                              }}
                              onClick={() => {
                                handleAddGroups(obj.id);
                              }}
                            >
                              <ListItemText> {obj.name}</ListItemText>
                            </ListItem>
                          );
                        }

                        return (
                          <ListItem
                            key={obj.id}
                            sx={{
                              cursor: 'pointer',
                              my: 1,
                              width: '100%',
                              p: 1,
                              userSelect: 'none',
                              '&:hover': {
                                backgroundColor: theme.palette.action.hover,
                                color: theme.palette.text.primary
                              }
                            }}
                            onClick={() => {
                              handleAddGroups(obj.id);
                            }}
                          >
                            <ListItemText> {obj.name}</ListItemText>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>

                  <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', display: grpErr }}>
                    *Required Fields
                  </Typography>
                </Box>

                <Box sx={{ width: '50%' }}>
                  <InputLabel shrink id="teams">
                    Teams
                    <Typography variant="body" sx={{ color: setColor?.main, fontWeight: 'bold' }}>
                      &nbsp;*
                    </Typography>
                  </InputLabel>
                  <Box
                    sx={{
                      width: '100%',
                      borderRadius: 1,
                      overflow: 'hidden',
                      border: '1px solid #dcdde1',
                      height: '150px'
                    }}
                  >
                    <List sx={{ width: '100%', overflowY: 'scroll', height: 'inherit' }}>
                      {teamOption?.map((obj) =>
                        formTeam?.includes(obj.id) ? (
                          <ListItem
                            key={obj.id}
                            sx={{
                              cursor: 'pointer',
                              my: 1,
                              width: '100%',
                              userSelect: 'none',
                              backgroundColor: theme.palette.action.selected,
                              color: 'white',
                              p: 1,
                              '&::selection': { color: 'none', backgroundColor: 'transparent' }
                            }}
                            onClick={() => {
                              handleAddTeams(obj.id);
                            }}
                          >
                            <ListItemText> {obj.name}</ListItemText>
                          </ListItem>
                        ) : (
                          <ListItem
                            key={obj.id}
                            sx={{
                              cursor: 'pointer',
                              my: 1,
                              width: '100%',
                              p: 1,
                              userSelect: 'none',
                              '&::selection': { color: 'none', backgroundColor: 'red' },
                              '&:hover': {
                                backgroundColor: theme.palette.action.hover,
                                color: theme.palette.text.primary
                              }
                            }}
                            onClick={() => {
                              handleAddTeams(obj.id);
                            }}
                          >
                            <ListItemText> {obj.name}</ListItemText>
                          </ListItem>
                        )
                      )}
                    </List>
                  </Box>
                  <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', display: teamErr }}>
                    *Required Fields
                  </Typography>
                </Box>
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Box sx={{ width: '50%' }}>
                  <TextField
                    sx={{ mt: 1 }}
                    label="External ID"
                    name="extID"
                    inputProps={{
                      autocomplete: 'new-password',
                      form: {
                        autocomplete: 'off'
                      }
                    }}
                    {...formik.getFieldProps('extID')}
                  />
                  {formik.errors.extID && formik.touched.extID ? (
                    <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.extID}</Typography>
                  ) : null}
                </Box>
                <Box sx={{ width: '50%', display: supervisrID ? 'block' : 'none' }}>
                  <FormControl fullWidth>
                    <TextField
                      id="staff_type"
                      multiple={false}
                      required
                      select
                      label="Staff Type"
                      variant="outlined"
                      sx={{ mt: 1 }}
                      name="staff_type"
                      {...formik.getFieldProps('staff_type')}
                    >
                      <MenuItem sx={{ color: 'text.primary' }} value={10}>
                        Employee
                      </MenuItem>
                      <MenuItem sx={{ color: 'text.primary' }} value={20}>
                        Supervisor
                      </MenuItem>
                      <MenuItem sx={{ color: 'text.primary' }} value={30}>
                        Advanced Supervisor
                      </MenuItem>
                    </TextField>
                  </FormControl>
                  {formik.errors.staff_type && formik.touched.staff_type ? (
                    <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.staff_type}</Typography>
                  ) : null}
                </Box>
              </Stack>
              <Box sx={{ width: '100%', my: 1 }}>
                <Autocomplete
                  fullWidth
                  sx={{ width: '100%' }}
                  id="tags-outlined"
                  value={locationCountryValue || ''}
                  inputValue={inputCountryValue}
                  options={countryDetailsData}
                  getOptionLabel={(option) => option?.country_name || ''}
                  onChange={(event, newValue) => {
                    setLocationCountryValue(
                      countryDetailsData?.find((option) => option?.country_name === newValue?.country_name)
                    );
                    if (newValue?.country_name) {
                      formik.setFieldValue('location1', newValue?.country_name);
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    // each key stroke call location API
                    handleFindLocationCountry(newInputValue);
                    setInputCountryValue(newInputValue);
                    if (newInputValue === '') {
                      formik.setFieldValue('location1', null);
                      setCountryDetailsData([]);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} label="Country*" fullWidth sx={{ width: '100%' }} />}
                />
                {formik.errors.location1 && formik.touched.location1 ? (
                  <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.location1}</Typography>
                ) : null}
              </Box>
              <Box sx={{ width: '100%', my: 1 }}>
                <Autocomplete
                  fullWidth
                  sx={{ width: '100%' }}
                  id="tags-outlined2"
                  value={locationCityValue || ''}
                  inputValue={inputValue}
                  options={cityDetailsData}
                  // value={allSup?.map((item, index) => { if (formik.values?.newSupId === item?.id) { return allSup[index] } return null })?.filter(item => item !== null)?.join("")}
                  getOptionLabel={(option) => option?.city_name || ''}
                  onChange={(event, newValue) => {
                    setLocationCityValue(cityDetailsData?.find((option) => option?.city_name === newValue?.city_name));
                    if (newValue?.city_name) {
                      formik.setFieldValue('location2', newValue?.city_name);
                    }
                    // console.log(newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    // each key stroke call location API
                    delayedQuery(formik.values.location1, newInputValue);
                    setInputValue(newInputValue);
                    if (newInputValue === '') {
                      formik.setFieldValue('location2', null);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} label="City*" fullWidth sx={{ width: '100%' }} />}
                />
                {formik.errors.location2 && formik.touched.location2 ? (
                  <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.location2}</Typography>
                ) : null}
              </Box>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Box sx={{ width: '100%' }}>
                  <TextField
                    fullWidth
                    label="Notes"
                    multiline
                    rows={4}
                    name="notes"
                    {...formik.getFieldProps('notes')}
                    inputProps={{
                      autocomplete: 'new-password',
                      form: {
                        autocomplete: 'off'
                      }
                    }}
                  />
                  {formik.errors.notes && formik.touched.notes ? (
                    <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.notes}</Typography>
                  ) : null}
                </Box>
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ color: (theme) => theme.palette.text.buttonText }}
              onClick={() => {
                formik.resetForm();
                setFormTeam('');
                setFormGroup('');
                handleClose();
              }}
              color="inherit"
            >
              Cancel
            </Button>

            {supervisrID ? (
              <Button
                sx={{
                  '&:hover': {
                    color: (theme) => theme.palette.text.buttonText,
                    backgroundColor: (theme) => theme.palette.background.paper
                  },
                  boxShadow: 'none',
                  borderRadius: '7px',
                  border: '1px solid #dddde7',
                  color: (theme) => theme.palette.text.buttonText,
                  backgroundColor: (theme) => theme.palette.background.paper
                }}
                onClick={() => {
                  setIsSubmitted(true);
                  formik.handleSubmit();
                }}
                variant="contained"
                disabled={disBtn}
                className="halloween-animation"
              >
                Update Supervisor {disBtn && <CircularProgress size={14} sx={{ ml: 1 }} />}
              </Button>
            ) : (
              <Button
                sx={{
                  '&:hover': {
                    color: (theme) => theme.palette.text.buttonText,
                    backgroundColor: (theme) => theme.palette.background.paper
                  },
                  boxShadow: 'none',
                  borderRadius: '7px',
                  border: '1px solid #dddde7',
                  color: (theme) => theme.palette.text.buttonText,
                  backgroundColor: (theme) => theme.palette.background.paper
                }}
                onClick={() => {
                  setIsSubmitted(true);
                  formik.handleSubmit();
                }}
                variant="contained"
                disabled={disBtn}
                className="halloween-animation"
              >
                Add Supervisor {disBtn && <CircularProgress size={14} sx={{ ml: 1 }} />}
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}