import PropTypes from 'prop-types';

import { useMemo } from 'react';
// material
import { CssBaseline } from '@material-ui/core';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@material-ui/core/styles';
// hooks
import ApexCharts from 'apexcharts';
import useSettings from '../hooks/useSettings';
//
import shape from './shape';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import GlobalStyles from './globalStyles';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node
};

export default function ThemeConfig({ children }) {
  const { themeMode, themeDirection, setColor } = useSettings();
  const isLight = themeMode === 'light';

  const themeOptions = useMemo(
    () => ({
      palette: isLight ? { ...palette.light, action: { selected: setColor?.main, hover: "#e3e4e9e8" }, mode: 'light' } : { ...palette.dark, action: { selected: setColor?.dark, hover: "#e3e4e9e8" }, mode: 'dark' },
      shape,
      typography,
      breakpoints,
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
      // components: {
      //   MuiTableHead: {
      //     styleOverrides: {
      //       root: {
      //         '&&': {
      //           backgroundColor: '#1976d2 !important',
      //         },
      //         '& tr': {
      //           backgroundColor: '#1976d2 !important',
      //         },
      //         '& th': {
      //           backgroundColor: '#1976d2 !important',
      //           color: '#ffffff !important',
      //           fontWeight: 'bold !important',
      //         },
      //         '& .MuiTableCell-head': {
      //           backgroundColor: '#1976d2 !important',
      //           color: '#ffffff !important',
      //           fontWeight: 'bold !important',
      //         },
      //       },
      //     },
      //   },
      //   MuiTableCell: {
      //     styleOverrides: {
      //       head: {
      //         backgroundColor: '#1976d2 !important',
      //         color: '#ffffff !important',
      //         fontWeight: 'bold !important',
      //       },
      //       root: {
      //         '&.MuiTableCell-head': {
      //           backgroundColor: '#1976d2 !important',
      //           color: '#ffffff !important',
      //           fontWeight: 'bold !important',
      //         },
      //       },
      //     },
      //   },
      //   MuiTableRow: {
      //     styleOverrides: {
      //       root: {
      //         '&.MuiTableRow-head': {
      //           backgroundColor: '#1976d2 !important',
      //         },
      //       },
      //     },
      //   },
      // },
      ApexCharts: {
        theme: {
          palette: 'palette1'
        }
      }
    }), [isLight, themeDirection, setColor]
  );
  // Apex.theme.palette: isLight
  // const chartTheme = createTheme({
  //   ApexCharts.theme.palette:'palette10'

  // })

  const theme = createTheme(themeOptions);
  theme.components = {
    ...componentsOverride(theme),
    ...theme.components,
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: isLight ? '#f1f1f1' : '#2f3542',
          },
          '&::-webkit-scrollbar-thumb': {
            background: isLight ? '#888' : '#636e72',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: isLight ? '#555' : '#7f8c8d',
          },
          'scrollbar-width': 'thin',
          'scrollbar-color': `${isLight ? '#888' : '#636e72'} ${isLight ? '#f1f1f1' : '#2f3542'}`,
        },
      },
    },
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
