import { useState, useContext, useEffect } from 'react';
// material
import { Close } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Divider,
  ListItemButton,
  ListItemText,
  Box,
  ListItem,
  List,
  Typography,
  CircularProgress
} from '@material-ui/core';
// hooks
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
import AddUserContext from '../../../../contexts/AddUserContext';
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  dropdownListStyle:
  {
    padding: 5
  },
  select: {
    backgroundColor: "#FFFFFF"
  },
  selectDisabled: {
    backgroundColor: "#E9ECEF"
  }
}));

// ----------------------------------------------------------------------

export default function UsersGroupsCountDialog({ isDialogOpened, addUserButton, selectedRow, setAddMode, closeGroupTeamDialog, tabHeading, userData }) {

  const { showGroupSpinner } = useContext(AddUserContext);
  // const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [showAddUserButton, setShowAddUserButton] = useState(addUserButton);

  useEffect(() => {

    setOpen(isDialogOpened);

  }, [isDialogOpened]);

  // Open user dialog modal
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Close user dialog modal
  const handleClose = (event, reason) => {

    if (reason !== 'backdropClick') {

      setOpen(false)

      if (!setAddMode) {
        closeGroupTeamDialog(false);
      }
    }
  };

  return (
    <div>
      {/* Display user button based on add user or edit user dialog modal */}
      {showAddUserButton && (
        <Button variant="contained" onClick={handleClickOpen} className="halloween-animation">
          Add User
        </Button>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ pb: 2 }}>
          {/* <h3> */}
          {tabHeading === 'group' ? (
            <>
              <Typography display="inline" variant="h4">
                {`${userData?.firstName} 
                  ${userData?.lastName}`}
              </Typography>{' '}
              <Typography
                display="inline"
                sx={{ color: 'text.secondary' }}
              >{`( ${userData?.groups ? userData?.groups : userData} Groups )`}</Typography>
            </>
          ) : (
            <>
              <Typography display="inline" variant="h4">
                {`${userData?.firstName} 
                  ${userData?.lastName}`}
              </Typography>{' '}
              <Typography
                display="inline"
                sx={{ color: 'text.secondary' }}
              >{`( ${userData?.teams ? userData?.teams : userData} Teams )`}</Typography>
            </>
          )}
          {/* </h3> */}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 15,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />

        <DialogContent style={{ paddingTop: '20px' }}>
          <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {showGroupSpinner ? (
              <Box display="flex" alignItems="center" justifyContent="center" sx={{ py: 2 }}>
                <CircularProgress /> Loading...
              </Box>
            ) : (
              <List sx={{ width: '100%', maxWidth: 500 }}>
                {selectedRow?.map((item) => (
                  <ListItem key={item.id} component="div" disablePadding>
                    <ListItemButton>
                      <ListItemText primary={item?.name} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
            }}
            color="inherit" 
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
