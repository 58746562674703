/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import React from 'react';
import moment from 'moment';
import { styled } from '@material-ui/core/styles';
// MATERIAL IMPORTS
import { Box, Table, TableRow, tableCellClasses, TableHead, TableBody, TableCell, TableContainer, Typography } from '@material-ui/core';


export default function CustomDashboardTableView({ cardData, module, customTableData, hght }) {
    let questionNumber = 0;
    const SpanStyle = styled('span')(({ theme }) => ({
        fontWeight: 'bold',
        color: '#000000'
    }));

    const [filterObj, setFilterObj] = React.useState({});
    const percentageFinder = (total, maxTotal, naTotal = 0, parse = false) => {
        let totalVal = '';

        if (maxTotal === 0) {
            totalVal = 'N/A'
        } else if (naTotal === null) {
            totalVal = `${((total / (maxTotal)) * 100).toFixed(2)}%`;
        } else if (maxTotal === naTotal) {
            totalVal = 'N/A'
        } else {
            totalVal = `${((total / (maxTotal - naTotal)) * 100).toFixed(2)}%`;
        }
        // }
        return totalVal;
    };

    // FOR 5.1

    const getDateType = (id) => {
        let typeOfDate = 'week'
        switch (id) {
            case '10':
                typeOfDate = 'week'
                break;
            case '20':
                typeOfDate = 'month'
                break;
            case '30':
                typeOfDate = 'quarter'
                break;

            default:
                typeOfDate = 'week'
                break;
        }
        return typeOfDate
    };

    const handleDuration = (data) => {
        console.log(data?.created_at__week, cardData?.dateType)
        // let result;
        // if (getDateType(cardData?.dateType) === 'week') {
        //     result = data && `W ${data}`;
        // } else if (getDateType(cardData?.dateType) === 'month') {
        //     result = data && data <= 12 && moment(data, 'M').format('MMMM');
        // } else if (getDateType(cardData?.dateType) === 'quarter') {
        //     result = data && `Q ${data}`;
        // }
        // return result;

        let result = '';
        if (getDateType(cardData?.dateType) === 'week') {
            if (data?.created_at__week) {
                result = data?.created_at__week && `W ${data?.created_at__week}`;
            } else if (data?.updated_at__week) {
                result = data?.updated_at__week && `W ${data?.updated_at__week}`;
            } else if (data?.event_date__week) {
                result = data?.event_date__week && `W ${data?.event_date__week}`;
            } else if (data?.event_date__week) {
                result = data?.event_date__week && `W ${data?.event_date__week}`;
            }
        } else if (getDateType(cardData?.dateType) === 'month') {
            if (data?.created_at__month) {
                result = data?.created_at__month && data?.created_at__month <= 12 && moment(data?.created_at__month, 'M').format('MMMM');
            } else if (data?.updated_at__month) {
                result = data?.updated_at__month && data?.updated_at__month <= 12 && moment(data?.updated_at__month, 'M').format('MMMM');
            } else if (data?.event_date__month) {
                result = data?.event_date__month && data?.event_date__month <= 12 && moment(data?.event_date__month, 'M').format('MMMM');
            }
        } else if (getDateType(cardData?.dateType) === 'year') {
            if (data?.created_at__quarter) {
                result = data?.created_at__quarter && `Quarter ${data?.created_at__quarter}`;
            } else if (data?.updated_at__quarter) {
                result = data?.updated_at__quarter && `Quarter ${data?.updated_at__quarter}`;
            } else if (data?.event_date__quarter) {
                result = data?.event_date__quarter && `Quarter ${data?.event_date__quarter}`;
            }
        }
        return result;
    };

    // 6.3 HEADERS
    const headers = [
        { name: "Group ID" }, { name: "Group Name" },
        { name: "No.of Staff" }, { name: "No.of Events" },
        { name: "Fail All" }, { name: "Fail All Results" },
        { name: "Fail All Section" }, { name: "Custom Label" },
        { name: "Fail All %" }, { name: "Fail All Results %" }

    ]

    console.log(module, cardData?.data?.headers)

    // FOR 5.1
    const handlePercentage = (item) => {
        let result = 0;
        if (item !== null) {
            if ((item?.total_score) !== 0) {
                result = `${((item?.total_score / (item?.max_val - item?.na_total)) * 100)?.toFixed('2')}%`
            } else {
                result = 0;
            }
        } else {
            result = ''
        }

        return result;
    }

    return (

        <>
            {(cardData?.heading_users?.length > 0 || cardData?.data?.length > 0 || cardData?.data?.headers?.length > 0 || cardData?.data?.header?.length > 0) && <TableContainer sx={{ maxHeight: hght, zIndex: 1, cursor: 'default' }}>
                <Table
                    sx={{
                        [`& .${tableCellClasses.body}`]: {
                            backgroundColor: theme => theme.palette.background.paper
                        },
                        [`& .${tableCellClasses.root}`]: {
                            border: '1px solid #DEE2E6'
                        },
                        "& .MuiTableCell-root:first-of-type": {
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            boxShadow: 'none',
                        },
                        "& .MuiTableCell-root:last-of-type": {
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            boxShadow: 'none',
                        },
                    }}
                >
                    <TableHead sx={{ m: 0 }}>
                        {module === '1.2' && <TableRow>
                            <TableCell sx={{ color: theme => theme.palette.mode === 'light' ? '#666666' : '#ffffff' }}>Question</TableCell>
                            {cardData.heading_users.map((headData, index) => (
                                <TableCell sx={{ color: theme => theme.palette.mode === 'light' ? '#666666' : '#ffffff' }} key={index}>
                                    {headData.staff__user__first_name} {headData.staff__user__last_name}
                                </TableCell>
                            ))}
                            <TableCell sx={{ color: theme => theme.palette.mode === 'light' ? '#666666' : '#ffffff' }}>Total</TableCell>
                        </TableRow>}
                        {module === '5.1' && <TableRow>
                            <TableCell sx={{ color: theme => theme.palette.mode === 'light' ? '#666666' : '#ffffff' }} align="center">Teams</TableCell>
                            <TableCell sx={{ color: theme => theme.palette.mode === 'light' ? '#666666' : '#ffffff' }} align="center">Target-</TableCell>
                            <TableCell sx={{ color: theme => theme.palette.mode === 'light' ? '#666666' : '#ffffff' }} align="center">Target+</TableCell>
                            {
                                cardData?.data?.headers?.map((headDate, dataIndex) => (
                                    <TableCell sx={{ color: theme => theme.palette.mode === 'light' ? '#666666' : '#ffffff' }} key={dataIndex} align="center">
                                        {handleDuration(headDate)}
                                    </TableCell>
                                ))
                            }
                            <TableCell sx={{ color: theme => theme.palette.mode === 'light' ? '#666666' : '#ffffff' }} align="center">Total</TableCell>
                        </TableRow>}
                        {module === '6.1' && <TableRow>
                            <TableCell sx={{ color: theme => theme.palette.mode === 'light' ? '#666666' : '#ffffff' }} align="center">Groups</TableCell>
                            <TableCell sx={{ color: theme => theme.palette.mode === 'light' ? '#666666' : '#ffffff' }} align="center">Target</TableCell>
                            <TableCell sx={{ color: theme => theme.palette.mode === 'light' ? '#666666' : '#ffffff' }} align="center">+Target</TableCell>
                            {cardData?.data?.header.map((val, index) =>
                                <>
                                    {cardData?.dateType === '10' && <TableCell sx={{ color: theme => theme.palette.mode === 'light' ? '#666666' : '#ffffff' }} key={index}>
                                        {`W${val?.date_num}(${(`${val?.year}`)?.slice(2, 4)})`}
                                    </TableCell>}
                                    {cardData?.dateType === '20' && <TableCell sx={{ color: theme => theme.palette.mode === 'light' ? '#666666' : '#ffffff' }} key={index}>
                                        {`${moment(val?.date_num, 'MM').format('MMMM')}(${(`${val?.year}`)?.slice(2, 4)})`}
                                    </TableCell>}
                                    {cardData?.dateType === '30' && <TableCell sx={{ color: theme => theme.palette.mode === 'light' ? '#666666' : '#ffffff' }} key={index}>
                                        {`Q${val?.date_num}(${(`${val?.year}`)?.slice(2, 4)})`}
                                    </TableCell>}
                                </>
                            )}
                            <TableCell sx={{ color: theme => theme.palette.mode === 'light' ? '#666666' : '#ffffff' }} align="center">Total</TableCell>
                        </TableRow>}
                        {module === '6.3' && <TableRow>
                            {headers?.map((row, index) => <TableCell sx={{ color: theme => theme.palette.mode === 'light' ? '#666666' : '#ffffff' }} key={index} align="center">
                                {row?.name}
                            </TableCell>)}
                        </TableRow>}
                    </TableHead>

                    {module === '1.2' && <TableBody>
                        {cardData &&
                            cardData.data?.map((data, dataIndex) => {
                                return (
                                    <>
                                        <TableRow key={dataIndex}>
                                            <TableCell
                                                style={{ background: '#F8FAFC', color: '#000000' }}
                                                colspan={data.headerData?.length + 2}
                                            >
                                                {data?.name}
                                            </TableCell>
                                            {cardData.heading_users.map((headData, index) => (
                                                <>
                                                    <TableCell
                                                        key={index}
                                                        align="left"
                                                        style={{ background: '#F8FAFC', textTransform: 'uppercase', color: '#000000' }}
                                                    >
                                                        {data?.section_total_user?.map((sectionTotalUser) => {
                                                            return sectionTotalUser.staff === headData.staff
                                                                ? ((sectionTotalUser?.total || sectionTotalUser?.max_total) === 0 ? '0 %' : percentageFinder(
                                                                    sectionTotalUser.total,
                                                                    sectionTotalUser.max_total,
                                                                    sectionTotalUser.na_total,
                                                                ))
                                                                : '';
                                                        })}
                                                    </TableCell>
                                                </>
                                            ))}
                                            <TableCell
                                                key={dataIndex}
                                                style={{ background: '#F8FAFC', textTransform: 'uppercase', color: '#000000' }}
                                            >
                                                {' '}
                                                {(data?.section_total?.total || data?.section_total?.max_total) === 0 ? '0 %' : percentageFinder(
                                                    data?.section_total?.total,
                                                    data?.section_total?.max_total,
                                                    data?.section_total?.na_total
                                                )}
                                            </TableCell>
                                        </TableRow>

                                        {data?.questions?.map((questionData, index) => {
                                            questionNumber += 1;
                                            return (
                                                <>
                                                    <TableRow key={index}>
                                                        <TableCell>{`Q${questionNumber} | ${questionData.question}`}</TableCell>
                                                        {cardData.heading_users.map((headData, i) => (
                                                            <TableCell key={i} align="left">


                                                                {questionData?.percentage_value?.map(
                                                                    (percentValue, index) =>
                                                                        percentValue.staff === headData.staff &&
                                                                        (
                                                                            // percentValue.na !== null ? 'N/A' :
                                                                            parseFloat(percentageFinder(percentValue.total, percentValue.max_total, percentValue?.na))
                                                                                < parseFloat(filterObj?.highlightValue) ? (
                                                                                <SpanStyle
                                                                                    style={{
                                                                                        color: `${filterObj?.highlightValue && 'white'}`,
                                                                                        borderRadius: '4px',
                                                                                        padding: '5px',
                                                                                        backgroundColor: `${filterObj?.highlightValue && '#ffcb4e'}`
                                                                                    }}
                                                                                >
                                                                                    {percentageFinder(percentValue.total, percentValue.max_total, percentValue?.na)}

                                                                                    {/* {(percentValue?.na === null ? (Number.isNaN(percentValue?.total / percentValue?.max_total) === true ? '0 %' : percentageFinder(percentValue.total, percentValue.max_total)) : percentageFinder(percentValue.total, percentValue.max_total))} */}
                                                                                    {/* {(percentValue?.total || percentValue?.max_total) === 0 ? 'N/A' : percentageFinder(percentValue.total, percentValue.max_total)} */}
                                                                                </SpanStyle>
                                                                            ) : percentageFinder(percentValue.total, percentValue.max_total, percentValue?.na)
                                                                            // (percentValue?.na === null ? (Number.isNaN(percentValue?.total / percentValue?.max_total) === true ? '0 %' : percentageFinder(percentValue.total, percentValue.max_total)) : percentageFinder(percentValue.total, percentValue.max_total))
                                                                        )
                                                                )}
                                                            </TableCell>
                                                        ))}
                                                        {questionData.total && (
                                                            <TableCell>

                                                                {parseFloat(
                                                                    percentageFinder(questionData.total.total, questionData.total.max_total, questionData.total.na_total)
                                                                ) < filterObj?.highlightValue ? (
                                                                    <SpanStyle
                                                                        style={{
                                                                            color: `${filterObj?.highlightValue && 'white'}`,
                                                                            borderRadius: '4px',
                                                                            padding: '5px',
                                                                            backgroundColor: `${filterObj?.highlightValue && '#ffcb4e'}`
                                                                        }}
                                                                    >
                                                                        {(questionData?.total?.na_total === 0 ?
                                                                            (Number.isNaN(questionData?.total?.total / questionData?.total?.max_total) === true ? '0 %' :
                                                                                percentageFinder(
                                                                                    questionData.total.total,
                                                                                    questionData.total.max_total,
                                                                                    questionData.total.na_total
                                                                                ))
                                                                            : percentageFinder(
                                                                                questionData.total.total,
                                                                                questionData.total.max_total,
                                                                                questionData.total.na_total
                                                                            ))}

                                                                        {/* {(questionData?.total?.total || questionData?.total?.max_total) === 0 ? 'N/A' : percentageFinder(
                                              questionData.total.total,
                                              questionData.total.max_total,
                                              questionData.total.na_total
                                            )} */}
                                                                    </SpanStyle>
                                                                ) : (
                                                                    (questionData?.total?.na_total === 0 ?
                                                                        (Number.isNaN(questionData?.total?.total / questionData?.total?.max_total) === true ? '0 %' :
                                                                            percentageFinder(
                                                                                questionData.total.total,
                                                                                questionData.total.max_total,
                                                                                questionData.total.na_total
                                                                            ))
                                                                        : percentageFinder(
                                                                            questionData.total.total,
                                                                            questionData.total.max_total,
                                                                            questionData.total.na_total
                                                                        ))
                                                                    // (questionData?.total?.total || questionData?.total?.max_total) === 0 ? 'N/A' :
                                                                    //   percentageFinder(
                                                                    //     questionData.total.total,
                                                                    //     questionData.total.max_total,
                                                                    //     questionData.total.na_total
                                                                    //   )
                                                                )}
                                                            </TableCell>
                                                        )}
                                                    </TableRow>
                                                </>
                                            );
                                        })}
                                    </>
                                );
                            })}
                        <TableRow>
                            <TableCell style={{ background: '#F8FAFC', color: '#000000' }}>Total</TableCell>
                            {cardData.heading_users.map((headData, index) => (
                                <TableCell
                                    key={index}
                                    align="left"
                                    style={{ background: '#F8FAFC', textTransform: 'uppercase', color: '#000000' }}
                                >
                                    {cardData?.totals?.map((uTotal) =>
                                        uTotal.staff === headData.staff
                                            ? ((uTotal?.total || uTotal?.max_total) === 0 ? 'N/A' : percentageFinder(uTotal.total, uTotal.max_total, uTotal.na_total))
                                            : ''
                                    )}
                                </TableCell>
                            ))}
                            <TableCell style={{ background: '#F8FAFC', textTransform: 'uppercase', color: '#000000' }}>
                                {(cardData?.totals__total?.total || cardData?.totals__total?.max_total) === 0 ? 'N/A' : percentageFinder(
                                    cardData.totals__total.total,
                                    cardData.totals__total.max_total,
                                    cardData.totals__total.na_total
                                )}
                            </TableCell>
                        </TableRow>
                    </TableBody>}
                    {
                        module === '5.1' && <TableBody>
                            {cardData?.data?.table_data &&
                                cardData?.data?.table_data?.map((dataMain, index) => {
                                    return <TableRow key={index}>

                                        <TableCell align="center">

                                            {dataMain.name}
                                        </TableCell>
                                        <TableCell align="center">

                                            {dataMain?.under_target}
                                        </TableCell>
                                        <TableCell align="center">

                                            {dataMain.above_target}
                                        </TableCell>
                                        {dataMain?.records?.map((item, itemIndex) => {
                                            return <TableCell key={itemIndex} align="center">
                                                <>


                                                    {`${handlePercentage(item)}` ?? ''}

                                                </>{' '}
                                            </TableCell>
                                        })}
                                        <TableCell align="center">

                                            <>
                                                {handlePercentage(dataMain)}
                                            </>

                                        </TableCell>
                                    </TableRow>
                                })}

                        </TableBody>
                    }
                    {
                        module === '6.1' && <TableBody>
                            {(typeof cardData?.data === 'object' && cardData?.data?.score_card?.length > 0) && (cardData?.data?.score_card?.map((row, index) => <TableRow key={index}>
                                <TableCell /* sx={{ px: 1, margin: "0 auto",  minWidth: headers?.length > 7 ? '250px' : '0px' }} */ align="center" >
                                    {row?.score_card__groups__name}
                                </TableCell>
                                <TableCell /* sx={{ px: 1, margin: "0 auto" }} */ align="center">
                                    {row?.b_target}

                                </TableCell>
                                <TableCell /* sx={{ px: 1, margin: "0 auto" }} */ align="center">
                                    {row?.a_target}
                                </TableCell>

                                {cardData?.data?.header?.map((hdrRow, hedrIndx) => (typeof cardData?.data === 'object' && typeof cardData?.data?.data[index][hedrIndx] === 'object') ? <TableCell key={hedrIndx} /* sx={{ px: 1, margin: "0 auto" }} */ align="center" /* padding='normal' */>
                                    {cardData?.data?.data[index][hedrIndx]?.max_total !== 0 && (cardData?.data?.data[index][hedrIndx]?.max_total - cardData?.data?.data[index][hedrIndx]?.na_sum !== 0) ? ((cardData?.data?.data[index][hedrIndx]?.total / (cardData?.data?.data[index][hedrIndx]?.max_total - cardData?.data?.data[index][hedrIndx]?.na_sum)) * 100)?.toFixed(2) : 0}%
                                </TableCell> : <TableCell key={hedrIndx} /* sx={{ px: 1, margin: "0 auto" }} */ align="center" /* padding='normal' */>
                                    <Box>-</Box>
                                </TableCell>)}
                                <TableCell /* sx={{ px: 1, margin: "0 auto" }} */ align="center" /* padding='normal' */>
                                    {(row?.max_total !== 0 && (row?.max_total - row?.na_sum !== 0)) ? ((row?.total / (row?.max_total - row?.na_sum)) * 100)?.toFixed(2) : 0}%
                                </TableCell>
                            </TableRow>)
                            )}
                            {cardData?.data?.length === 0 && (
                                <TableRow>

                                    <TableCell colSpan={12}>
                                        <Typography gutterBottom align="center" variant="subtitle1" sx={{ py: 2 }}>

                                            No Data Found
                                        </Typography>
                                    </TableCell>

                                </TableRow>
                            )}
                        </TableBody>
                    }
                    {module === '6.3' && <TableBody>
                        {cardData?.data?.length > 0 && (cardData?.data?.map((row, index) => <TableRow key={index}>
                            <TableCell align="center">
                                {row?.group_id}
                            </TableCell>
                            <TableCell /* sx={{ px: 1, margin: "0 auto", minWidth: '150px' }} */ align="center" /* padding='normal' */>
                                {row?.group_name}
                            </TableCell>
                            <TableCell align="center">
                                {row?.no_of_staff}
                            </TableCell>
                            <TableCell align="center">
                                {row?.no_of_events}
                            </TableCell>
                            <TableCell align="center">
                                {row?.fail_all || 0}
                            </TableCell>
                            <TableCell align="center">
                                {row?.fail_all_results}
                            </TableCell>
                            <TableCell align="center" >
                                {row?.fail_section || 0}
                            </TableCell>
                            <TableCell align="center" >
                                {row?.custom_label}
                            </TableCell>
                            <TableCell align="center">
                                {row?.no_of_events !== 0 ? `${((row?.fail_all / row?.no_of_events) * 100)?.toFixed(2)}` : `0`}
                            </TableCell>
                            <TableCell align="center" >
                                {row?.no_of_events !== 0 ? `${((row?.fail_all_results / row?.no_of_events) * 100)?.toFixed(2)}` : `0`}
                            </TableCell>
                        </TableRow>)
                        )}
                        {cardData?.data?.length === 0 && (
                            <TableRow>

                                <TableCell colSpan={12}>
                                    <Typography gutterBottom align="center" variant="subtitle1" sx={{ py: 2 }}>

                                        No Data Found
                                    </Typography>
                                </TableCell>

                            </TableRow>
                        )}
                    </TableBody>}
                </Table>
            </TableContainer>}
        </>
    )

}