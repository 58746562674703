import React, { useEffect } from 'react';
// MATERIAL UI IMPORTS
import {
    Autocomplete,
    Box, Button,
    CircularProgress,
    Divider, Dialog, DialogTitle, DialogContent,
    FormControl, FormControlLabel,
    IconButton, InputLabel,
    Radio,
    Select, Stack,
    MenuItem,
    OutlinedInput,
    Typography, TextField,
} from "@material-ui/core";
// MATERIAL ICON IMPORTS
import { Cancel, Visibility, VisibilityOff } from "@material-ui/icons";
// NPM IMPORTS
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker, TimePicker } from '@mui/x-date-pickers';
import { useSearchParams } from 'react-router-dom';
// CONTEXT IMPORTS
import ScoreCardsContext from "../../../../../contexts/ScoreCardsContext";
import { AnalyticContext } from "../../../../../contexts/AnalyticContext";
import { SettingsContext } from "../../../../../contexts/SettingsContext"
// UTILS IMPORTS
import { DateRangeList } from "../../../../../utils/filterUtilities";
// API IMPORTS
import { getAcceptanceByGroupApi, getCallibrationTrend, getPointsOfFailureApi, getGroupSummaryApi } from "../../../../../_apis_/authApi.js/analytic_api";

const formulaType = [
    {
        id: 10,
        type: 'True'
    },
    {
        id: 20,
        type: 'Potential'
    }
]

export default function DialogAcceptanceByGroup({ addUserButton }) {
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    //  REACT STATES
    const [open, setOpen] = React.useState(false);
    const [advancedFilter, setAdvancedFilter] = React.useState(false);
    const [weekNo, setWeekNo] = React.useState([]);
    const [fromWeekNo, setFromWeekNo] = React.useState([]);
    const [toWeekNo, setToWeekNo] = React.useState([]);
    const [customFilters, setCustomFilters] = React.useState(false);
    const [circular, setCircular] = React.useState(false);
    const [supGrp, setSupGrp] = React.useState(loggedUserData?.groups?.map(obj => obj.id));
    const [searchParams, setSearchParams] = useSearchParams()
    const [isAnyOfTag, setIsAnyOfTag] = React.useState(true)
    const [isAllOfTag, setIsAllOfTag] = React.useState(false)

    // CONTEXT STATES
    // Scorecard context
    const { getScoreLogTeamsCalibrationTrend, calibrationTrendEvaluator, calibrationTrendTeam, getScoreLogGroups, reportsScoreLogGroups, getScoreLogScorecards, reportsScoreLogScorecards, getScoreLogEventType, reportsScoreLogEventType, getScoreLogEventSubType, reportsScoreLogEventSubType, getScoreLogTeams, reportsScoreLogTeams, reportsScoreLogSupervisor, reportsScoreLogEmployee, getScoreLogEvaluator, getScoreLogEvaluatorCalibrationTrend, reportsScoreLogEvaluator } = React.useContext(ScoreCardsContext);
    // Analytic context
    const { selectedGroupDataTagsOperator, setselectedGroupDataTagsOperator, selectedGroupDataTag, setselectedGroupDataTag, dataTagByScoreCard, getDataTagsByScoreCard, setGroupFilterApplied, groupSummaryDateType, setGroupSummaryData, setGroupSummaryLoading, selectedHighLightBelow, setSelectedHighLightBelow, selAnaGroupFilters, setCallibrationTrendData, setSelAnaGroupFilters, selectedGroup, setSelectedGroup, selectedTeam, setSelectedTeam, pageAccpGrp, setPageAccpGrp, filterNameAcceptanceByGrp, setFilterNameAcceptanceByGrp, setIsLoading, setCount, setacceptanceByGrpData, selectedDateRange, setSelectedDateRange, selectedFilter, setSelectedFilter, selectedFlag, selectedScoreCard, setSelectedScoreCard, selectedEventType, setSelectedEventType, selectedEventSubType, setSelectedEventSubType, selectedEvaluator, setSelectedEvaluator, selectedDate, setSelectedDate, selectedWeek, setSelectedWeek, subModLabel,
        setPointsOfFailureData, setPointsOfFailureLoading, selectedGroupToWeek, setSelectedGroupToWeek, selectedGroupFromWeek, setSelectedGroupFromWeek,
        getScoreCardCategroies, getCustomerReference, getComplianceOverview
    } = React.useContext(AnalyticContext);

    const { dataCreatedFrom } = React.useContext(SettingsContext);

    // FORMIK STATES
    const formik = useFormik({
        initialValues: {
            group: selectedGroup,
            teams: selectedTeam ?? '',
            dateRange: selectedDateRange,
            fromWeek: '',
            toWeek: '',
            fromDate: selectedDate?.date1,
            toDate: selectedDate?.date2,
            score_card: (selectedScoreCard || searchParams.get("score_card")) ?? '',
            filter_By: selectedFilter,
            event_Type: selectedEventType,
            sub_type: selectedEventSubType,
            evaluator: selectedEvaluator,
            highlightBelow: selectedHighLightBelow,
            dataTag: [],
            datTagsOperator: selectedGroupDataTagsOperator
        },
        validationSchema: Yup.object().shape({

            group: (subModLabel === 6.2 || subModLabel === 6.4) ? Yup.string().required('This Value is required').nullable() : Yup.string().nullable(),
            // scoreCard: (subModLabel === 6.4) ? Yup.string().required('This Value is required').nullable() : Yup.string().nullable(),
            // formula: (subModLabel === 1.6) ? Yup.number().required('This Value is required').nullable() : Yup.string().nullable()
        }),
        onSubmit: async (values) => {
            setCircular(true)

            if (subModLabel === 6.7 && subModLabel !== 6.6) {
                setSelectedGroup(values.group);
                setSelectedTeam(values.teams);
                setSelectedHighLightBelow(values.highlightBelow);
                setSelectedDate({ date1: values.fromDate, date2: values.toDate });
                setSelectedDateRange(values.dateRange);
                setSelectedFilter(values.filter_By);
                setSelectedScoreCard(values.score_card);
                setSelectedEventType(values.event_Type);
                setSelectedEventSubType(values.sub_type);
                setSelectedEvaluator(values.evaluator);
                setacceptanceByGrpData([]);
                setIsLoading(true);
                setPageAccpGrp(0);
                setFilterNameAcceptanceByGrp("");
                setSelectedGroupFromWeek(values.fromWeek);
                setSelectedGroupToWeek(values.toWeek);
                setselectedGroupDataTag([values.dataTag]);
                setselectedGroupDataTagsOperator(values.anyOfTags || values.allOfTags || '10')
                try {
                    const { data } = await getAcceptanceByGroupApi(
                        moment(values.fromDate).format('YYYY-MM-DD'), moment(values.toDate).format('YYYY-MM-DD'), values.filter_By,
                        values.score_card, values.event_Type, values.sub_type,
                        values.evaluator, (loggedUserData?.staff && loggedUserData?.staff?.staffType !== 10 ? supGrp : ""), 1, "", [values.dataTag], (values?.allOfTags || values.anyOfTags) || '10');
                    setacceptanceByGrpData(data?.results);
                    setCount(data?.count)
                    setIsLoading(false);
                    setOpen(false);
                    setCircular(false);
                    setSearchParams(formik?.values);
                    setGroupFilterApplied(true);
                    setSelAnaGroupFilters({ ...values, 'dateRange': selectedDateRange || values?.dateRange });
                } catch (err) {
                    setacceptanceByGrpData([]);
                    setCount(0)
                    setIsLoading(false);
                    setOpen(false);
                    setCircular(false);
                }
                // getAcceptanceGrpData(values.fromDate, values.toDate, values.filter_By, values.score_card, values.event_Type, values.sub_type, values.evaluator, 1, '');
            }
            if (subModLabel === 6.6) {
                setSelectedGroup(values.group);
                setSelectedTeam(values.teams);
                setSelectedDate({ date1: values.fromDate, date2: values.toDate });
                setSelectedDateRange(values.dateRange);
                setSelectedFilter(values.filter_By);
                setSelectedScoreCard(values.score_card);
                setSelectedEventType(values.event_Type);
                setSelectedEventSubType(values.sub_type);
                setSelectedEvaluator(values.evaluator);
                setCallibrationTrendData([]);
                setIsLoading(true);
                setPageAccpGrp(0);
                setSelectedGroupFromWeek(values.fromWeek);
                setSelectedGroupToWeek(values.toWeek);
                setFilterNameAcceptanceByGrp("")
                setselectedGroupDataTag(values.dataTag);
                try {
                    const { data } = await getCallibrationTrend(moment(values.fromDate).format('YYYY-MM-DD'), moment(values.toDate).format('YYYY-MM-DD'), values.filter_By, selectedFlag, values.evaluator, values.group, values.teams);
                    setCallibrationTrendData(data);
                    setCount(data?.count)
                    setIsLoading(false);
                    setOpen(false);
                    setGroupFilterApplied(true);
                    setSelAnaGroupFilters(values);
                    setCircular(false);

                } catch (err) {
                    setCallibrationTrendData([]);
                    setCount(0)
                    setIsLoading(false);
                    setOpen(false);
                    setCircular(false);

                }
                // getAcceptanceGrpData(values.fromDate, values.toDate, values.filter_By, values.score_card, values.event_Type, values.sub_type, values.evaluator, 1, '');
            }
            if (subModLabel === 6.3) {
                setSelectedGroup(values.group);
                setSelectedTeam(values.teams);
                setSelectedHighLightBelow(values.highlightBelow);
                setSelectedDate({ date1: values.fromDate, date2: values.toDate });
                setSelectedDateRange(values.dateRange);
                setSelectedFilter(values.filter_By);
                setSelectedScoreCard(values.score_card);
                setSelectedEventType(values.event_Type);
                setSelectedEventSubType(values.sub_type);
                setSelectedEvaluator(values.evaluator);
                setSelectedGroupFromWeek(values.fromWeek);
                setSelectedGroupToWeek(values.toWeek);
                setPointsOfFailureData([]);
                setPointsOfFailureLoading(true);
                setSelAnaGroupFilters();
                setselectedGroupDataTag(values.dataTag);
                try {

                    const { data } = await getPointsOfFailureApi(values);


                    if (data?.data?.length > 0) {
                        setPointsOfFailureData(data?.data);
                        setSearchParams(values);
                    }
                    if (data?.data?.length === 0) {
                        setPointsOfFailureData([]);
                    }
                    setPointsOfFailureLoading(false);
                    setCircular(false);
                    setGroupFilterApplied(true);
                    setOpen(false);
                    setSelAnaGroupFilters({ ...values, fromDate: moment(values.fromDate).format('YYYY-MM-DD'), toDate: moment(values.toDate).format('YYYY-MM-DD') });
                } catch (err) {
                    setPointsOfFailureData([]);
                    setPointsOfFailureLoading(false);
                    setCircular(false);
                    setOpen(false);
                }
                // getAcceptanceGrpData(values.fromDate, values.toDate, values.filter_By, values.score_card, values.event_Type, values.sub_type, values.evaluator, 1, '');
            }

            if (subModLabel === 6.4) {
                setSelectedGroup(values.group);
                setSelectedTeam(values.teams);
                setSelectedDate({ date1: values.fromDate, date2: values.toDate });
                setSelectedDateRange(values.dateRange);
                setSelectedFilter(values.filter_By);
                setSelectedScoreCard(values.score_card);
                setSelectedEventType(values.event_Type);
                setSelectedEventSubType(values.sub_type);
                setSelectedEvaluator(values.evaluator);
                // setCallibrationTrendData([]);
                setIsLoading(true);
                setPageAccpGrp(0);
                setSelectedGroupFromWeek(values.fromWeek);
                setSelectedGroupToWeek(values.toWeek);
                setFilterNameAcceptanceByGrp("")
                setselectedGroupDataTag(values.dataTag);
                try {
                    const filterObj =
                    {
                        "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
                        "toDate": moment(values.toDate).format('YYYY-MM-DD'),
                        "filterBy": values.filter_By || '',
                        "group": values.group || '',
                        "teams": values.teams || '',
                        "supervisor": values.supervisor || '',
                        "staff": values.staff || '',
                        "formula": values.formula || '',
                        "evaluator": values.evaluator || '',
                        "scoreCard": values.score_card || '',
                        "scoreCardGroups": values?.group || '',
                        "eventType": values.event_Type || '',
                        "eventTypeEventSubType": values.sub_type || '',
                        "page": "1",
                        "highlightValue": values.highlightBelow || '',
                        "flag": selectedFlag,
                    }
                    setSelAnaGroupFilters(filterObj);
                    // setSearchParams(filterObj)
                    getCustomerReference(filterObj);
                    setIsLoading(false);
                    setOpen(false);
                    setGroupFilterApplied(true);
                    setCircular(false);

                } catch (err) {
                    setCount(0)
                    setIsLoading(false);
                    setOpen(false);
                    setCircular(false);

                }
                // getAcceptanceGrpData(values.fromDate, values.toDate, values.filter_By, values.score_card, values.event_Type, values.sub_type, values.evaluator, 1, '');
            }

            if (subModLabel === 6.5) {
                setSelectedGroup(values.group);
                setSelectedTeam(values.teams);
                setSelectedDate({ date1: values.fromDate, date2: values.toDate });
                setSelectedDateRange(values.dateRange);
                setSelectedFilter(values.filter_By);
                setSelectedScoreCard(values.score_card);
                setSelectedEventType(values.event_Type);
                setSelectedEventSubType(values.sub_type);
                setSelectedEvaluator(values.evaluator);
                // setCallibrationTrendData([]);
                setIsLoading(true);
                setPageAccpGrp(0);
                setSelectedGroupFromWeek(values.fromWeek);
                setSelectedGroupToWeek(values.toWeek);
                setFilterNameAcceptanceByGrp("")
                setselectedGroupDataTag(values.dataTag);
                try {
                    const filterObj =
                    {
                        "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
                        "toDate": moment(values.toDate).format('YYYY-MM-DD'),
                        "filterBy": values.filter_By || '',
                        // "group": values.group || '',
                        // "teams": values.teams || '',
                        // "supervisor": values.supervisor || '',
                        // "staff": values.staff || '',
                        // "formula": values.formula || '',
                        // "evaluator": values.evaluator || '',
                        // "scoreCard": values.score_card || '',
                        // "scoreCardGroups": values?.group || '',
                        // "eventType": values.event_Type || '',
                        // "eventTypeEventSubType": values.sub_type || '',
                        // "page": "1",
                        // "highlightValue": values.highlightBelow || '',
                        "flag": selectedFlag,
                    }
                    setSelAnaGroupFilters(filterObj);
                    // setSearchParams(filterObj)
                    getComplianceOverview(filterObj);
                    setIsLoading(false);
                    setOpen(false);
                    setGroupFilterApplied(true);
                    setCircular(false);

                } catch (err) {
                    setCount(0)
                    setIsLoading(false);
                    setOpen(false);
                    setCircular(false);

                }
                // getAcceptanceGrpData(values.fromDate, values.toDate, values.filter_By, values.score_card, values.event_Type, values.sub_type, values.evaluator, 1, '');
            }

            if (subModLabel === 6.1) {
                setSelectedGroup(values.group);
                setSelectedTeam(values.teams);
                setSelectedHighLightBelow(values.highlightBelow);
                setSelectedDate({ date1: values.fromDate, date2: values.toDate });
                setSelectedDateRange(values.dateRange);
                setSelectedFilter(values.filter_By);
                setSelectedScoreCard(values.score_card);
                setSelectedEventType(values.event_Type);
                setSelectedEventSubType(values.sub_type);
                setSelectedEvaluator(values.evaluator);
                setPointsOfFailureData([]);
                setSelectedGroupFromWeek(values.fromWeek);
                setSelectedGroupToWeek(values.toWeek);
                setPointsOfFailureLoading(true);
                setselectedGroupDataTag(values.dataTag);
                try {

                    const { data } = await getGroupSummaryApi(values, groupSummaryDateType);


                    if (data?.score_card?.length > 0) {
                        setGroupSummaryData(data);
                        setSearchParams(values);
                    }
                    if (data?.score_card?.length === 0) {
                        setGroupSummaryData([]);
                    }
                    setGroupSummaryLoading(false);
                    setCircular(false);
                    setOpen(false);
                    setGroupFilterApplied(true);
                    setSelAnaGroupFilters(values);
                } catch (err) {
                    setGroupSummaryData([]);
                    setGroupSummaryLoading(false);
                    setCircular(false);
                    setOpen(false);
                }
                // getAcceptanceGrpData(values.fromDate, values.toDate, values.filter_By, values.score_card, values.event_Type, values.sub_type, values.evaluator, 1, '');
            }

            if (subModLabel === 6.2) {
                setSelectedGroup(values.group);
                setSelectedTeam(values.teams);
                setSelectedDate({ date1: values.fromDate, date2: values.toDate });
                setSelectedDateRange(values.dateRange);
                setSelectedFilter(values.filter_By);
                setSelectedScoreCard(values.score_card);
                setSelectedEventType(values.event_Type);
                setSelectedEventSubType(values.sub_type);
                setSelectedEvaluator(values.evaluator);
                // setCallibrationTrendData([]);
                setIsLoading(true);
                setPageAccpGrp(0);
                setSelectedGroupFromWeek(values.fromWeek);
                setSelectedGroupToWeek(values.toWeek);
                setFilterNameAcceptanceByGrp("")
                setselectedGroupDataTag(values.dataTag);
                try {
                    const filterObj =
                    {
                        "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
                        "toDate": moment(values.toDate).format('YYYY-MM-DD'),
                        "filterBy": values.filter_By,
                        "group": values.group,
                        "scoreCard": values.score_card,
                        "scoreCardGroups": values?.group,
                        "eventType": values.event_Type,
                        "eventTypeEventSubType": values.sub_type,
                        "scoreCardCategory": values.scoreCardCategory,
                        "page": "1",
                        "highlightValue": values.highlightValue,
                        "flag": selectedFlag,
                    }
                    setSearchParams(filterObj)
                    getScoreCardCategroies(filterObj);
                    setIsLoading(false);
                    setOpen(false);
                    setGroupFilterApplied(true);
                    setSelAnaGroupFilters(filterObj);
                    setCircular(false);

                } catch (err) {
                    setCount(0)
                    setIsLoading(false);
                    setOpen(false);
                    setCircular(false);

                }
                // getAcceptanceGrpData(values.fromDate, values.toDate, values.filter_By, values.score_card, values.event_Type, values.sub_type, values.evaluator, 1, '');
            }
        }
    })

    const { errors, touched, values, setFieldValue, getFieldValue, handleSubmit, isSubmitting, getFieldProps } = formik;


    useEffect(() => {
        console.log(moment().week(), 'ashu testing', selectedDate, selectedDateRange);

        if (open) {
            console.log('chk val', selectedFilter,
                selectedScoreCard, 'ev', selectedEventType, selectedEventSubType, 'evl', selectedEvaluator,

                'gr', selectedGroup, 'h', selectedHighLightBelow
                , 'dg', selectedGroupDataTag, selectedTeam)
            if (selectedDate?.date1 && selectedDate?.date2) {
                formik.setFieldValue("fromDate", selectedDate?.date1 || searchParams.get('fromDate'));
                formik.setFieldValue("toDate", selectedDate?.date2 || searchParams.get('toDate'));
                formik.setFieldValue("dateRange", selectedDateRange || searchParams.get('dateRange'))
            }
            // else {
            //     setFieldValue("dateRange", 'This Month');
            //     setFieldValue("fromDate", moment().startOf('month').format('YYYY-MM-DD'));
            //     setFieldValue("toDate", moment().endOf('month').format('YYYY-MM-DD'));
            // }

            if (selectedDateRange === 11) {
                setCustomFilters(true);
                formik.setFieldValue("fromWeek", selectedGroupFromWeek);
                formik.setFieldValue("toWeek", selectedGroupToWeek);
            }
            if (selectedFilter) {
                formik.setFieldValue("filter_By", selectedFilter);
            } else {
                formik.setFieldValue("filter_By", "created_at");
            }

            if (selectedScoreCard) {
                setFieldValue("score_card", selectedScoreCard || searchParams.get('score_card'))
                const filterScoreCardObj = {
                    "fromDate": values.fromDate,
                    "toDate": values.toDate,
                    "filterBy": values.filter_By,
                    "scoreCard": selectedScoreCard ?? '',
                }
                getDataTagsByScoreCard(filterScoreCardObj);
            } else {
                setFieldValue("score_card", '');
            }



            if (selectedEventType) {
                formik.setFieldValue("event_Type", selectedEventType)
            } else {
                formik.setFieldValue("event_Type", '');
            }

            if (selectedEventSubType) {

                formik.setFieldValue("sub_type", selectedEventSubType)
            } else {
                formik.setFieldValue("sub_type", '')
            }

            if (selectedEvaluator) {
                formik.setFieldValue("evaluator", selectedEvaluator)
            } else {
                formik.setFieldValue("evaluator", '')
            }

            if (selectedGroup) {
                formik.setFieldValue("group", selectedGroup)
            } else {
                formik.setFieldValue("group", '')
            }

            if (selectedTeam) {
                formik.setFieldValue("teams", selectedTeam)
            } else {
                formik.setFieldValue("teams", '')
            }

            if (selectedHighLightBelow) {
                formik.setFieldValue("highlightBelow", selectedHighLightBelow)
            } else {
                formik.setFieldValue("highlightBelow", 0)
            }

            if (selectedGroupDataTag?.length > 0) {
                formik.setFieldValue('dataTag', selectedGroupDataTag)
            } else {
                formik.setFieldValue('dataTag', [])

            }
        }


    }, [open]);

    useEffect(() => {
        if (open) {
            const arrFromWeek = [];
            const arrToWeek = [];
            if (formik.values.fromWeek > moment().week()) {
                for (let i = 1; i <= formik.values.fromWeek; i) {
                    arrFromWeek.push(i);
                    i += 1;
                }
                setFromWeekNo(arrFromWeek);
                console.log("from week1", arrFromWeek)
            } else {
                for (let i = 1; i <= moment().week(); i) {
                    arrFromWeek.push(i);
                    i += 1;
                }
                setFromWeekNo(arrFromWeek);
            }

            if (formik.values.toWeek > moment().week()) {
                for (let i = 1; i <= formik.values.toWeek; i) {
                    arrToWeek.push(i);
                    i += 1;
                }
                setToWeekNo(arrToWeek);
            } else {
                for (let i = 1; i <= moment().week(); i) {
                    arrToWeek.push(i);
                    i += 1;
                }
                setToWeekNo(arrToWeek);
            }
        }
        // if (formik.values.fromDate && formik.values.toDate && formik.values.filter_By) {


        getScoreLogTeams({ fromDate: moment(formik.values.fromDate).format('YYYY-MM-DD'), toDate: moment(formik.values.toDate).format('YYYY-MM-DD'), filterBy: formik.values.filter_By, group: formik.values.group, sup_grp: loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30 ? loggedUserData?.groups?.map((obj) => obj.id) : [] })
        getScoreLogGroups({ fromDate: moment(formik.values.fromDate).format('YYYY-MM-DD'), toDate: moment(formik.values.toDate).format('YYYY-MM-DD'), filterBy: formik.values.filter_By, sup_grp: loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30 ? loggedUserData?.groups?.map((obj) => obj.id) : [], isCalibratedScore: subModLabel === 6.6 ? 'True' : 'False' })
        getScoreLogScorecards({ fromDate: moment(formik.values.fromDate).format('YYYY-MM-DD'), toDate: moment(formik.values.toDate).format('YYYY-MM-DD'), filterBy: formik.values.filter_By, group: formik.values.group, teams: formik.values.teams ?? '', scoreCard: "", eventType: '', eventTypeEventSubType: '', staff: '', scoreCardCategory: '', evaluator: '', sup_grp: [] });
        getScoreLogEventType({ fromDate: moment(formik.values.fromDate).format('YYYY-MM-DD'), toDate: moment(formik.values.toDate).format('YYYY-MM-DD'), filterBy: formik.values.filter_By, group: formik.values.group, teams: formik.values.teams ?? '', scoreCard: formik.values.score_card === 'all' ? '' : formik.values.score_card, eventType: '', eventTypeEventSubType: formik.values.sub_type === 'all' ? "" : formik.values.sub_type, staff: '', scoreCardCategory: '', evaluator: formik.values.evaluator, sup_grp: loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30 ? loggedUserData?.groups?.map((obj) => obj.id) : [] });
        getScoreLogEventSubType({ fromDate: moment(formik.values.fromDate).format('YYYY-MM-DD'), toDate: moment(formik.values.toDate).format('YYYY-MM-DD'), filterBy: formik.values.filter_By, group: formik.values.group, teams: formik.values.teams ?? '', scoreCard: formik.values.score_card === 'all' ? '' : formik.values.score_card, eventType: formik.values.event_Type === 'all' ? '' : formik.values.event_Type, eventTypeEventSubType: "", staff: '', scoreCardCategory: '', evaluator: formik.values.evaluator, sup_grp: loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30 ? loggedUserData?.groups?.map((obj) => obj.id) : [] });
        getScoreLogEvaluator({ fromDate: moment(formik.values.fromDate).format('YYYY-MM-DD'), toDate: moment(formik.values.toDate).format('YYYY-MM-DD'), filterBy: formik.values.filter_By, group: formik.values.group, teams: formik.values.teams ?? '', scoreCard: formik.values.score_card === 'all' ? '' : formik.values.score_card, eventType: formik.values.event_Type === 'all' ? '' : formik.values.event_Type, eventTypeEventSubType: formik.values.sub_type === 'all' ? "" : formik.values.sub_type, staff: '', scoreCardCategory: '', evaluator: '', sup_grp: loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30 ? loggedUserData?.groups?.map((obj) => obj.id) : [] });
        getScoreLogTeamsCalibrationTrend({ fromDate: moment(formik.values.fromDate).format('YYYY-MM-DD'), toDate: moment(formik.values.toDate).format('YYYY-MM-DD'), filterBy: formik.values.filter_By, group: formik.values.group, sup_grp: loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30 ? loggedUserData?.groups?.map((obj) => obj.id) : [], isCalibratedScore: 'True' })
        getScoreLogEvaluatorCalibrationTrend({ fromDate: moment(formik.values.fromDate).format('YYYY-MM-DD'), toDate: moment(formik.values.toDate).format('YYYY-MM-DD'), filterBy: formik.values.filter_By, group: formik.values.group, teams: formik.values.teams, scoreCard: formik.values.score_card === 'all' ? '' : formik.values.score_card, eventType: formik.values.event_Type === 'all' ? '' : formik.values.event_Type, eventTypeEventSubType: formik.values.sub_type === 'all' ? "" : formik.values.sub_type, staff: '', scoreCardCategory: '', evaluator: '', sup_grp: loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30 ? loggedUserData?.groups?.map((obj) => obj.id) : [], isCalibratedScore: 'True' });
        // getScoreLogGroupsCalibrationTrend({ fromDate: formik.values.fromDate, toDate: formik.values.toDate, filterBy: formik.values.filter_By, sup_grp: loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30 ? loggedUserData?.groups?.map((obj) => obj.id) : [], isCalibratedScore: 'True' })
        // }
    }, [formik.values])

    const filterBy = [
        {
            "id": 1,
            "type": "Score Date",
            "value": "created_at"
        },
        {
            "id": 2,
            "type": "Event Date",
            "value": "event_date"
        },
        {
            "id": 3,
            "type": "Last Edit Date",
            "value": "updated_at"
        }
    ]

    // METHODS

    const handleDateRangeChange = (event) => {
        console.log('123333', event.target.value);
        const dateRange = event.target.value;
        setFieldValue('group', '');
        setFieldValue('teams', '');
        setFieldValue('score_card', '');
        setFieldValue('event_Type', '');
        setFieldValue('sub_type', '');
        setFieldValue('scoreCardCategory', '');
        setFieldValue('evaluator', '');
        setFieldValue("dateRange", dateRange);
        setFieldValue("highlightBelow", 0)
        console.log(dateRange);
        switch (dateRange) {
            
            case 1:
                setCustomFilters(false);
                setFieldValue("fromDate", moment().format('YYYY-MM-DD'));
                setFieldValue("toDate", moment().format('YYYY-MM-DD'));
                // setSelectedDateRange(1);
                break;
            case 2:
                setCustomFilters(false);
                setFieldValue("fromDate", moment().subtract(1, 'days').format('YYYY-MM-DD'));
                setFieldValue("toDate", moment().subtract(1, 'days').format('YYYY-MM-DD'));
                // setSelectedDateRange(2);

                break;
            case 3:
                setCustomFilters(false);
                setFieldValue("fromDate", moment().startOf('week').format('YYYY-MM-DD'));
                setFieldValue("toDate", moment().endOf('week').format('YYYY-MM-DD'));
                // setSelectedDateRange(3);

                break;
            case 4:
                setCustomFilters(false);
                setFieldValue("fromDate", moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'));
                setFieldValue("toDate", moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'));
                // setSelectedDateRange(4);

                break;
            case 5:
                setCustomFilters(false);
                setFieldValue("fromDate", moment().startOf('month').format('YYYY-MM-DD'));
                setFieldValue("toDate", moment().endOf('month').format('YYYY-MM-DD'));
                // setSelectedDateRange(5);

                break;
            case 6:
                setCustomFilters(false);
                setFieldValue("fromDate", moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
                setFieldValue("toDate", moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
                // setSelectedDateRange(6);

                break;
            case 7:
                setCustomFilters(false);
                setFieldValue("fromDate", moment().quarter(moment().quarter()).startOf('quarter').format('YYYY-MM-DD'));
                setFieldValue("toDate", moment().quarter(moment().quarter()).endOf('quarter').format('YYYY-MM-DD'));
                // setSelectedDateRange(7);

                break;
            case 8:
                setCustomFilters(false);
                setFieldValue("fromDate", moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'));
                setFieldValue("toDate", moment().subtract(1, 'quarter').endOf('quarter').endOf('month').format('YYYY-MM-DD'));
                // setSelectedDateRange(8);

                break;
            case 9:
                setCustomFilters(false);
                setFieldValue("fromDate", moment().startOf('year').format('YYYY-MM-DD'));
                setFieldValue("toDate", moment().endOf('year').format('YYYY-MM-DD'));
                // setSelectedDateRange(9);

                break;
            case 10:
                setCustomFilters(false);
                setFieldValue("fromDate", moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'));
                setFieldValue("toDate", moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'));
                // setSelectedDateRange(10);

                break;
            case 11:
                setCustomFilters(true);
                setFieldValue("fromDate", moment());
                setFieldValue("toDate", moment());
                // setFieldValue("toWeek", weekNo[weekNo?.length - 2]);
                // setFieldValue("fromWeek", weekNo[weekNo?.length - 2]);
                if (moment().format('MM-DD') === '01-01') {
                    setFieldValue("fromWeek", 1)
                    // setSelectedGroupFromWeek(1)
                }
                else {
                    setFieldValue("fromWeek", moment().year(moment().format('YYYY')).week());
                    // setSelectedGroupFromWeek(moment(newValue).year(moment(newValue).format('YYYY')).week());
                }
                if (moment().format('MM-DD') === '01-01') {
                    setFieldValue("toWeek", 1)
                    // setSelectedGroupFromWeek(1)
                }
                else {
                    setFieldValue("toWeek", moment().year(moment().format('YYYY')).week());
                    // setSelectedGroupFromWeek(moment(newValue).year(moment(newValue).format('YYYY')).week());
                }
                // setSelectedGroupFromWeek(fromWeekNo[fromWeekNo?.length - 1]);
                // setSelectedGroupToWeek(toWeekNo[toWeekNo?.length - 1]);
                // setSelectedDateRange(11);

                console.log(moment().add(36,).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
                break;
            default:
                setFieldValue("fromDate", moment().format('YYYY-MM-DD'));
                setFieldValue("toDate", moment().format('YYYY-MM-DD'));
                break;
        }

    };

    const resetValue = () => {
        formik.setFieldValue("fromDate", selectedDate?.date1);
        formik.setFieldValue("toDate", selectedDate?.date2);
        formik.setFieldValue("dateRange", selectedDateRange);
        formik.setFieldValue("filter_By", selectedFilter);
        formik.setFieldValue("score_card", selectedScoreCard);
        formik.setFieldValue("event_Type", selectedEventType);
        formik.setFieldValue("sub_type", selectedEventSubType);
        formik.setFieldValue("evaluator", selectedEvaluator);
        formik.setFieldValue("highlightBelow", selectedHighLightBelow)
        if (selectedWeek > 0) {
            setCustomFilters(true);
            formik.setFieldValue("fromWeek", selectedGroupFromWeek);
            formik.setFieldValue("toWeek", selectedGroupToWeek);
        } else {
            setCustomFilters(false);
        }

    }

    // const getAcceptanceGrpData = async (date1, date2, filter, scrd, evnt, subEvnt, evaltr, pgNo, query) => {
    //     setacceptanceByGrpData([]);
    //     setIsLoading(true);
    //     setPageAccpGrp(0);
    //     setFilterNameAcceptanceByGrp("")
    //     try {
    //         const { data } = await getAcceptanceByGroupApi(date1, date2, filter, scrd === 'all' ? '' : scrd, evnt === 'all' ? '' : evnt, subEvnt === 'all' ? '' : subEvnt, evaltr === 'all' ? '' : evaltr, (loggedUserData?.staff && loggedUserData?.staff?.staffType !== 10 ? supGrp : ""), pgNo, query || "");
    //         setacceptanceByGrpData(data?.results);
    //         setCount(data?.count)
    //         setIsLoading(false);
    //     } catch (err) {
    //         setacceptanceByGrpData([]);
    //         setCount(0)
    //         setIsLoading(false);
    //     }
    // }

    const handleFilterByChange = (event) => {
        setFieldValue('teams', '');
        setFieldValue('score_card', '');
        setFieldValue('event_Type', '');
        setFieldValue('sub_type', '');
        setFieldValue('staff', '');
        setFieldValue('scoreCardCategory', '');
        setFieldValue('evaluator', '');
        setFieldValue('supervisor', '');
        setFieldValue('highlightValue', '');
        setFieldValue('section', '');
        setFieldValue('evaluator', '');

        setFieldValue('group', '');
        setFieldValue("highlightBelow", 0)

        setFieldValue("filter_By", event.target.value);
    }


    const handleGroupChange = (event, newVal) => {
        // Resetting remaining fields
        setFieldValue('teams', '');
        setFieldValue('score_card', '');
        setFieldValue('event_Type', '');
        setFieldValue('sub_type', '');
        setFieldValue('staff', '');
        setFieldValue('scoreCardCategory', '');
        setFieldValue('evaluator', '');
        setFieldValue('supervisor', '');
        setFieldValue('highlightValue', '');
        setFieldValue('section', '');
        setFieldValue('evaluator', '');
        setFieldValue("highlightBelow", 0)

        setFieldValue('group', newVal?.id ?? '');
        // setSelectedScGroupFilter(event.target.value);

    };

    const handleTeamChange = (event) => {
        // Resetting remaining fields
        setFieldValue('score_card', '');
        setFieldValue('event_Type', '');
        setFieldValue('sub_type', '');
        setFieldValue('staff', '');
        setFieldValue('scoreCardCategory', '');
        setFieldValue('evaluator', '');
        setFieldValue('supervisor', '');
        setFieldValue('highlightValue', '');
        setFieldValue('section', '');
        setFieldValue('evaluator', '');
        setFieldValue("highlightBelow", 0)
        setFieldValue('teams', event.target.value);
    };

    const handleScoreCardChange = (event, newVal) => {
        console.log("new_val", newVal)
        // Resetting remaining fields
        setFieldValue('score_card', newVal?.scoreCard ?? '');
        setFieldValue('event_Type', '');
        setFieldValue('sub_type', '');
        setFieldValue('staff', '');
        setFieldValue('scoreCardCategory', '');
        setFieldValue('evaluator', '');
        setFieldValue('supervisor', '');
        setFieldValue('highlightValue', '');
        setFieldValue('section', '');
        setFieldValue('evaluator', '');
        setFieldValue("dataTag", []);
        setSelectedScoreCard(newVal?.scoreCard || '')
        const filterScoreCardObj = {
            "fromDate": values.fromDate,
            "toDate": values.toDate,
            "filterBy": values.filter_By,
            "scoreCard": newVal?.scoreCard ?? '',
        }
        getDataTagsByScoreCard(filterScoreCardObj);
    };

    const handleEventTypeChange = (event) => {
        // Resetting remaining fields
        setFieldValue('event_Type', event.target.value);
        setFieldValue('sub_type', '');
        setFieldValue('staff', '');
        setFieldValue('scoreCardCategory', '');
        setFieldValue('evaluator', '');
        setFieldValue('supervisor', '');
        setFieldValue('highlightValue', '');
        setFieldValue('section', '');
        setFieldValue('evaluator', '');
        setFieldValue("highlightBelow", 0)

    };

    const handleEventSubTypeChange = (event) => {
        // Resetting remaining fields
        setFieldValue('sub_type', event.target.value);
        setFieldValue('staff', '');
        setFieldValue('scoreCardCategory', '');
        setFieldValue('evaluator', '');
        setFieldValue('supervisor', '');
        setFieldValue('highlightValue', '');
        setFieldValue('section', '');
        setFieldValue('evaluator', '');
        setFieldValue("highlightBelow", 0)

    };

    const handleSupervisorChange = (event) => {
        setFieldValue('supervisor', event.target.value)
    }

    const handleStaffEmployee = (event) => {
        setFieldValue('staff', event.target.value)

    }

    const handleEvaluatorChange = (event) => {
        // Resetting remaining fields
        setFieldValue('staff', '');
        setFieldValue('scoreCardCategory', '');
        setFieldValue('evaluator', event.target.value);
        setFieldValue('supervisor', '');
        setFieldValue('highlightValue', '');
        setFieldValue('section', '');
        setFieldValue('evaluator', event.target.value);
    };

    const handleDataTags = (event, val) => {
        setFieldValue("dataTag", (event.target.value).flat(100));
        // setselectedDataTag((event.target.value).flat(100))

    }

    const handleAnyOfTag = (event) => {
        setFieldValue('allOfTag', '')
        setFieldValue('anyOfTag', '10')
        setIsAnyOfTag(true)
        setIsAllOfTag(false)
    }

    const handleAllOfTag = () => {
        setFieldValue('anyOfTag', '')
        setFieldValue('allOfTag', '20')
        setIsAnyOfTag(false)
        setIsAllOfTag(true)
    }
    const renderOptions = (props, option) => {
        console.log('render option', option)
        return <li {...props} key={option?.scoreCard}>
            {option?.scoreCardName}
        </li>

    }

    console.log('formikValue', values)
    console.log('reportsScoreLogScorecards', reportsScoreLogScorecards, reportsScoreLogEventSubType)
    return (
        <>
            <Box>
                {addUserButton &&
                    <Button variant="contained" onClick={() => { setOpen(true) }} className="halloween-animation">
                        Filter
                    </Button>
                }
                <Dialog open={open} fullWidth maxWidth="sm">
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                            <DialogTitle sx={{ p: 2 }}>
                                <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
                                    <Typography variant="h5">Report Filter</Typography>
                                    <IconButton onClick={() => { setOpen(false); resetValue(); }}><Cancel /></IconButton>
                                </Stack>
                            </DialogTitle>
                            <Divider />
                            <DialogContent sx={{ p: 2 }}>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                    <Button size="small" startIcon={advancedFilter ? <VisibilityOff /> : <Visibility />} onClick={() => { setAdvancedFilter(!advancedFilter) }}>{advancedFilter ? "Hide" : "Show"} Advanced Features</Button>
                                </Box>

                                <Box sx={{ my: 1 }}>
                                    <Stack direction="row" sx={{ width: "100%", my: 2 }} spacing={2}>
                                        <Box sx={{ width: "50%" }}>
                                            <TextField
                                                fullWidth
                                                required
                                                value={formik?.values?.dateRange}
                                                multiple={false}
                                                select
                                                label="Date Range"
                                                name="dateRange"
                                                onChange={(e) => { handleDateRangeChange(e) }}
                                            >
                                                {DateRangeList?.map((item) => (
                                                    <MenuItem key={item.id} id={item.id} value={item.id}>
                                                        {item.dateRange}
                                                    </MenuItem >
                                                ))}

                                            </TextField>
                                            {/* {formik.errors.fname && formik.touched.fname ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.fname}</Typography> : null} */}
                                        </Box>
                                        <Box sx={{ width: "50%" }}>
                                            <TextField
                                                fullWidth
                                                value={formik?.values?.filter_By}
                                                required
                                                select
                                                label="Filter By"
                                                name="filter_By"
                                                onChange={handleFilterByChange}

                                            >
                                                {filterBy?.map((item) => (
                                                    <MenuItem key={item.id} id={item.id} value={item.value}>
                                                        {item.type}
                                                    </MenuItem >
                                                ))}
                                            </TextField>
                                            {/* {formik.errors.lname && formik.touched.lname ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.lname}</Typography> : null} */}
                                        </Box>

                                    </Stack>
                                    {customFilters && <Box>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                                <Box sx={{ width: "50%" }}>
                                                    <DesktopDatePicker
                                                        label="Date From"
                                                        inputFormat="yyyy/MM/dd"
                                                        name="fromDate"
                                                        minDate={new Date(dataCreatedFrom)}
                                                        disableFuture
                                                        value={formik?.values?.fromDate}

                                                        onChange={(newValue) => {
                                                            if (moment(newValue).format('MM-DD') === '01-01') {
                                                                formik.setFieldValue("fromWeek", 1)
                                                                // setSelectedGroupFromWeek(1)
                                                            }
                                                            else {
                                                                formik.setFieldValue("fromWeek", moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                                                                // setSelectedGroupFromWeek(moment(newValue).year(moment(newValue).format('YYYY')).week());
                                                            }
                                                            formik.setFieldValue("fromDate", newValue)
                                                            if (moment(newValue, "YYYY-MM-DD").isAfter(formik.values.toDate)) {
                                                                formik.setFieldValue("toDate", newValue)
                                                                if (moment(newValue).format('MM-DD') === '01-01') {
                                                                    formik.setFieldValue("toWeek", 1)
                                                                } else {
                                                                    formik.setFieldValue("toWeek", moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                                                                }

                                                            }
                                                        }}
                                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                                    />
                                                    {formik.errors.eventDate && formik.touched.eventDate && <Typography variant="caption" sx={{ color: "red", ml: 1 }}>{formik.errors.eventDate}</Typography>}

                                                </Box>

                                                <Box sx={{ width: "50%" }}>
                                                    <DesktopDatePicker
                                                        label="Date To"
                                                        inputFormat="yyyy/MM/dd"
                                                        minDate={new Date(dataCreatedFrom)}
                                                        name="toDate"
                                                        disableFuture
                                                        {...getFieldProps('toDate')}
                                                        onChange={(newValue) => {
                                                            if (moment(newValue).format('MM-DD') === '01-01') {
                                                                formik.setFieldValue("toWeek", 1)
                                                                // setSelectedGroupToWeek(1)
                                                            } else {
                                                                formik.setFieldValue("toWeek", moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                                                                // setSelectedGroupToWeek(moment(newValue).year(moment(newValue).format('YYYY')).week())
                                                            }
                                                            setFieldValue("toDate", newValue)

                                                            // Extract year from full date
                                                            const fullDate = moment(newValue);
                                                            const extractyear = fullDate.year();

                                                            if (moment(newValue, "YYYY-MM-DD").isBefore(formik.values.fromDate) && /^\d{4}$/.test(extractyear)) {
                                                                formik.setFieldValue("fromDate", newValue)
                                                                if (moment(newValue).format('MM-DD') === '01-01') {
                                                                    formik.setFieldValue("fromWeek", 1)
                                                                } else {
                                                                    formik.setFieldValue("fromWeek", moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                                                                }
                                                            }
                                                        }}
                                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                                    />
                                                    {formik.errors.eventDate && formik.touched.eventDate && <Typography variant="caption" sx={{ color: "red", ml: 1 }}>{formik.errors.eventDate}</Typography>}

                                                </Box>
                                            </Stack>
                                        </LocalizationProvider>
                                        <Stack direction="row" sx={{ width: "100%", mb: 2 }} spacing={2}>
                                            <Box sx={{ width: "50%" }}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    value={formik?.values?.fromWeek}
                                                    multiple={false}
                                                    select
                                                    name="fromWeek"
                                                    onChange={(e) => {
                                                        moment.updateLocale('en', {
                                                            week: {
                                                                dow: 1
                                                            }
                                                        });
                                                        formik.setFieldValue("fromWeek", e.target.value);
                                                        formik.setFieldValue("fromDate", moment().week(e.target.value).startOf('week'));
                                                        const newDate = moment().week(e.target.value).startOf('week').format('YYYY-MM-DD')
                                                        if (moment(newDate, "YYYY-MM-DD").isAfter(formik.values.toDate)) {
                                                            formik.setFieldValue("toDate", newDate)
                                                        }
                                                    }}
                                                >
                                                    {fromWeekNo?.map((item) => (
                                                        <MenuItem key={item} id={item} value={item}>
                                                            Week&nbsp;{item}
                                                        </MenuItem >
                                                    ))}

                                                </TextField>
                                                {/* {formik.errors.fname && formik.touched.fname ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.fname}</Typography> : null} */}
                                            </Box>
                                            <Box sx={{ width: "50%" }}>
                                                <TextField
                                                    fullWidth
                                                    multiple={false}
                                                    value={formik?.values?.toWeek}
                                                    required
                                                    select
                                                    name="toWeek"
                                                    onChange={(e) => {
                                                        moment.updateLocale('en', {
                                                            week: {
                                                                dow: 1
                                                            }
                                                        });
                                                        formik.setFieldValue("toWeek", e.target.value);
                                                        formik.setFieldValue("toDate", moment().week(e.target.value).endOf('week'));
                                                        const newDate = moment().week(e.target.value).endOf('week').format('YYYY-MM-DD')
                                                        if (moment(newDate, "YYYY-MM-DD").isBefore(formik.values.fromDate)) {
                                                            formik.setFieldValue("fromDate", newDate)
                                                        }
                                                    }}

                                                >
                                                    {toWeekNo?.map((item) => (
                                                        <MenuItem disabled={formik.values.fromWeek > item} key={item} id={item} value={item}>
                                                            Week&nbsp;{item}
                                                        </MenuItem >
                                                    ))}
                                                </TextField>
                                                {/* {formik.errors.lname && formik.touched.lname ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.lname}</Typography> : null} */}
                                            </Box>

                                        </Stack>
                                    </Box>}

                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        {(subModLabel !== 6.7 && subModLabel !== 6.5) &&
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    fullWidth
                                                    value={(
                                                        (subModLabel === 6.6 || subModLabel === 6.3 || subModLabel === 6.1)
                                                            ? ([{
                                                                name: 'All', id: ''
                                                            }].concat(reportsScoreLogGroups)) : reportsScoreLogGroups)?.find((option) => option?.id === values?.group)}
                                                    options={
                                                        (subModLabel === 6.6 || subModLabel === 6.3 || subModLabel === 6.1)
                                                            ? ([{
                                                                name: 'All', id: ''
                                                            }].concat(reportsScoreLogGroups)) : reportsScoreLogGroups}
                                                    getOptionLabel={(option) => option?.name || ""}
                                                    onChange={(event, newValue) => {
                                                        handleGroupChange(event, newValue);
                                                    }}
                                                    id="controllable-states-demo"
                                                    renderInput={(params) => <TextField {...params} label="Group"
                                                        popoverProps={{
                                                            style: {
                                                                bottom: 0,
                                                                overflowY: 'auto'
                                                            }
                                                        }}
                                                        variant="outlined"
                                                        error={Boolean(touched.group && errors.group)}
                                                        helperText={(touched.group && errors.group)}
                                                        required
                                                    />}
                                                />
                                                {/* {errors.group && touched.group ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', p: 1 }}>This field is required</Typography> : null} */}
                                            </FormControl>
                                            // <FormControl fullWidth>
                                            //     <InputLabel shrink id="group-label">
                                            //         Group
                                            //     </InputLabel>
                                            //     <Select
                                            //         labelId="group-label"
                                            //         id="demo-select-small"
                                            //         displayEmpty
                                            //         // value={values.group || ''}
                                            //         input={<OutlinedInput notched label="Group" />}
                                            //         error={Boolean(touched.group && errors.group)}
                                            //         // label="Group"
                                            //         value={values.group}
                                            //         {...getFieldProps('group')}
                                            //         onChange={handleGroupChange}
                                            //     >
                                            //         {
                                            //             (subModLabel === 6.6 || subModLabel === 6.3 || subModLabel === 6.1) ? <MenuItem value=""> All </MenuItem> : <MenuItem value="">--Select Group -- </MenuItem>
                                            //         }
                                            //         {(subModLabel === 6.6 ? calibrationTrendGroup : reportsScoreLogGroups)?.map((item) => (
                                            //             <MenuItem key={item.id} id={item.id} value={item.id}>
                                            //                 {item.name}
                                            //             </MenuItem>
                                            //         ))}
                                            //     </Select>
                                            // </FormControl>
                                        }

                                        {(subModLabel === 6.4) && <FormControl fullWidth>
                                            <InputLabel shrink id="teams-label">
                                                Teams
                                            </InputLabel>
                                            <Select
                                                // defaultValue='All'
                                                labelId="teams-label"

                                                id="demo-select-small"
                                                displayEmpty
                                                value={formik?.values?.teams}
                                                // label="Teams"
                                                input={<OutlinedInput notched label="Teams" />}
                                                {...getFieldProps('teams')}
                                                onChange={handleTeamChange}
                                            >
                                                <MenuItem value=""> All </MenuItem>
                                                {(subModLabel === 6.6 ? calibrationTrendTeam : reportsScoreLogTeams)?.map((item) => (
                                                    <MenuItem key={item.id} id={item.id} value={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>}
                                    </Stack>

                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        {
                                            (subModLabel !== 6.6 && subModLabel !== 6.2 && subModLabel !== 6.3 && subModLabel !== 6.5) && <Box sx={{ width: "100%", my: 2 }}>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        value={([{
                                                            scoreCard: '',
                                                            scoreCardName: 'All',
                                                        }].concat(reportsScoreLogScorecards))?.find((option) =>
                                                            option?.scoreCard === (values?.score_card || selectedScoreCard)
                                                        )}
                                                        fullWidth
                                                        options={[{
                                                            scoreCard: '',
                                                            scoreCardName: 'All'
                                                        }].concat(reportsScoreLogScorecards)}
                                                        getOptionLabel={(option) => option?.scoreCardName || ""}
                                                        renderOption={renderOptions}
                                                        onChange={(event, newValue) => {
                                                            handleScoreCardChange(event, newValue);
                                                        }}
                                                        id="controllable-states-demo"
                                                        renderInput={(params) => <TextField {...params} label="Scorecard"
                                                            popoverProps={{
                                                                style: {
                                                                    bottom: 0,
                                                                    overflowY: 'auto'
                                                                }
                                                            }}
                                                            variant="outlined"
                                                            error={(touched.score_card && errors.score_card)}
                                                            helperText={(touched.score_card && errors.score_card)}
                                                            required
                                                        />}
                                                    />
                                                    {/* {errors.score_card && touched.score_card ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', p: 1 }}>This field is required</Typography> : null} */}
                                                </FormControl>
                                            </Box>
                                        }

                                        {
                                            (dataTagByScoreCard?.data?.length > 0 && (subModLabel === 6.1 || subModLabel === 6.4 || subModLabel === 6.7)) && <Box sx={{ width: '100%' }}> <FormControl fullWidth sx={{ width: '100%', my: 2 }}>

                                                <InputLabel shrink id="scorecard-label">
                                                    Data Tags
                                                </InputLabel>
                                                <Select
                                                    labelId="scorecard-label"
                                                    id="demo-select-small"
                                                    fullWidth
                                                    multiple
                                                    // displayEmpty
                                                    value={formik?.values?.dataTag}
                                                    input={<OutlinedInput notched label="Data Tags" />}
                                                    {...getFieldProps('dataTag')}
                                                    onChange={handleDataTags}
                                                >
                                                    {dataTagByScoreCard?.data?.map((item, i) => (
                                                        <MenuItem key={i} id={item.dataTags} value={item.dataTags}>
                                                            {item?.dataTagsName}
                                                        </MenuItem >
                                                    ))}
                                                </Select>
                                            </FormControl>


                                                <Stack direction={{ sm: 'row' }} spacing={0} alignItems="flex-end" justifyContent="flex-end">
                                                    <FormControlLabel
                                                        name="anyOfTag"
                                                        sx={{ marginLeft: '0px', marginRight: '0px' }}
                                                        // value="10"
                                                        control={<Radio />}
                                                        label="Any of the Tags"
                                                        checked={isAnyOfTag}
                                                        onChange={handleAnyOfTag}
                                                    />
                                                    {/* {...getFieldProps('anyOfTag')} */}

                                                    <FormControlLabel
                                                        name="allOfTag"
                                                        sx={{ marginLeft: '0px', marginRight: '0px' }}
                                                        // value="20"
                                                        control={<Radio />}
                                                        label="All of the Tags"
                                                        onChange={handleAllOfTag}
                                                        checked={isAllOfTag}


                                                    />
                                                    {/* {...getFieldProps('allOfTag')} */}

                                                </Stack>



                                            </Box>}

                                    </Stack>





                                    {
                                        (subModLabel !== 6.6 && subModLabel !== 6.5) && <Stack direction="row" sx={{ width: "100%", my: 2 }} spacing={2}>
                                            <Box sx={{ width: "50%" }}>
                                                <FormControl fullWidth sx={{ width: "100%" }}>
                                                    <InputLabel shrink id="eventType-label">
                                                        Event-Type
                                                    </InputLabel>
                                                    <Select

                                                        labelId="eventType-label"
                                                        id="demo-select-small"
                                                        fullWidth
                                                        name='event_type'
                                                        displayEmpty
                                                        value={formik?.values?.event_Type}
                                                        input={<OutlinedInput notched label="Event-Type" />}
                                                        {...getFieldProps('event_Type')}
                                                        onChange={handleEventTypeChange}
                                                    >
                                                        <MenuItem value="">All</MenuItem>
                                                        {reportsScoreLogEventType?.map((item) => (

                                                            <MenuItem key={item.eventType} id={item.eventType} value={item.eventType}>
                                                                <Typography sx={{ fontSize: '14px', display: 'inline' }}>{item.eventTypeEventType}</Typography>&nbsp;<Typography sx={{ display: 'inline-block', fontStyle: 'italic', fontSize: '12px', fontWeight: 'bold' }}>{`(${item?.scorecard})`}</Typography>
                                                            </MenuItem>

                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {/* {formik.errors.fname && formik.touched.fname ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.fname}</Typography> : null} */}
                                            </Box>
                                            <Box sx={{ width: "50%" }}>
                                                <FormControl fullWidth sx={{ width: "100%" }}>
                                                    <InputLabel shrink id="eventSubType-label">
                                                        Event-SubType
                                                    </InputLabel>
                                                    <Select

                                                        labelId="eventSubType-label"
                                                        id="demo-select-small"
                                                        fullWidth
                                                        name='sub_type'
                                                        displayEmpty
                                                        value={formik?.values?.sub_type}
                                                        input={<OutlinedInput notched label=" Event-SubType" />}
                                                        {...getFieldProps('sub_type')}
                                                        onChange={handleEventSubTypeChange}
                                                    >
                                                        <MenuItem value="">All</MenuItem>
                                                        {reportsScoreLogEventSubType?.map(
                                                            (item, index) =>


                                                                <MenuItem key={index} id={item.eventSubType} value={item.eventSubType}>
                                                                    {/* <Tooltip placement="left-end" title={`Scorecard: ${item?.scorecard}`} /> */}
                                                                    <Typography sx={{ fontSize: '14px', display: 'inline' }}>{item?.subType}</Typography>&nbsp;<Typography sx={{ display: 'inline-block', fontStyle: 'italic', fontSize: '12px', fontWeight: 'bold' }}>{`(${item?.scorecard})`}</Typography>
                                                                </MenuItem>


                                                        )}</Select>
                                                </FormControl>
                                                {/* {formik.errors.lname && formik.touched.lname ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.lname}</Typography> : null} */}
                                            </Box>

                                        </Stack>
                                    }

                                    {advancedFilter && <>
                                        {(subModLabel === 6.4) && <>
                                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                                <FormControl fullWidth>
                                                    <InputLabel shrink id="group-label">Supervisor</InputLabel>
                                                    <Select
                                                        // defaultValue='All'
                                                        labelId="group-label"
                                                        id="demo-select-small"
                                                        displayEmpty
                                                        value={formik?.values?.supervisor}
                                                        // label="Supervisor"
                                                        input={<OutlinedInput notched label="Supervisor" />}
                                                        {...getFieldProps('supervisor')}
                                                        onChange={handleSupervisorChange}
                                                    >
                                                        <MenuItem value=""> All </MenuItem >
                                                        {reportsScoreLogSupervisor?.map((item) => (
                                                            <MenuItem key={item.supervisor} id={item.supervisor} value={item.supervisor}>
                                                                {`${item.supervisorUserFirstName} ${item.supervisorUserLastName}`}
                                                            </MenuItem >
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <InputLabel shrink id="scorecard-label">Employee</InputLabel>
                                                    <Select
                                                        labelId="scorecard-label"
                                                        id="demo-select-small"
                                                        displayEmpty
                                                        required
                                                        input={<OutlinedInput notched label="evaluator" />}
                                                        error={Boolean(touched.staff && errors.staff)}
                                                        helperText={touched.staff && errors.staff}
                                                        value={formik?.values?.staff}
                                                        {...getFieldProps('staff')}
                                                        onChange={handleStaffEmployee}
                                                    >
                                                        <MenuItem value=""> All </MenuItem >
                                                        {reportsScoreLogEmployee?.map((item) => (
                                                            <MenuItem key={item.staff} id={item.staff} value={item.staff}>
                                                                {`${item.staffUserFirstName} ${item.staffUserLastName}`}
                                                            </MenuItem >
                                                        ))}
                                                    </Select>
                                                </FormControl>

                                            </Stack>
                                        </>}
                                        <Stack direction="row" sx={{ width: "100%", my: 2 }} spacing={2}>
                                            {(subModLabel === 6.3 || subModLabel === 6.2 || subModLabel === 6.4 || subModLabel === 6.1) &&
                                                <FormControl fullWidth>
                                                    <TextField
                                                        value={formik?.values?.highlightBelow}
                                                        name='highlightBelow'
                                                        label='Highlight Below'
                                                        fullWidth
                                                        {...getFieldProps('highlightBelow')}
                                                        onChange={(e) => formik.setFieldValue("highlightBelow", e.target.value)}
                                                    />
                                                </FormControl>
                                            }
                                            {
                                                (subModLabel === 6.4) && <FormControl fullWidth>
                                                    <InputLabel shrink id="group-label">Formula</InputLabel>
                                                    <Select
                                                        labelId="group-label"
                                                        id="demo-select-small"
                                                        displayEmpty
                                                        name='formula'
                                                        value={formik?.values?.formula}
                                                        input={<OutlinedInput notched label="Formula" />}
                                                        {...getFieldProps('formula')}

                                                    >
                                                        {formulaType?.map((item, i) => (
                                                            <MenuItem key={i} id={item.id} value={item?.id}>
                                                                {item.type}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            }

                                        </Stack>
                                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                            {
                                                (subModLabel !== 6.1 && subModLabel !== 6.2 && subModLabel !== 6.3 && subModLabel !== 6.5) && <FormControl fullWidth sx={{ width: "100%" }}>
                                                    <InputLabel shrink id="evaluator-label">
                                                        Evaluator
                                                    </InputLabel>
                                                    <Select

                                                        labelId="evaluator-label"
                                                        id="demo-select-small"
                                                        fullWidth
                                                        displayEmpty
                                                        value={formik?.values?.evaluator}
                                                        input={<OutlinedInput notched label="Evaluator" />}
                                                        {...getFieldProps('evaluator')}
                                                        onChange={handleEvaluatorChange}
                                                    >
                                                        <MenuItem value="">All</MenuItem>
                                                        {(subModLabel === 6.6 ? calibrationTrendEvaluator : reportsScoreLogEvaluator)?.map(obj => <MenuItem key={obj?.evaluator} value={obj?.evaluator}>{`${obj?.evaluatorFirstName} ${obj?.evaluatorLastName}`}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            }

                                        </Stack>

                                    </>}
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", pr: 1, my: 1 }}>
                                        <Button sx={{ mx: 1 }} onClick={() => { setOpen(false); resetValue(); }} variant="contained">Cancel</Button>
                                        <Button disabled={circular} sx={{ mx: 1 }} type="submit" variant="contained">Apply{circular && <CircularProgress size={14} sx={{ ml: 1 }} />}</Button>
                                    </Box>
                                </Box>

                            </DialogContent>
                        </Form>
                    </FormikProvider>
                </Dialog>

            </Box>
        </>
    )
}
