import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';

// MATERIAL
import { styled } from '@material-ui/core/styles';
import { AppBar, Box, Button, Divider, IconButton, Stack, Toolbar } from '@material-ui/core';
// HOOKS
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// COMPONENTS
import { MHidden } from '../../components/@material-extend';
import AccountPopover from './AccountPopover';
import SpeechAiPopover from './AccountPopover1';
import NavBarMenuDropDown from './NavbarMenuDropdown';
import Logo from "../../components/Logo";
import NewYearCountdown from '../../components/NewYearCountdown';
import { getMessageCount } from "../../_apis_/authApi.js/globalDashboardApi";
// IMPORT CONTEXT 
import { SettingsContext } from "../../contexts/SettingsContext"
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

const DRAWER_WIDTH_XL = 280;
const DRAWER_WIDTH_LG = 200;
const COLLAPSE_WIDTH_LG = 48;
const COLLAPSE_WIDTH_XL = 60;

// const APPBAR_MOBILE = 64;
const APPBAR_MOBILE = 34;
// const APPBAR_DESKTOP = 92;
const APPBAR_DESKTOP = 70;



// ----------------------------------------------------------------------



DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar() {
  const {
    getCompanySettings,
    setHeaderLogo, favIconLogo, setFavIconLogo } = useAuth();
  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
  const { setNotificationMsgCount, notificationMsgCount, showHelpSupportPopUp, setShowHelpSupportPopUp, showSidebar, setShowSidebar, dataCreatedFrom, setDataCreatedFrom, isOpenSidebar, setIsOpenSidebar } = React.useContext(SettingsContext)
  const { isCollapse } = useCollapseDrawer();
  const { pathname } = useLocation();

  const ref = React.useRef(null);

  const RootStyle = styled(AppBar)(({ theme, path }) => ({
    boxShadow: 'none',
    zIndex: 200,
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('lg')]: {
      width: showSidebar ? `calc(100% - ${DRAWER_WIDTH_LG}px)` : '100%'
    },
    [theme.breakpoints.up('xl')]: {
      width: showSidebar ? `calc(100% - ${DRAWER_WIDTH_XL}px)` : '100%'
    }
  }));

  const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
      minHeight: APPBAR_DESKTOP,
      padding: theme.spacing(0, 5)
    }
  }));


  const [isMore, setIsMore] = React.useState(false);

  React.useEffect(() => {
    async function getCount() {
      const { data } = await getMessageCount();
      setNotificationMsgCount(data?.data);
    }
    getCount();
    setIsMore(false)

  }, [pathname, notificationMsgCount])

  React.useEffect(() => {
    const fetchMyAPI = async () => {
      const response = await getCompanySettings();
      setHeaderLogo(response?.logo);
      setFavIconLogo(response?.favicon);
      setShowSidebar(response?.showSidebar);
      const favIcon = document.getElementById("favicon");
      favIcon.href = response?.favicon;
      localStorage.setItem('dataCreatedOn', JSON.stringify(response?.createdOn));
      setDataCreatedFrom(response?.createdOn);

    }


    fetchMyAPI();
  }, [])

  React.useEffect(() => {
    const fetchMyAPI = async () => {
      const response = await getCompanySettings();
      setHeaderLogo(response?.logo);
      setFavIconLogo(response?.favicon);
      setShowSidebar(response?.showSidebar);
      const favIcon = document.getElementById("favicon");
      favIcon.href = response?.favicon;
      localStorage.setItem('dataCreatedOn', JSON.stringify(response?.createdOn));
      setDataCreatedFrom(response?.createdOn);

    }


    fetchMyAPI();
  }, [])




  return (
    <RootStyle
      path={pathname}
      sx={{
        ...(isCollapse && {
          width: !showSidebar ? '100%' : { lg: `calc(100% - ${COLLAPSE_WIDTH_LG}px)`, xl: `calc(100% - ${COLLAPSE_WIDTH_XL}px)` },
        })
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={() => setIsOpenSidebar(true)} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        {!showSidebar && <Link to={!loggedUserData?.staff?.staffType ? "/dashboard" : "/supervisor/dashboard"}>
          <Box sx={{ mb: { lg: 1, xl: 0 }, mr: { lg: 2, xl: 3 }, display: { xs: 'none', lg: 'block' }, width: { xs: '160px', lg: '100%' } }}><Logo /></Box>
        </Link>}

        <Box sx={{ flexGrow: 1 }} >
          {!showSidebar && <Stack direction='row' alignItems='center' sx={{ width: '100%', display: { xs: 'none', lg: 'flex' } }}>

            <SpeechAiPopover fromDashboard /> {/* CONTAINS MENU OPTIONS FOR DASHBOARD */}

            <NavBarMenuDropDown />  { /* CONTAINS MENU OPTIONS FOR SCORES FROM DASHBOARD TO ANALYTICS  */}

            {/* <Divider orientation='vertical' sx={{ height: '40px', ml: 2 }} />
            <Divider orientation='vertical' sx={{ height: '40px', mr: 1 }} /> */}

            <SpeechAiPopover fromDashboard={false} /> { /* CONTAINS MENU OPTIONS FOR AI */}

          </Stack>}

        </Box>

        <Stack direction="row" alignItems="center" sx={{ display: { xs: isMore ? 'flex' : 'flex', xl: 'flex' }, mr: 0 }} spacing={{ xs: 0.5, sm: 1.5 }}>

          {/* <NewYearCountdown /> */}
          {!pathname?.includes('support') && <Button elevation={5} disableFocusRipple disableRipple onClick={() => setShowHelpSupportPopUp(!showHelpSupportPopUp)} variant='' sx={{ borderRadius: 5, boxShadow: 'none', pr: 0, '&:hover': { backgroundColor: 'transparent' } }} size='small' >  <Box component='img' alt="Custom SVG Icon" src="/static/illustrations/navbar/support.svg" height="30" /> </Button>}

          <AccountPopover messageCount={notificationMsgCount} />


        </Stack>
      </ToolbarStyle>



    </RootStyle >
  );
}

