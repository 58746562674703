import { Button } from '@material-ui/core';

// SX IS FOR STYLE COMPONENT 
// VARIANT IS SAME AS BUTTON VARIANT OF MUI BUTTONS ELSE BY DEFAULT CONTAINED WILL BE SET
// STARTICON AND ENDICON SAME AS MUI BUTTON
// FN IS FOR ONCLICK FUNCTION
// LABEL IS FOR BUTTON LABEL

export default function GlobalButton({ sx, variant, fn, label, startIcon, endIcon, size, disabled }) {


    return (
        <Button size={size || 'medium'} disabled={disabled} elevation={5} startIcon={startIcon} endIcon={endIcon} sx={{ fontWeight: 400, px: '20px', py: 1, borderRadius: '5px', mr: 2, ...sx }} variant={variant || 'contained'}
            onClick={() => fn()}
        >{label}</Button>
    )
}