import React from 'react';
// MATERIAL IMPORTS
import {
    Autocomplete,
    Box, Button,
    CircularProgress,
    Dialog, DialogContent, DialogTitle, Divider,
    FormLabel, FormControl, FormControlLabel,
    IconButton, InputLabel,
    ListSubheader,
    MenuItem,
    OutlinedInput,
    Radio, RadioGroup,
    Select, Stack,
    TextField, Tooltip, Typography,

} from "@material-ui/core";
import {
    Cancel,
    EqualizerOutlined,
    LibraryAdd,
    PieChartOutline,
    SettingsOutlined,
    TableRowsOutlined, TimelineOutlined,
} from "@material-ui/icons";
import AddIcon from '@mui/icons-material/Add';
// NPM IMPORTS
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import _ from "lodash";
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack5';
import { Icon } from '@iconify/react';


// COMPONENT IMPORTS
import { MIconButton } from '../../../../components/@material-extend';
import GlobalButton from '../../../Common Component/GlobalButton';


// utils Imports
import { customDateRangeList, filterByList } from "../../../../utils/filterUtilities";

// CONTEXT IMPORTS
import ScoreCardsContext from "../../../../contexts/ScoreCardsContext";
import { AnalyticContext } from '../../../../contexts/AnalyticContext';

// API IMPORTS
import { updateCustomAnalyticReportApi, addCustomAnalyticReportApi, getAllCustomAnalyticReportApi } from "../../../../_apis_/authApi.js/analytic_api";

export default function AddCustomReportDialog({ addUserButton, filterValue, text, isReportSetting, setAddMode, closeAddUserDialog, isEmptyCustom }) {

    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));

    // SNACBAR STATES

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // CONTEXT STATES
    const {
        getScoreLogGroups,
        reportsScoreLogGroups,
        getScoreLogScorecards,
        scorecardSection,
        getScorecardSection,
        reportsScoreLogScorecards,
        getScoreLogEventType,
        reportsScoreLogEventType,
        getScoreLogEventSubType,
        reportsScoreLogEventSubType,
        getScoreLogTeams,
        reportsScoreLogTeams,

    } = React.useContext(ScoreCardsContext);


    const {
        customData, setCustomData, dialogVisible, setDialogVisible, setUpdateCustomId, updateCustomId
    } = React.useContext(AnalyticContext);

    // REACT STATES
    const [open, setOpen] = React.useState(false);
    const [reportFieldValue, setReportFieldValue] = React.useState("");
    const [radioBtnValue, setRadioBtnValue] = React.useState();
    const [titleText, setTitleText] = React.useState();
    const [reportSettingObj, setReportSettingObject] = React.useState();
    const [customLoader, setCustomLoader] = React.useState(false);


    // FORMIK STATES
    React.useEffect(() => {

        if (reportsScoreLogGroups && reportSettingObj) {
            // formik.setFieldValue("group", Number(filterValue?.score_card__groups))
            setOpen(true);
            setDialogVisible(true);
        }

    }, [reportsScoreLogEventSubType])
    const formik = useFormik({
        initialValues: {
            group: '', teams: '', fromDate: '', toDate: '', filterBy: 'created_at', dateRange: 'Rolling 4 Weeks', scoreCard: '', eventType: '', eventSubType: '', formula: 10, interval: 10, dataLimit: 10,

        },
        validationSchema: Yup.object().shape({
            group: (reportFieldValue === '' || reportFieldValue === 6.1 || reportFieldValue === 6.3) ? Yup.string() : Yup.string().required('Required Fields'),
            scoreCard: (reportFieldValue === '' || reportFieldValue === 6.1 || reportFieldValue === 6.3 || reportFieldValue === 5.1 || reportFieldValue === 7.1) ? Yup.string() : Yup.string().required('Required Fields')
        }),
        onSubmit: async (values) => {
            if (reportFieldValue !== '' && !reportSettingObj) {
                setCustomLoader(true);
                const filterObj =
                {
                    "report_module": `${reportFieldValue}`,
                    "report_type": `${radioBtnValue}`,
                    "report_params": {
                        "from_date": reportFieldValue === 4.7 ? moment().subtract(1, 'year').format('YYYY-MM-DD') : values.fromDate,
                        "to_date": reportFieldValue === 4.7 ? moment().format('YYYY-MM-DD') : values.toDate,
                        "filter_by": values.filterBy,
                        "date_type": values.interval,
                        "date_range_type": values.dateRange,
                        "score_card__groups": values.group,
                        "score_card": values.scoreCard,
                        "teams": values.teams,
                        "event_type": values.eventType,
                        "event_sub_type": values.eventSubType,
                        "formula": values.formula,
                        "data_limit": values.dataLimit,
                    }
                }


                try {
                    // if (customData?.length > 10) {
                    //     enqueueSnackbar('Maximum number of 5 rows reached', {
                    //         variant: 'error',
                    //         action: (key) => (

                    //             <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                    //                 <Icon icon={closeFill} />
                    //             </MIconButton>
                    //         )
                    //     });
                    //     handleClose();
                    //     return
                    // }
                    const res = await addCustomAnalyticReportApi(filterObj);
                    const { data } = await getAllCustomAnalyticReportApi();
                    setCustomData(data)
                    enqueueSnackbar('Added Report Successfully', {
                        variant: 'success',
                        action: (key) => (

                            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </MIconButton>
                        )
                    });

                    handleClose();

                } catch (err) {
                    enqueueSnackbar('Report Not Added', {
                        variant: 'error',
                        action: (key) => (

                            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </MIconButton>
                        )
                    });
                    handleClose();
                }


            }
            if (reportFieldValue !== '' && reportSettingObj) {
                setCustomLoader(true);
                const filterObj =
                {
                    "report_module": `${reportFieldValue}`,
                    "report_type": `${radioBtnValue}`,
                    "report_params": {
                        "from_date": values.fromDate,
                        "to_date": values.toDate,
                        "filter_by": values.filterBy,
                        "date_type": values.interval,
                        "date_range_type": values.dateRange,
                        "score_card__groups": values.group,
                        "score_card": values.scoreCard !== '' ? Number(values.scoreCard) : values.scoreCard,
                        "teams": values?.teams !== '' ? Number(values.teams) : values?.teams,
                        "event_type": values.eventType,
                        "event_sub_type": values.eventSubType,
                        "formula": values.formula,
                        "data_limit": values.dataLimit,

                    }
                }

                try {
                    const res = await updateCustomAnalyticReportApi(reportSettingObj?.id, filterObj)

                    const temp = customData?.map(item => { if (item?.id === res?.data?.id) { return res?.data } return item })
                    console.log(customData, temp)
                    setCustomData(temp);

                    // const { data } = await getAllCustomAnalyticReportApi();
                    enqueueSnackbar('Updated Report Successfully', {
                        variant: 'success',
                        action: (key) => (

                            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </MIconButton>
                        )
                    });
                    // setCustomData(data);
                    setUpdateCustomId(res?.data?.id);

                    handleClose(true);
                } catch (err) {
                    enqueueSnackbar('Report Not Updated', {
                        variant: 'error',
                        action: (key) => (

                            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </MIconButton>
                        )
                    });
                }
            }
        }
    })
    const { errors, touched, values, setFieldValue, getFieldValue, handleSubmit, isSubmitting, getFieldProps } = formik;

    const handleClickOpen = () => {
        if (filterValue?.from_date) {
            setReportFieldValue(Number(filterValue?.module));
            setRadioBtnValue(Number(filterValue?.type));
            fetchFilterValues(filterValue?.score_card__groups);
            setReportSettingObject(filterValue);

        }
        if (filterValue?.from_date) {
            setTitleText("Report Setting")
        } else {
            setTitleText("Add Custom Report")
            setReportSettingObject(undefined)
            setOpen(true)
            setDialogVisible(true);

        }

    };

    // REACT STATES

    const handleClose = (isUpdate) => {
        if (!isUpdate) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
        setOpen(false);
        setDialogVisible(false);
        setReportFieldValue("");
        setRadioBtnValue();
        setReportSettingObject(undefined);
        setCustomLoader(false);

    }


    const handleDateRangeChange = (event) => {
        // Resetting remaining fields
        setFieldValue('group', '');
        setFieldValue('teams', '');
        setFieldValue('scoreCard', '');
        setFieldValue('eventType', '');
        setFieldValue('eventSubType', "");
        setFieldValue('staff', '');
        setFieldValue('scoreCardCategory', '');
        setFieldValue('evaluator', '');
        setFieldValue('supervisor', '');
        setFieldValue('highlightValue', '');
        setFieldValue('section', '');
        setFieldValue('evaluator', '');
        setFieldValue('dataTag', []);
        // setDataTagByScoreCard([]);

        const dateRange = event.target.value;
        setFieldValue('dateRange', dateRange);
        const dates = { date1: '', date2: '' };
        // setScorecardType(event.target.value);
        switch (dateRange) {
            case 'Today':
                // setCustomFilters(false);
                setFieldValue('fromDate', moment().format('YYYY-MM-DD'));
                setFieldValue('toDate', moment().format('YYYY-MM-DD'));
                dates.date1 = moment().format('YYYY-MM-DD'); dates.date2 = moment().format('YYYY-MM-DD')


                break;
            case 'Yesterday':
                // setCustomFilters(false);
                setFieldValue('fromDate', moment().subtract(1, 'days').format('YYYY-MM-DD'));
                setFieldValue('toDate', moment().subtract(1, 'days').format('YYYY-MM-DD'));
                dates.date1 = moment().subtract(1, 'days').format('YYYY-MM-DD'); dates.date2 = moment().subtract(1, 'days').format('YYYY-MM-DD')


                break;
            case 'This Week':
                // setCustomFilters(false);
                setFieldValue('fromDate', moment().startOf('week').format('YYYY-MM-DD'));
                setFieldValue('toDate', moment().endOf('week').format('YYYY-MM-DD'));
                dates.date1 = moment().startOf('week').format('YYYY-MM-DD'); dates.date2 = moment().endOf('week').format('YYYY-MM-DD')


                break;
            case 'Last Week':
                // setCustomFilters(false);
                setFieldValue('fromDate', moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'));
                setFieldValue('toDate', moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'));
                dates.date1 = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'); dates.date2 = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')


                break;
            case 'This Month':
                // setCustomFilters(false);
                setFieldValue('fromDate', moment().startOf('month').format('YYYY-MM-DD'));
                setFieldValue('toDate', moment().endOf('month').format('YYYY-MM-DD'));
                dates.date1 = moment().startOf('month').format('YYYY-MM-DD'); dates.date2 = moment().endOf('month').format('YYYY-MM-DD')


                break;
            case 'Last Month':
                // setCustomFilters(false);
                setFieldValue('fromDate', moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
                setFieldValue('toDate', moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
                dates.date1 = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'); dates.date2 = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')


                break;
            case 'This Quarter':
                // setCustomFilters(false);
                setFieldValue('fromDate', moment().quarter(moment().quarter()).startOf('quarter').format('YYYY-MM-DD'));
                setFieldValue('toDate', moment().quarter(moment().quarter()).endOf('quarter').format('YYYY-MM-DD'));
                dates.date1 = moment().quarter(moment().quarter()).startOf('quarter').format('YYYY-MM-DD'); dates.date2 = moment().quarter(moment().quarter()).endOf('quarter').format('YYYY-MM-DD')

                break;
            case 'Last Quarter':
                // setCustomFilters(false);
                setFieldValue('fromDate', moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'));
                setFieldValue('toDate', moment().subtract(1, 'quarter').endOf('quarter').endOf('month').format('YYYY-MM-DD'));
                dates.date1 = moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'); dates.date2 = moment().subtract(1, 'quarter').endOf('quarter').endOf('month').format('YYYY-MM-DD')

                break;
            case 'This Year':
                // setCustomFilters(false);
                setFieldValue('fromDate', moment().startOf('year').format('YYYY-MM-DD'));
                setFieldValue('toDate', moment().endOf('year').format('YYYY-MM-DD'));
                dates.date1 = moment().startOf('year').format('YYYY-MM-DD'); dates.date2 = moment().endOf('year').format('YYYY-MM-DD')

                break;
            case 'Last Year':
                // setCustomFilters(false);
                setFieldValue('fromDate', moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'));
                setFieldValue('toDate', moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'));
                dates.date1 = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'); dates.date2 = moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD')

                break;
            case 'Rolling 4 Weeks':
                // setCustomFilters(false);
                setFieldValue('fromDate', moment().subtract(4, 'week').startOf('week').format('YYYY-MM-DD'));
                setFieldValue('toDate', moment().format('YYYY-MM-DD'));
                dates.date1 = moment().subtract(4, 'week').startOf('week').format('YYYY-MM-DD'); dates.date2 = moment().format('YYYY-MM-DD')


                break;
            case 'Rolling 2 Months':
                // setCustomFilters(false);
                setFieldValue('fromDate', moment().subtract(2, 'month').startOf('week').format('YYYY-MM-DD'));
                setFieldValue('toDate', moment().format('YYYY-MM-DD'));
                dates.date1 = moment().subtract(2, 'month').startOf('week').format('YYYY-MM-DD'); dates.date2 = moment().format('YYYY-MM-DD')


                break;
            case 'Rolling 3 Months':
                // setCustomFilters(false);
                setFieldValue('fromDate', moment().subtract(3, 'month').startOf('week').format('YYYY-MM-DD'));
                setFieldValue('toDate', moment().format('YYYY-MM-DD'));
                dates.date1 = moment().subtract(3, 'month').startOf('week').format('YYYY-MM-DD'); dates.date2 = moment().format('YYYY-MM-DD')


                break;
            case 'Rolling 4 Months':
                // setCustomFilters(false);
                setFieldValue('fromDate', moment().subtract(4, 'month').startOf('week').format('YYYY-MM-DD'));
                setFieldValue('toDate', moment().format('YYYY-MM-DD'));
                dates.date1 = moment().subtract(4, 'month').startOf('week').format('YYYY-MM-DD'); dates.date2 = moment().format('YYYY-MM-DD')

                console.log(moment().subtract(4, 'month').startOf('week').format('YYYY-MM-DD'));

                break;
            case 'Rolling 6 Months':
                // setCustomFilters(false);
                setFieldValue('fromDate', moment().subtract(6, 'month').startOf('week').format('YYYY-MM-DD'));
                setFieldValue('toDate', moment().format('YYYY-MM-DD'));
                dates.date1 = moment().subtract(6, 'month').startOf('week').format('YYYY-MM-DD'); dates.date2 = moment().format('YYYY-MM-DD')

                console.log(moment().subtract(6, 'month').startOf('week').format('YYYY-MM-DD'));

                break;

            default:
                setFieldValue('fromDate', moment().format('YYYY-MM-DD'));
                setFieldValue('toDate', moment().format('YYYY-MM-DD'));
                dates.date1 = moment().format('YYYY-MM-DD'); dates.date2 = moment().format('YYYY-MM-DD')

                break;
        }

        getScoreLogGroups({
            fromDate: dates.date1, toDate: dates.date2, filterBy: 'created_at', sup_grp:
                loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
                    ? loggedUserData?.groups?.map((obj) => obj.id)
                    : ''
        })

        getScoreLogTeams({
            group: '',
            fromDate: dates.date1, toDate: dates.date2, filterBy: 'created_at', sup_grp:
                loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
                    ? loggedUserData?.groups?.map((obj) => obj.id)
                    : ''
        })
        getScoreLogScorecards({
            group: '', teams: '', scoreCard: '', eventType: '', eventTypeEventSubType: '', staff: "", scoreCardCategory: '', evaluator: '',
            fromDate: dates.date1, toDate: dates.date2, filterBy: 'created_at', sup_grp:
                loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
                    ? loggedUserData?.groups?.map((obj) => obj.id)
                    : ''
        })
        getScoreLogEventType({
            group: '', teams: '', scoreCard: '', eventType: '', eventTypeEventSubType: '', staff: "", scoreCardCategory: '', evaluator: '',
            fromDate: dates.date1, toDate: dates.date2, filterBy: 'created_at', sup_grp:
                loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
                    ? loggedUserData?.groups?.map((obj) => obj.id)
                    : ''
        })

        getScoreLogEventSubType({
            group: '', teams: '', scoreCard: '', eventType: '', eventTypeEventSubType: '', staff: "", scoreCardCategory: '', evaluator: '',
            fromDate: dates.date1, toDate: dates.date2, filterBy: 'created_at', sup_grp:
                loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
                    ? loggedUserData?.groups?.map((obj) => obj.id)
                    : ''
        })
    };



    const handleFilterByChange = (e) => {
        setFieldValue('filterBy', e.target.value)
    }

    const handleGroupChange = (event, newVal) => {
        // Resetting remaining fields
        setFieldValue('teams', '');
        setFieldValue('scoreCard', '');
        setFieldValue('eventType', '');
        setFieldValue('eventTypeEventSubType', '');
        setFieldValue('staff', '');
        setFieldValue('scoreCardCategory', '');
        setFieldValue('evaluator', '');
        setFieldValue('supervisor', '');
        setFieldValue('highlightValue', '');
        setFieldValue('section', '');
        setFieldValue('evaluator', '');
        setFieldValue('dataTag', []);

        setFieldValue('group', newVal?.id || '');
        // setDataTagByScoreCard([]);
        // setSelectedScGroupFilter(newVal?.id);
        const filterObj = {
            fromDate: values.fromDate,
            toDate: values.toDate,
            filterBy: values.filterBy,
            group: newVal?.id || "",
            sup_grp:
                loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
                    ? loggedUserData?.groups?.map((obj) => obj.id)
                    : ''
        };

        getScoreLogTeams(filterObj);

        getScoreLogScorecards({
            group: newVal?.id || '', teams: '', scoreCard: '', eventType: '', eventTypeEventSubType: '', staff: "", scoreCardCategory: '', evaluator: '',
            fromDate: values.fromDate, toDate: values.toDate, filterBy: 'created_at', sup_grp:
                loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
                    ? loggedUserData?.groups?.map((obj) => obj.id)
                    : ''
        })

        getScoreLogEventType({
            group: newVal?.id || '', teams: '', scoreCard: '', eventType: '', eventTypeEventSubType: '', staff: "", scoreCardCategory: '', evaluator: '',
            fromDate: values.fromDate, toDate: values.toDate, filterBy: 'created_at', sup_grp:
                loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
                    ? loggedUserData?.groups?.map((obj) => obj.id)
                    : ''
        })
        getScoreLogEventSubType({
            group: newVal?.id || '', teams: '', scoreCard: '', eventType: '', eventTypeEventSubType: '', staff: "", scoreCardCategory: '', evaluator: '',
            fromDate: values.fromDate, toDate: values.toDate, filterBy: 'created_at', sup_grp:
                loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
                    ? loggedUserData?.groups?.map((obj) => obj.id)
                    : ''
        })
    };

    const handleScorecardChange = (event, newValue) => {
        // Resetting remaining fields
        setFieldValue('eventType', '');
        setFieldValue('eventSubType', '');
        setFieldValue('staff', '');
        setFieldValue('scoreCardCategory', '');
        setFieldValue('evaluator', '');
        setFieldValue('supervisor', '');
        setFieldValue('highlightValue', '');
        setFieldValue('section', '');
        setFieldValue('evaluator', '');
        setFieldValue('scoreCard', newValue?.scoreCard || '');
        getScoreLogEventType({
            group: values.group, teams: values.teams, scoreCard: newValue?.scoreCard || '', eventType: '', eventTypeEventSubType: '', staff: "", scoreCardCategory: '', evaluator: '',
            fromDate: values.fromDate, toDate: values.toDate, filterBy: 'created_at', sup_grp:
                loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
                    ? loggedUserData?.groups?.map((obj) => obj.id)
                    : ''
        })
        getScoreLogEventSubType({
            group: values.group, teams: values.teams, scoreCard: newValue?.scoreCard || '', eventType: '', eventTypeEventSubType: '', staff: "", scoreCardCategory: '', evaluator: '',
            fromDate: values.fromDate, toDate: values.toDate, filterBy: 'created_at', sup_grp:
                loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
                    ? loggedUserData?.groups?.map((obj) => obj.id)
                    : ''
        })
        // setselectedScFilter(newValue?.scoreCard);

        // setDataTagByScoreCard([]);


    };

    const handleEventTypeChange = (event) => {
        // Resetting remaining fields
        setFieldValue('eventSubType', '');
        setFieldValue('staff', '');
        setFieldValue('scoreCardCategory', '');
        setFieldValue('evaluator', '');
        setFieldValue('supervisor', '');
        setFieldValue('highlightValue', '');
        setFieldValue('section', '');
        setFieldValue('evaluator', '');
        setFieldValue('eventType', event.target.value);
        getScoreLogEventSubType({
            group: '', teams: '', scoreCard: '', eventType: event.target.value, eventTypeEventSubType: '', staff: "", scoreCardCategory: '', evaluator: '',
            fromDate: values.fromDate, toDate: moment().format('YYYY-MM-DD'), filterBy: 'created_at', sup_grp:
                loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
                    ? loggedUserData?.groups?.map((obj) => obj.id)
                    : ''
        })
    };


    const getModuleDates = (id) => {
        let date = '2022-03-01';
        if (id === 4.7) {
            date = moment().subtract(1, 'year').format('YYYY-MM-DD')

        } else {
            date = moment().subtract(4, 'week').startOf('week').format('YYYY-MM-DD')
        }

        return date;
    }

    const fetchFilterValues = (sectionId) => {
        formik.setTouched({}, false); formik.setErrors({}, false);
        setFieldValue('fromDate', filterValue?.from_date ? moment(filterValue?.from_date).format('YYYY-MM-DD') : getModuleDates(sectionId));
        setFieldValue('toDate', filterValue?.to_date ? moment(filterValue?.to_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'));
        setFieldValue('dateRange', filterValue?.date_range_type || 'Rolling 4 Weeks');
        setFieldValue('filterBy', filterValue?.filter_by || 'created_at');
        setFieldValue('teams', filterValue?.teams || '');
        formik.setFieldValue("group", Number(filterValue?.score_card__groups) || "");
        formik.setFieldValue('scoreCard', Number(filterValue?.score_card) || "");
        setFieldValue('eventType', filterValue?.event_type || '');
        setFieldValue('eventSubType', filterValue?.event_sub_type || "");
        setFieldValue('formula', filterValue?.formula || 10);
        setFieldValue('interval', Number(filterValue?.date_type) || Number(10));
        setFieldValue('interval', Number(filterValue?.date_type) || Number(10));
        setFieldValue('dataLimit', Number(filterValue?.data_limit) || Number(10));

        getScoreLogGroups({
            fromDate: filterValue?.from_date ? moment(filterValue?.from_date).format('YYYY-MM-DD') : getModuleDates(sectionId), toDate: filterValue?.to_date ? moment(filterValue?.to_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'), filterBy: filterValue?.filter_by || 'created_at', sup_grp:
                loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
                    ? loggedUserData?.groups?.map((obj) => obj.id)
                    : ''
        })
        getScoreLogTeams({
            group: filterValue?.score_card__groups || '',
            fromDate: filterValue?.from_date ? moment(filterValue?.from_date).format('YYYY-MM-DD') : getModuleDates(sectionId), toDate: filterValue?.to_date ? moment(filterValue?.to_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'), filterBy: filterValue?.filter_by || 'created_at', sup_grp:
                loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
                    ? loggedUserData?.groups?.map((obj) => obj.id)
                    : ''
        })
        getScoreLogScorecards({
            group: filterValue?.score_card__groups || '', teams: filterValue?.teams || '', scoreCard: '', eventType: '', eventTypeEventSubType: '', staff: "", scoreCardCategory: '', evaluator: '',
            fromDate: filterValue?.from_date ? moment(filterValue?.from_date).format('YYYY-MM-DD') : getModuleDates(sectionId), toDate: filterValue?.to_date ? moment(filterValue?.to_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'), filterBy: filterValue?.filter_by || 'created_at', sup_grp:
                loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
                    ? loggedUserData?.groups?.map((obj) => obj.id)
                    : ''
        })
        getScoreLogEventType({
            group: filterValue?.score_card__groups || '', teams: filterValue?.teams || '', scoreCard: filterValue?.score_card || '', eventType: '', eventTypeEventSubType: '', staff: "", scoreCardCategory: '', evaluator: '',
            fromDate: filterValue?.from_date ? moment(filterValue?.from_date).format('YYYY-MM-DD') : getModuleDates(sectionId), toDate: filterValue?.to_date ? moment(filterValue?.to_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'), filterBy: filterValue?.filter_by || 'created_at', sup_grp:
                loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
                    ? loggedUserData?.groups?.map((obj) => obj.id)
                    : ''
        })

        getScoreLogEventSubType({
            group: filterValue?.score_card__groups || '', teams: filterValue?.teams || '', scoreCard: filterValue?.score_card || '', eventType: filterValue?.event_type || '', eventTypeEventSubType: '', staff: "", scoreCardCategory: '', evaluator: '',
            fromDate: filterValue?.from_date ? moment(filterValue?.from_date).format('YYYY-MM-DD') : getModuleDates(sectionId), toDate: filterValue?.to_date ? moment(filterValue?.to_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'), filterBy: 'created_at', sup_grp:
                loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
                    ? loggedUserData?.groups?.map((obj) => obj.id)
                    : ''
        })



    }

    const handleGlobalButton = () => {
        setDialogVisible(true);
        handleClickOpen();
    }

    return (
        <>
            {(addUserButton && isReportSetting) ?
                <Tooltip title={text}>
                    <IconButton

                        sx={{ mx: 1 }}
                        onClick={() => {
                            setDialogVisible(true);
                            handleClickOpen();
                        }}>

                        <SettingsOutlined /> :


                    </IconButton>
                </Tooltip> : <GlobalButton sx={{ mx: 1 }} label='Add Report' startIcon={<AddIcon sx={{
                    // color: '#636e72',
                    stroke: (theme) => theme.palette.background.paper, strokeWidth: '1px'
                }} />} fn={handleGlobalButton} />
            }

            {/* {(addUserButton && isEmptyCustom) &&
                <GlobalButton sx={{ mx: 1 }} label='Add Report' startIcon={<AddIcon sx={{
                    // color: '#636e72',
                    stroke: (theme) => theme.palette.background.paper, strokeWidth: '1px'
                }} />} fn={handleGlobalButton} />

            } */}
            <Dialog open={open} maxWidth='sm' fullWidth>
                <DialogTitle sx={{ px: 2, py: 1 }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="h5">{titleText}</Typography>
                        <IconButton onClick={() => handleClose()}>
                            <Cancel />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel shrink id="filter-by-label">Report</InputLabel>
                        <Select
                            disabled={titleText === 'Report Setting'}
                            input={<OutlinedInput notched label="Report" />}
                            labelId="filter-by-label" displayEmpty value={reportFieldValue} fullWidth>
                            <MenuItem onClick={() => { setReportFieldValue(""); setRadioBtnValue() }} value="">--Select Report--</MenuItem>
                            <ListSubheader>Staff</ListSubheader>
                            <MenuItem onClick={() => { setReportFieldValue(1.2); setRadioBtnValue(20); fetchFilterValues() }} value={1.2}>1.2&nbsp;-&nbsp;Question By Staff</MenuItem>
                            <ListSubheader>Evaluations</ListSubheader>
                            <MenuItem onClick={() => { setReportFieldValue(3.2); setRadioBtnValue(30); fetchFilterValues() }} value={3.2}>3.2&nbsp;-&nbsp;Evaluation per period</MenuItem>
                            <ListSubheader>Scorecards</ListSubheader>
                            <MenuItem onClick={() => { setReportFieldValue(4.7); setRadioBtnValue(40); fetchFilterValues(4.7) }} value={4.7}>4.7&nbsp;-&nbsp;Causes Snapshot</MenuItem>
                            <ListSubheader>Teams</ListSubheader>
                            <MenuItem onClick={() => { setReportFieldValue(5.1); setRadioBtnValue(30); fetchFilterValues() }} value={5.1}>5.1&nbsp;-&nbsp;OverAll Team Trend</MenuItem>
                            <ListSubheader>Groups</ListSubheader>
                            <MenuItem onClick={() => { setReportFieldValue(6.1); setRadioBtnValue(30); fetchFilterValues() }} value={6.1}>6.1&nbsp;-&nbsp;Summary</MenuItem>
                            <MenuItem onClick={() => { setReportFieldValue(6.3); setRadioBtnValue(20); fetchFilterValues() }} value={6.3}>6.3&nbsp;-&nbsp;Points of Failures</MenuItem>
                            <ListSubheader>Custom</ListSubheader>
                            <MenuItem onClick={() => { setReportFieldValue(7.1); setRadioBtnValue(50); fetchFilterValues() }} value={7.1}>7.1&nbsp;-&nbsp;Agent Engagement</MenuItem>
                        </Select>
                    </FormControl>
                    {
                        reportFieldValue !== '' && <>
                            <FormControl sx={{ my: 2, pl: 1 }}>
                                <FormLabel id="demo-form-control-label-placement" sx={{ fontSize: "14px" }}>Report Type</FormLabel>

                                <RadioGroup
                                    row
                                    aria-labelledby="demo-form-control-label-placement"
                                    name="position"
                                    onChange={(e) => setRadioBtnValue(Number(e.target.value))}
                                    value={radioBtnValue}
                                >
                                    {(reportFieldValue !== 4.7 && reportFieldValue !== 7.1) && <FormControlLabel value={10} control={<Radio size="small" />} label={<Stack direction="row" alignItems="center">
                                        <TableRowsOutlined fontSize='small' />&nbsp; <Typography variant="caption">Table</Typography>
                                    </Stack>} />}
                                    {(reportFieldValue === 1.2 || reportFieldValue === 6.3) && <FormControlLabel value={20} control={<Radio size="small" />} label={<Stack direction="row" alignItems="center">
                                        <EqualizerOutlined fontSize='small' />&nbsp; <Typography variant="caption">Column Chart</Typography>
                                    </Stack>} />}
                                    {(reportFieldValue === 3.2 || reportFieldValue === 5.1 || reportFieldValue === 6.1) && <FormControlLabel value={30} control={<Radio size="small" />} label={<Stack direction="row" alignItems="center">
                                        <TimelineOutlined fontSize='small' />&nbsp; <Typography variant="caption">Line Chart</Typography>
                                    </Stack>} />}
                                    {reportFieldValue === 4.7 && <FormControlLabel value={40} control={<Radio size="small" />} label={<Stack direction="row" alignItems="center">
                                        <EqualizerOutlined fontSize='small' />&nbsp; <Typography variant="caption">Bar Chart</Typography>
                                    </Stack>} />}

                                    {reportFieldValue === 7.1 && <FormControlLabel value={50} control={<Radio size="small" />} label={<Stack direction="row" alignItems="center">
                                        <PieChartOutline fontSize='small' />&nbsp; <Typography variant="caption">Pie Chart</Typography>
                                    </Stack>} />}
                                </RadioGroup>
                            </FormControl>
                            <Stack direction={{ xs: 'column', sm: 'row', mt: 2 }} spacing={2}>
                                {reportFieldValue !== 4.7 && <FormControl sx={{ width: "50%" }} fullWidth>
                                    <InputLabel id="date-range-label">Date Range</InputLabel>
                                    <Select
                                        // defaultValue='This Month'
                                        labelId="date-range-label"
                                        id="demo-select-small"
                                        //   value={values.dateRange}
                                        value={formik.values.dateRange}
                                        name="dateRange"
                                        label="Date Range"
                                        {...getFieldProps('dateRange')}
                                        onChange={(e) => {
                                            handleDateRangeChange(e);
                                        }}
                                    >
                                        {customDateRangeList?.map((item) => (
                                            <MenuItem key={item.id} id={item.id} value={item.dateRange}>
                                                {item.dateRange}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>}

                                {reportFieldValue === 7.1 && <FormControl sx={{ width: "50%" }} fullWidth>
                                    <Autocomplete

                                        value={[{
                                            name: '--Select Group--', id: ''
                                        }].concat(reportsScoreLogGroups)?.find((option) => option?.id === values.group)}
                                        fullWidth
                                        options={[{
                                            name: '--Select Group--', id: ''
                                        }].concat(reportsScoreLogGroups)}
                                        getOptionLabel={(option) => option?.name || ""}
                                        onChange={(event, newValue) => {
                                            handleGroupChange(event, newValue);
                                            console.log(newValue);
                                        }}
                                        id="controllable-states-demo"

                                        renderInput={(params) => <TextField error={(touched.group && errors.group)} {...params} label="Group"
                                            popoverProps={{
                                                style: {
                                                    bottom: 0,
                                                    overflowY: 'auto'
                                                }
                                            }}
                                            variant="outlined"
                                            required
                                        />}
                                    />
                                    {errors.group && touched.group ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', p: 1 }}>This field is required</Typography> : null}
                                </FormControl>}

                                {(reportFieldValue !== 4.7 && reportFieldValue !== 7.1) && <FormControl sx={{ width: "50%" }} fullWidth>
                                    <InputLabel id="filter-by-label">Filter By</InputLabel>
                                    <Select
                                        labelId="filter-by-label"
                                        id="demo-select-small"
                                        value={values.filterBy}
                                        label="Filter By"
                                        name="filterBy"
                                        {...getFieldProps('filterBy')}
                                        onChange={handleFilterByChange}
                                    >
                                        {filterByList?.map((item) => (
                                            <MenuItem key={item.id} id={item.id} value={item.id}>
                                                {item.type}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>}
                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ my: 2 }} spacing={2}>

                                {(reportFieldValue === 3.2 || reportFieldValue === 5.1 || reportFieldValue === 6.1) && <FormControl sx={{ width: "50%" }} fullWidth>
                                    <InputLabel shrink id="teams-label">
                                        Interval
                                    </InputLabel>
                                    <Select
                                        // defaultValue='All'
                                        labelId="teams-label"
                                        id="demo-select-small"
                                        displayEmpty
                                        value={values.interval}
                                        // label="Teams"
                                        input={<OutlinedInput notched label="Interval" />}
                                        {...getFieldProps('interval')}
                                    // onChange={handleTeamChange}
                                    >
                                        <MenuItem value={10}> Week </MenuItem>
                                        <MenuItem value={20}> Month </MenuItem>
                                        <MenuItem value={30}> Quarter </MenuItem>

                                    </Select>
                                </FormControl>}

                                {reportFieldValue === 4.7 && <FormControl sx={{ width: "50%" }} fullWidth>
                                    <InputLabel shrink id="teams-label">
                                        Data Limit
                                    </InputLabel>
                                    <Select
                                        // defaultValue='All'
                                        labelId="teams-label"
                                        id="demo-select-small"
                                        displayEmpty
                                        value={values.dataLimit}
                                        // label="Teams"
                                        input={<OutlinedInput notched label="Interval" />}
                                        {...getFieldProps('dataLimit')}
                                    // onChange={handleTeamChange}
                                    >
                                        <MenuItem value={10}> Last 50 Results</MenuItem>
                                        <MenuItem value={20}> Last 100 Results</MenuItem>

                                    </Select>
                                </FormControl>}

                                {reportFieldValue !== 7.1 && <FormControl sx={{ width: "50%" }} fullWidth>
                                    <Autocomplete
                                        value={[{
                                            name: reportFieldValue === 6.1 || reportFieldValue === 6.3 ? 'All' : '--Select Group--', id: ''
                                        }].concat(reportsScoreLogGroups)?.find((option) => option?.id === formik.values.group)}

                                        fullWidth
                                        options={[{
                                            name: reportFieldValue === 6.1 || reportFieldValue === 6.3 ? 'All' : '--Select Group--', id: ''
                                        }].concat(reportsScoreLogGroups)}
                                        getOptionLabel={(option) => option?.name || ""}
                                        onChange={(event, newValue) => {
                                            handleGroupChange(event, newValue);
                                            console.log(newValue);
                                        }}
                                        id="controllable-states-demo"

                                        renderInput={(params) => <TextField error={touched.group && errors.group} {...params} label="Group"
                                            popoverProps={{
                                                style: {
                                                    bottom: 0,
                                                    overflowY: 'auto'
                                                }
                                            }}
                                            variant="outlined"
                                            required
                                        />}
                                    />
                                    {errors.group && touched.group ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', p: 1 }}>This field is required</Typography> : null}
                                </FormControl>}
                                {(reportFieldValue === 6.3) && <FormControl sx={{ width: "50%" }} fullWidth>
                                    <InputLabel shrink id="group-label">
                                        Event Type
                                    </InputLabel>
                                    <Select
                                        // defaultValue='All'
                                        labelId="group-label"
                                        id="demo-select-small"
                                        displayEmpty
                                        // value={values.type}
                                        // label="Event Type"
                                        input={<OutlinedInput notched label="Event Type" />}
                                        // onChange={handleTypeChange}
                                        {...getFieldProps('eventType')}
                                        onChange={handleEventTypeChange}
                                    >
                                        <MenuItem value=""> All </MenuItem>
                                        {reportsScoreLogEventType?.map((item) => (

                                            <MenuItem key={item.eventType} id={item.eventType} value={item.eventType}>
                                                <Typography sx={{ fontSize: '14px', display: 'inline' }}>{item.eventTypeEventType}</Typography>&nbsp;<Typography sx={{ display: 'inline-block', fontStyle: 'italic', fontSize: '12px', fontWeight: 'bold' }}>{`(${item?.scorecard})`}</Typography>
                                            </MenuItem>

                                        ))}
                                    </Select>
                                </FormControl>}

                                {((reportFieldValue !== 4.7 && reportFieldValue !== 5.1 && reportFieldValue !== 6.3 && reportFieldValue !== 6.1 && reportFieldValue !== 7.1) && reportFieldValue !== 3.2) && <FormControl sx={{ width: "50%" }} fullWidth>
                                    <InputLabel shrink id="teams-label">
                                        Teams
                                    </InputLabel>
                                    <Select
                                        // defaultValue='All'
                                        labelId="teams-label"
                                        id="demo-select-small"
                                        displayEmpty
                                        value={values.teams}
                                        // label="Teams"
                                        input={<OutlinedInput notched label="Teams" />}
                                        {...getFieldProps('teams')}
                                    // onChange={handleTeamChange}
                                    >
                                        <MenuItem value=""> All </MenuItem>
                                        {reportsScoreLogTeams?.map((item) => (
                                            <MenuItem key={item.id} id={item.id} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>}
                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ my: 2 }} spacing={2}>
                                {(reportFieldValue !== 5.1 && reportFieldValue !== 6.3 && reportFieldValue !== 7.1) && <FormControl sx={{ width: "50%" }} fullWidth>
                                    <Autocomplete
                                        value={[{
                                            scoreCardName: reportFieldValue === 6.1 ? 'All' : '--Please Select--', scoreCard: ''
                                        }].concat(reportsScoreLogScorecards)?.find((option) => option?.scoreCard === formik.values.scoreCard)}
                                        fullWidth
                                        options={[{
                                            scoreCardName: reportFieldValue === 6.1 ? 'All' : '--Please Select--', scoreCard: ''
                                        }].concat(reportsScoreLogScorecards)}
                                        getOptionLabel={(option) => option?.scoreCardName || ""}
                                        onChange={(event, newValue) => {
                                            handleScorecardChange(event, newValue);
                                        }}
                                        id="controllable-states-demo"

                                        renderInput={(params) => <TextField error={(touched.scoreCard && errors.scoreCard)} {...params} label="Scorecard"
                                            popoverProps={{
                                                style: {
                                                    bottom: 0,
                                                    overflowY: 'auto'
                                                }
                                            }}
                                            variant="outlined"
                                            required
                                        />}
                                    />
                                    {errors.scoreCard && touched.scoreCard ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', p: 1 }}>This field is required</Typography> : null}
                                </FormControl>}
                                {(reportFieldValue !== 4.7 && reportFieldValue !== 6.3 && reportFieldValue !== 7.1) && <FormControl sx={{ width: "50%" }} fullWidth>
                                    <InputLabel shrink id="group-label">
                                        Event Type
                                    </InputLabel>
                                    <Select
                                        // defaultValue='All'
                                        labelId="group-label"
                                        id="demo-select-small"
                                        displayEmpty
                                        // value={values.type}
                                        // label="Event Type"
                                        input={<OutlinedInput notched label="Event Type" />}
                                        // onChange={handleTypeChange}
                                        {...getFieldProps('eventType')}
                                        onChange={handleEventTypeChange}
                                    >
                                        <MenuItem value=""> All </MenuItem>
                                        {reportsScoreLogEventType?.map((item) => (
                                            <MenuItem key={item.eventType} id={item.eventType} value={item.eventType}>
                                                <Typography sx={{ fontSize: '14px', display: 'inline' }}>{item.eventTypeEventType}</Typography>&nbsp;<Typography sx={{ display: 'inline-block', fontStyle: 'italic', fontSize: '12px', fontWeight: 'bold' }}>{`(${item?.scorecard})`}</Typography>

                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>}
                                {(reportFieldValue === 5.1) && <FormControl sx={{ width: "50%" }} fullWidth>
                                    <InputLabel shrink id="teams-label">
                                        Event Sub Type
                                    </InputLabel>
                                    <Select
                                        labelId="teams-label"
                                        id="demo-select-small"
                                        displayEmpty
                                        input={<OutlinedInput notched label="Event Sub Type" />}
                                        // onChange={handleTypeChange}
                                        {...getFieldProps('eventSubType')}
                                    // onChange={handleSubTypeChange}
                                    >
                                        <MenuItem value=""> All </MenuItem>
                                        {reportsScoreLogEventSubType?.map(
                                            (item, index) =>


                                                <MenuItem key={index} id={item.eventSubType} value={item.eventSubType}>
                                                    {/* <Tooltip placement="left-end" title={`Scorecard: ${item?.scorecard}`} /> */}
                                                    <Typography sx={{ fontSize: '14px', display: 'inline' }}>{item.eventTypeEventSubType}</Typography>&nbsp;<Typography sx={{ display: 'inline-block', fontStyle: 'italic', fontSize: '12px', fontWeight: 'bold' }}>{`(${item?.scorecard})`}</Typography>
                                                </MenuItem>


                                        )}
                                    </Select>
                                </FormControl>}

                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ my: 2 }} spacing={2}>
                                {(reportFieldValue !== 4.7 && reportFieldValue !== 5.1 && reportFieldValue !== 7.1) && <FormControl sx={{ width: "50%" }} fullWidth>
                                    <InputLabel shrink id="teams-label">
                                        Event Sub Type
                                    </InputLabel>
                                    <Select
                                        labelId="teams-label"
                                        id="demo-select-small"
                                        displayEmpty
                                        input={<OutlinedInput notched label="Event Sub Type" />}
                                        // onChange={handleTypeChange}
                                        {...getFieldProps('eventSubType')}
                                    // onChange={handleSubTypeChange}
                                    >
                                        <MenuItem value=""> All </MenuItem>
                                        {reportsScoreLogEventSubType?.map(
                                            (item, index) => <MenuItem key={index} id={item.eventSubType} value={item.eventSubType}>
                                                {item.eventTypeEventSubType}
                                            </MenuItem>

                                        )}
                                    </Select>
                                </FormControl>}
                                {((reportFieldValue !== 3.2 && reportFieldValue !== 5.1 && reportFieldValue !== 6.1 && reportFieldValue !== 6.3 && reportFieldValue !== 7.1) && reportFieldValue !== 4.7) && <FormControl sx={{ width: "50%" }} fullWidth>
                                    <InputLabel shrink id="formula-label">
                                        Formula
                                    </InputLabel>
                                    <Select
                                        labelId="formula-label"
                                        id="demo-select-small"
                                        displayEmpty
                                        value={formik.values.formula}
                                        input={<OutlinedInput notched label="Formula" />}
                                        // onChange={handleTypeChange}
                                        {...getFieldProps('formula')}
                                    // onChange={handleSubTypeChange}
                                    >
                                        <MenuItem value={10}>True</MenuItem>
                                        <MenuItem value={20}>Potential</MenuItem>

                                    </Select>
                                </FormControl>}
                            </Stack>

                        </>
                    }
                    <Stack direction="row" sx={{ p: 2, mt: 2, mb: 1 }} alignItems="center" justifyContent="flex-end">
                        <Button onClick={() => { handleClose() }} variant="outlined" sx={{ mr: 1 }}>Cancel</Button>
                        <Button disabled={customLoader} onClick={() => formik.handleSubmit()} variant="contained">{titleText === 'Report Setting' ? 'Edit Report' : 'Add Report'}
                            {customLoader && <CircularProgress size={14} sx={{ ml: 1 }} />}</Button>
                    </Stack>
                </DialogContent>
            </Dialog >
        </>
    )
}