import React from "react";
import "../assets/Sparkle.css";

const BackgroundAnimation = () => {
  return (
    <div id="background">
      {Array.from({ length: 28 }).map((_, index) => (
        <div key={index}>
          <span />
        </div>
      ))}
    </div>
  );
};

export default BackgroundAnimation;
