import React, { useState } from 'react';

// MATERIAL UI IMPORT
import { Box, Stack, Menu, MenuItem, Typography, IconButton, Tooltip, useTheme } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";

// APEX IMPORT
import Chart from 'react-apexcharts';



// OTHER IMPORTS
import moment from 'moment';
import { useResizeDetector } from 'react-resize-detector';
import { useNavigate } from 'react-router-dom';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ReactToPrint from 'react-to-print';
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';

// CONTEXT IMPORTS
import { AnalyticContext } from "../../../contexts/AnalyticContext";
import { CollapseDrawerContext } from "../../../contexts/CollapseDrawerContext";
import { SettingsContext } from "../../../contexts/SettingsContext";
import { ReactBarChart, ReactLineChart, ReactPieDounoughtChart } from '../../Common Component/ReactChart';

export default function EvaluationPerPeriodGraph({ customGraphData, hght, customGraphCategoryData, wdth, module }) {

    // STATES
    const handle = useFullScreenHandle();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isTitle, setIsTitle] = React.useState(false);
    const [scoreCardId, setScoreCardId] = React.useState();

    const theme = useTheme();
    const GRAPH_COLOR = theme.palette.graphColors.graphColor;


    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // CONTEXT STATES
    const {

        perPeriodGraphData,
        perPeriodGraphCategory,
        perPeriodData,
        perPeriodDateType,
        setPageAccpAgnt, filterNameAcceptanceByAgnt,
        selAnaEvaluationFilters,
        setFilterNameAcceptanceByAgnt, setAgentData, setLoadingAgent, setSubModLabel } = React.useContext(AnalyticContext);
    const { collapseClick, collapseHover } = React.useContext(CollapseDrawerContext);
    const { setColor } = React.useContext(SettingsContext);


    React.useEffect(() => {
        setScoreCardId(selAnaEvaluationFilters?.scoreCard)
    }, [perPeriodData])

    // REACT ROUTER STATES
    const navigate = useNavigate();

    const componentRef = React.useRef();
    const componentRef1 = React.useRef();

    // RESIZE STATES
    const { width, height, ref } = useResizeDetector();
    const [graphWidth, setGraphWidth] = React.useState(0);
    const [graphHeight, setGraphHeight] = React.useState(600);
    const [seriesData, setSeriesData] = React.useState([]);
    const [categoryData, setCategoryData] = React.useState([]);
    const [searchParams, setSearchParams] = React.useState()

    React.useEffect(() => {
        let x = 0;
        if (collapseClick) { x = 150 } else { x = 80 };

        if (componentRef.current) {


            if (collapseHover === true) {
                setGraphWidth((componentRef.current.offsetWidth + 103) - x);
            } else {
                setGraphWidth(componentRef.current.offsetWidth - x);
            }

        } else {
            setGraphWidth(0)
        }
        if (hght) {
            setGraphHeight(hght);
        }
        if (wdth) {
            setGraphWidth(wdth)
        }
    }, [componentRef, collapseClick, hght, wdth])

    React.useEffect(() => {
        if (perPeriodGraphData && !customGraphData) {
            setSeriesData(perPeriodGraphData);
        }
        if (customGraphData) {
            // console.log(module, customGraphData);
            setSeriesData(customGraphData);
        }
    }, [perPeriodGraphData, customGraphData])

    React.useEffect(() => {
        if (perPeriodGraphCategory && !customGraphData) {
            setCategoryData(perPeriodGraphCategory);
        }
        if (customGraphCategoryData) {
            setCategoryData(customGraphCategoryData);
        }
    }, [perPeriodGraphCategory, customGraphCategoryData])

    const optionBar = {
        series: seriesData?.length > 0 ? seriesData : [],
        options: {
            // colors: ["#3CB8E1", "#F5CB4E", "#958CE5"],
            colors: GRAPH_COLOR,

            chart: {
                height: 550,
                type: 'bar',
                zoom: {
                    enabled: false
                },
                events: {
                    dataPointSelection: (event, chartContext, config) => {

                        const dates = { date1: '', date2: '' }
                        if (perPeriodDateType === 'week') {
                            dates.date1 = moment(`${perPeriodData?.header[config.dataPointIndex]?.date_num}`, 'W').startOf('week').year(moment(selAnaEvaluationFilters?.fromDate).format('YYYY')).add(1, 'days').format('YYYY-MM-DD')
                            dates.date2 = moment(`${perPeriodData?.header[config.dataPointIndex]?.date_num}`, 'W').endOf('week').year(moment(selAnaEvaluationFilters?.toDate).format('YYYY')).add(1, 'days').format('YYYY-MM-DD')
                        }
                        if (perPeriodDateType === 'month') {
                            dates.date1 = moment(`${perPeriodData?.header[config.dataPointIndex]?.date_num}`, 'M').startOf('month').year(moment(selAnaEvaluationFilters?.fromDate).format('YYYY')).format('YYYY-MM-DD')
                            dates.date2 = moment(`${perPeriodData?.header[config.dataPointIndex]?.date_num}`, 'M').endOf('month').year(moment(selAnaEvaluationFilters?.toDate).format('YYYY')).format('YYYY-MM-DD')
                        }
                        if (perPeriodDateType === 'quarter') {
                            dates.date1 = moment(`${perPeriodData?.header[config.dataPointIndex]?.date_num}`, 'Q').startOf('quarter').year(moment(selAnaEvaluationFilters?.fromDate).format('YYYY')).format('YYYY-MM-DD')
                            dates.date2 = moment(`${perPeriodData?.header[config.dataPointIndex]?.date_num}`, 'Q').endOf('quarter').year(moment(selAnaEvaluationFilters?.toDate).format('YYYY')).format('YYYY-MM-DD')
                        }


                        if (selAnaEvaluationFilters?.dateRange === 'Custom Date Range') {

                            if (!customGraphData) navigate(`/analytics/evaluation/reportResultDetails?from_date=${dates?.date1}&to_date=${dates?.date2}&filterBy=${selAnaEvaluationFilters?.filterByRange}&date_type_key=${`${selAnaEvaluationFilters?.filterByRange}__${perPeriodDateType}`}&date_type_value=${perPeriodData?.data[config.seriesIndex][config.dataPointIndex]?.[`${selAnaEvaluationFilters?.filterByRange}__${perPeriodDateType}`]}&created_at_key=${`${selAnaEvaluationFilters?.filterByRange}__year`}&created_at_value=${perPeriodData?.data[config.seriesIndex][config.dataPointIndex]?.year}&score_Card_Id=${scoreCardId}&event_type=${selAnaEvaluationFilters?.eventType}&sub_type=${selAnaEvaluationFilters?.eventTypeEventSubType}&group=${selAnaEvaluationFilters?.group}&teams=${selAnaEvaluationFilters?.teams}&evaluator=${perPeriodData?.score_card[config.seriesIndex]?.evaluator}&dateType=${perPeriodDateType}&dateRange=${selAnaEvaluationFilters?.dateRange || searchParams.get('dateRange') || ''}&module=${3}&sub_module=${2}`)
                        }
                        if (selAnaEvaluationFilters?.dateRange !== 'Custom Date Range') {

                            if (!customGraphData) navigate(`/analytics/evaluation/reportResultDetails?from_date=${selAnaEvaluationFilters?.fromDate}&to_date=${selAnaEvaluationFilters?.toDate}&filterBy=${selAnaEvaluationFilters?.filterByRange}&score_Card_Id=${scoreCardId}&event_type=${selAnaEvaluationFilters?.eventType}&sub_type=${selAnaEvaluationFilters?.eventTypeEventSubType}&date_type_key=${`${selAnaEvaluationFilters?.filterByRange}__${perPeriodDateType}`}&date_type_value=${perPeriodData?.data[config.seriesIndex][config.dataPointIndex]?.[`${selAnaEvaluationFilters?.filterByRange}__${perPeriodDateType}`]}&created_at_key=${`${selAnaEvaluationFilters?.filterByRange}__year`}&created_at_value=${perPeriodData?.data[config.seriesIndex][config.dataPointIndex]?.year}&group=${selAnaEvaluationFilters?.group}&teams=${selAnaEvaluationFilters?.teams}&evaluator=${perPeriodData?.score_card[config.seriesIndex]?.evaluator}&dateType=${perPeriodDateType}&dateRange=${selAnaEvaluationFilters?.dateRange || searchParams.get('dateRange') || ''}&module=${3}&sub_module=${2}`)
                        }

                    },
                    legendClick: (chartContext, seriesIndex, config) => {
                        console.log(chartContext, seriesIndex, config)
                        if (customGraphData && module === '4.7') {
                            const seriesArr = config?.globals?.series[0];
                            if (seriesArr[seriesIndex] !== null) {
                                const tempArr = seriesArr?.map((row, index) => { if (index === seriesIndex) { return null } return row })
                                return setSeriesData([{ name: 'Count', data: tempArr }])
                            }
                            if (seriesArr[seriesIndex] === null) {
                                const tempArr = seriesArr;
                                tempArr[seriesIndex] = customGraphData[0]?.data[seriesIndex];
                                console.log(tempArr);
                                setSeriesData([{ name: 'Count', data: tempArr }])
                            }

                        }

                    }
                },
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: module === '4.7',
                    distributed: module === '4.7',
                }
            },
            dataLabels: {
                enabled: false
            },

            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // take an array which will be repeated on columns
                    opacity: 0.5
                },
            },

            xaxis: {
                categories: categoryData?.length > 0 ? categoryData : [],
                axisBorder: {
                    show: true,
                    color: "#535c68",
                },
            },
            yaxis: {
                floating: false,
                axisBorder: {
                    show: true,
                    color: "#535c68",
                },
                labels: {
                    show: true,
                    align: 'right',
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                        colors: "#000",
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-label',
                    },
                    offsetX: 0,
                    offsetY: 0,
                    rotate: 0,
                },

                title: {
                    text: "Count",
                    offsetX: -2,

                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-title',
                    },
                },
            },
            noData: {
                text: "No data found for the data range selected.",
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    // fontSize: {lg:'18px',md:'18px',sm:'18px',xs:"10px"},
                    fontSize: '18px',
                    fontWeight: "bold"
                }
            },

            legend: { showForSingleSeries: true, fontSize: '12px', position: "bottom", height: 56, },
            // markers: {
            //     size: 5,
            //     strokeColors: '#fff',
            //     strokeWidth: 2,
            //     strokeOpacity: 0.9,
            //     strokeDashArray: 0,
            //     fillOpacity: 0,
            //     discrete: [{
            //         seriesIndex: 0,
            //         dataPointIndex: 2,
            //         fillColor: setColor?.main,
            //         strokeColor: '#fff',
            //         size: 5
            //     }],
            //     shape: "circle",
            //     radius: 4,
            //     offsetX: 0,
            //     offsetY: 0
            // },
            title: {
                text: customGraphData ? '' : `3.2 Evaluations Per Period`,
                align: 'middle',
                margin: customGraphData ? 0 : 70,
                offsetX: 0,
                offsetY: customGraphData ? 0 : -30,
                floating: false,
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: 'black',
                },
            },
            responsive: [
                {
                    breakpoint: 425,
                    options: {
                        noData: {
                            text: 'No data found...',
                            align: 'center',
                            verticalAlign: 'middle',
                            offsetX: 20,
                            offsetY: 0,
                            style: {
                                // fontSize: {lg:'18px',md:'18px',sm:'18px',xs:"10px"},
                                fontSize: '10px',
                                fontWeight: "bold"
                            }
                        },
                    },
                },
                {
                    breakpoint: 1024,
                    options: {
                        noData: {
                            text: 'No data found for the data Range Selected.',
                            align: 'center',
                            verticalAlign: 'middle',
                            offsetX: 20,
                            offsetY: 0,
                            style: {
                                // fontSize: {lg:'18px',md:'18px',sm:'18px',xs:"10px"},
                                fontSize: '12px',
                                fontWeight: "bold"
                            }
                        },
                    },
                }
            ],
        },

    }

    const optionLine = {
        series: seriesData?.length > 0 ? seriesData : [],
        options: {
            // colors: ["#3CB8E1", "#F5CB4E", "#958CE5"],
            colors: GRAPH_COLOR,

            // colors: [setColor?.main || "#05164d", "#e67e22", "#8e44ad"],
            chart: {
                type: 'line',
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight',
                width: 1
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: {
                categories: categoryData?.length > 0 ? categoryData : [],
                axisBorder: {
                    show: true,
                    color: "#535c68",
                },
                labels: {
                    show: true,
                    style: {
                        colors: [],
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-xaxis-label',
                    },
                },

            },
            yaxis: {
                floating: false,
                axisBorder: {
                    show: true,
                    color: "#535c68",
                },
                min: 0,
                // max: teamGraphDataAll[0] ? parseFloat(teamGraphDataAll[0].data, 10) * 2 : 100
                max: 100,
                tickAmount: 5,

                labels: {
                    show: true,
                    align: 'right',
                    minWidth: 0,
                    maxWidth: 100,
                    style: {
                        colors: "#000",
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-label',
                    },
                    offsetX: 0,
                    offsetY: 0,
                    rotate: 0,
                },
                title: {
                    text: "Percentage %",
                    offsetX: -2,
                    style: {
                        color: undefined,
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-title',
                    },
                },
            },
            noData: {
                text: "No data found for the data range selected.",
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: customGraphData ? "12px" : '18px',
                    fontWeight: "bold"
                }
            },
            legend: { showForSingleSeries: true, fontSize: '12px', position: "bottom", horizontalAlign: "middle", height: 40 },
            // legend: { show: false },
            markers: {
                size: 5,
                colors: undefined,
                strokeColors: '#fff',
                strokeWidth: 2,
                strokeOpacity: 0.9,
                strokeDashArray: 0,
                fillOpacity: 1,
                discrete: [],
                shape: "circle",
                radius: 2,
                offsetX: 0,
                offsetY: 0,
                onClick: undefined,
                onDblClick: undefined,
                showNullDataPoints: true,
                hover: {
                    size: 5,
                    sizeOffset: 5
                }
            },
            tooltip: {
                enabled: true,
                shared: false,
                intersect: true,

            },
            title: {
                text: '',
                align: 'middle',
                margin: 0,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: 'black'
                },
            },
        },
    }

    const optionStacked = {

        series: seriesData?.length > 0 ? seriesData : [],
        options: {
            // colors: ['#eb4d4b', '#ffcb4e'],
            colors: GRAPH_COLOR,

            chart: {

                stacked: true,
                type: 'bar',

                toolbar: {
                    show: false
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "80%",
                },
            },
            dataLabels: {
                enabled: false,

            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                show: true,
                categories: categoryData?.length > 0 ? categoryData : [],
                title: {
                    text: 'Groups',
                    offsetY: 88,
                    style: {
                        fontSize: "12px"
                    }
                },
                axisBorder: {
                    show: true,
                    color: "#535c68",
                },
            },
            yaxis: {
                // max:100,
                // tickAmount: 4,
                show: true,
                title: {
                    text: "Count",
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-title',
                    },

                },
                axisBorder: {
                    show: true,
                    color: "#535c68"
                },
                labels: {
                    formatter: (val) =>
                        Math.round(val)

                },
            },
            fill: {
                opacity: 1
            },
            noData: {
                text: "No data found for the data range selected.",
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: '18px',
                    fontWeight: "bold"
                }
            },
            legend: { showForSingleSeries: true, fontSize: '12px', position: "top", horizontalAlign: "right", height: 60 },

            // tooltip: {
            //     custom: ({ series, seriesIndex, dataPointIndex, w }) =>
            //     (

            //     )
            // },
            title: {
                text: '',
                align: 'middle',
                margin: 0,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: 'black'
                },
            },
        },
    };

    const optionPie = {

        series: seriesData?.length > 0 ? seriesData : [],
        options: {
            colors: GRAPH_COLOR,

            chart: {
                type: 'pie',
            },
            labels: categoryData?.length > 0 ? categoryData : [],
            tooltip: {
                enabled: true,
                y: {
                    formatter: (val) =>
                        `Group: ${val}`

                },
            },
            legend: {

                position: 'bottom'
            }
            //   responsive: [{
            //     breakpoint: 480,
            //     options: {
            //       chart: {
            //         width: 200
            //       },
            //       legend: {
            //         position: 'bottom'
            //       }
            //     }
            //   }]
        },


    };




    const getOption = (id) => {
        const option = { option: null, type: null }
        switch (id) {
            case '3.2':
                option.option = optionLine.options;
                option.type = 'line'
                break;
            case '6.1':
                option.option = optionLine.options;
                option.type = 'line'
                break;
            case '6.3':
                option.option = optionStacked.options;
                option.type = 'bar'
                break;
            case '5.1':
                option.option = optionLine.options;
                option.type = 'line'
                break;
            case '7.1':
                option.option = optionPie.options;
                option.type = 'pie'
                break;
            case '4.7':
                option.option = optionBar.options;
                option.type = 'bar'
                break;

            default:
                option.option = optionBar.options;
                option.type = 'bar'
                break;


        }
        return option
    }


    const handleChartEvents = (event, chartContext, config) => {
        console.log('event, chartContext, config', event, chartContext, config)
    }

    const handleTooltipCustomFun = (series, seriesIndex, dataPointIndex, w) => {
        console.log('weeee', w)
        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        return `<ul style="font-size: 12px;padding:0px;text-align: left; "> 
  <li style="background-color:#e0e0e0; padding:5px; margin:0px;">${w.globals.labels[dataPointIndex]}</li>
  <li style="padding:5px; margin:0px;"><b><span>${w.globals.initialSeries[seriesIndex].name}: </span></b> <span>${data}%</span></li>
  </ul>`;
    }

    const handleBarChartEvents = (event, chartContext, config) => {

        const dates = { date1: '', date2: '' }
        if (perPeriodDateType === 'week') {
            dates.date1 = moment(`${perPeriodData?.header[config.dataPointIndex]?.date_num}`, 'W').startOf('week').year(moment(selAnaEvaluationFilters?.fromDate).format('YYYY')).add(1, 'days').format('YYYY-MM-DD')
            dates.date2 = moment(`${perPeriodData?.header[config.dataPointIndex]?.date_num}`, 'W').endOf('week').year(moment(selAnaEvaluationFilters?.toDate).format('YYYY')).add(1, 'days').format('YYYY-MM-DD')
        }
        if (perPeriodDateType === 'month') {
            dates.date1 = moment(`${perPeriodData?.header[config.dataPointIndex]?.date_num}`, 'M').startOf('month').year(moment(selAnaEvaluationFilters?.fromDate).format('YYYY')).format('YYYY-MM-DD')
            dates.date2 = moment(`${perPeriodData?.header[config.dataPointIndex]?.date_num}`, 'M').endOf('month').year(moment(selAnaEvaluationFilters?.toDate).format('YYYY')).format('YYYY-MM-DD')
        }
        if (perPeriodDateType === 'quarter') {
            dates.date1 = moment(`${perPeriodData?.header[config.dataPointIndex]?.date_num}`, 'Q').startOf('quarter').year(moment(selAnaEvaluationFilters?.fromDate).format('YYYY')).format('YYYY-MM-DD')
            dates.date2 = moment(`${perPeriodData?.header[config.dataPointIndex]?.date_num}`, 'Q').endOf('quarter').year(moment(selAnaEvaluationFilters?.toDate).format('YYYY')).format('YYYY-MM-DD')
        }


        if (selAnaEvaluationFilters?.dateRange === 'Custom Date Range') {

            if (!customGraphData) navigate(`/analytics/evaluation/reportResultDetails?from_date=${dates?.date1}&to_date=${dates?.date2}&filterBy=${selAnaEvaluationFilters?.filterByRange}&date_type_key=${`${selAnaEvaluationFilters?.filterByRange}__${perPeriodDateType}`}&date_type_value=${perPeriodData?.data[config.seriesIndex][config.dataPointIndex]?.[`${selAnaEvaluationFilters?.filterByRange}__${perPeriodDateType}`]}&created_at_key=${`${selAnaEvaluationFilters?.filterByRange}__year`}&created_at_value=${perPeriodData?.data[config.seriesIndex][config.dataPointIndex]?.year}&score_Card_Id=${scoreCardId}&event_type=${selAnaEvaluationFilters?.eventType}&sub_type=${selAnaEvaluationFilters?.eventTypeEventSubType}&group=${selAnaEvaluationFilters?.group}&teams=${selAnaEvaluationFilters?.teams}&evaluator=${perPeriodData?.score_card[config.seriesIndex]?.evaluator}&dateType=${perPeriodDateType}&dateRange=${selAnaEvaluationFilters?.dateRange || searchParams.get('dateRange') || ''}&module=${3}&sub_module=${2}`)
        }
        if (selAnaEvaluationFilters?.dateRange !== 'Custom Date Range') {

            if (!customGraphData) navigate(`/analytics/evaluation/reportResultDetails?from_date=${selAnaEvaluationFilters?.fromDate}&to_date=${selAnaEvaluationFilters?.toDate}&filterBy=${selAnaEvaluationFilters?.filterByRange}&score_Card_Id=${scoreCardId}&event_type=${selAnaEvaluationFilters?.eventType}&sub_type=${selAnaEvaluationFilters?.eventTypeEventSubType}&date_type_key=${`${selAnaEvaluationFilters?.filterByRange}__${perPeriodDateType}`}&date_type_value=${perPeriodData?.data[config.seriesIndex][config.dataPointIndex]?.[`${selAnaEvaluationFilters?.filterByRange}__${perPeriodDateType}`]}&created_at_key=${`${selAnaEvaluationFilters?.filterByRange}__year`}&created_at_value=${perPeriodData?.data[config.seriesIndex][config.dataPointIndex]?.year}&group=${selAnaEvaluationFilters?.group}&teams=${selAnaEvaluationFilters?.teams}&evaluator=${perPeriodData?.score_card[config.seriesIndex]?.evaluator}&dateType=${perPeriodDateType}&dateRange=${selAnaEvaluationFilters?.dateRange || searchParams.get('dateRange') || ''}&module=${3}&sub_module=${2}`)
        }

    }

    const handleBarChartLegendEvents = (chartContext, seriesIndex, config) => {
        console.log(chartContext, seriesIndex, config)
        if (customGraphData && module === '4.7') {
            const seriesArr = config?.globals?.series[0];
            if (seriesArr[seriesIndex] !== null) {
                const tempArr = seriesArr?.map((row, index) => { if (index === seriesIndex) { return null } return row })
                return setSeriesData([{ name: 'Count', data: tempArr }])
            }
            if (seriesArr[seriesIndex] === null) {
                const tempArr = seriesArr;
                tempArr[seriesIndex] = customGraphData[0]?.data[seriesIndex];
                console.log(tempArr);
                setSeriesData([{ name: 'Count', data: tempArr }])
            }

        }

    }

    const xAxisLabelFormatter = (val) => {
        return `${val}`;
    }

    const handleTooltipYFormatter = (val) => {
        return `Group: ${val}`

    }

    return (
        <>

            <Box sx={{ width: "100%", cursor: 'default' }}>
                <FullScreen handle={handle}>
                    <Box className='my-component' display={handle?.active ? "flex" : 'block'} ref={componentRef} justifyContent="center" alignItems="center" sx={{ position: "relative", width: '100%', height: customGraphData ? `${graphHeight + 25}px` : '600px', my: customGraphData ? 0 : 3, borderRadius: 1 }}>
                        {(handle?.active === false && !customGraphData) && <Box sx={{ position: "absolute", top: "0", left: '0', zIndex: 5 }}>
                            <Stack direction="row" alignItems="center">
                                <IconButton onClick={handleClick} className='icon'>
                                    <MenuIcon />
                                </IconButton>
                            </Stack>
                            <Menu

                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem sx={{ fontSize: "12px" }} onClick={(event) => { handleClose(event); handle.enter() }}>Fullscreen</MenuItem>
                                <ReactToPrint
                                    trigger={() => <MenuItem sx={{ fontSize: "12px" }}  >Print</MenuItem>}
                                    content={() => componentRef1.current}
                                />
                                <MenuItem sx={{ fontSize: "12px" }} onClick={(event) => { handleClose(event); exportComponentAsJPEG(componentRef, { fileName: '1.5 Section_Trend_Individual_jpeg.jpeg' }) }} >Download Jpeg</MenuItem>
                                {/* <MenuItem sx={{ fontSize: "12px" }} onClick={(event) => { handleClose(event); exportComponentAsPDF(componentRef) }} >Download PDF</MenuItem> */}
                                <MenuItem sx={{ fontSize: "12px" }} onClick={(event) => { handleClose(event); exportComponentAsPNG(componentRef, { fileName: '1.5 Section_Trend_Individual_png.png' }) }} >Download png</MenuItem>
                                <MenuItem sx={{ fontSize: "12px" }} onClick={(event) => { handleClose(event); exportComponentAsPDF(componentRef1, { fileName: '1.5 Section_Trend_Individual_pdf.pdf', pdfOptions: { w: 580, h: 320, x: 50, y: 20, unit: 'px' } }) }} >Download pdf</MenuItem>
                            </Menu>
                        </Box>}

                        <Box

                            sx={{
                                my: 1, p: 1, '@media print': {
                                    marginTop: '10px',
                                    marginRight: '10px',
                                    width: '100%',
                                    display: 'block',
                                    overflow: 'hidden',
                                    position: 'relative',

                                },
                            }}>
                            {/* {
                                getOption(module).type === 'line' && <>
                                    <ReactLineChart
                                        refrence={componentRef1}
                                        series={seriesData?.length > 0 ? seriesData : []}
                                        width={handle?.active ? window?.screen.width : graphWidth}
                                        height={handle?.active ? (window?.screen.height - 250) : (graphHeight)}
                                        id='Line Chart'
                                        chartEvents={handleChartEvents}
                                        title=''
                                        xTitle=''
                                        yTitle='Percentage (%)'
                                        legendShow={!false}
                                        xCategories={categoryData?.length > 0 ? categoryData : []}
                                        color={undefined}
                                        plotOptionsDistributed={!false}
                                        handleTooltipCustomFun={handleTooltipCustomFun}
                                        plotOptionsHorizontal={false}
                                        xAxisLabelShow={!false}
                                        datalableEnabled={false}
                                        legendPosition='bottom'
                                        yTickAmount={5}
                                    />
                                </>}
                            {
                                getOption(module).type === 'pie' ? <>
                                    <ReactPieDounoughtChart
                                        chartType='pie'
                                        refrence={componentRef1}
                                        series={seriesData?.length > 0 ? seriesData : []}
                                        width={handle?.active ? window?.screen.width : graphWidth}
                                        height={handle?.active ? (window?.screen.height - 250) : (graphHeight)}
                                        id='Pie Chart'
                                        title=''
                                        xTitle=''
                                        yTitle=''
                                        legendShow={!false}
                                        datalableEnabled={!false}
                                        plotOptionsPieDonutLabelTotal={false}
                                        plotOptionsPieDonutLabel={false}
                                        plotOptionsPieDonutLabelName={false}
                                        color={undefined}
                                        tooltipYFormatter={handleTooltipYFormatter}
                                        labelData={categoryData?.length > 0 ? categoryData : []}
                                    />
                                </> : <ReactBarChart
                                    refrence={componentRef1}
                                    series={seriesData?.length > 0 ? seriesData : []}
                                    width={handle?.active ? window?.screen.width : graphWidth}
                                    height={handle?.active ? (window?.screen.height - 250) : (graphHeight)}
                                    id='Bar Chart'
                                    chartEvents={handleBarChartEvents}
                                    barChartLegendsEvent={handleBarChartLegendEvents}
                                    title=''
                                    xTitle=''
                                    yTitle='Count'
                                    legendShow={!false}
                                    legendPosition='bottom'
                                    xCategories={categoryData?.length > 0 ? categoryData : []}
                                    color={undefined}
                                    plotOptionsDistributed={false}
                                    xAxisLabelShow={!false}
                                    yAxisLabelShow={!false}
                                    xAxisLabelFormatter={xAxisLabelFormatter}
                                    handleTooltipCustomFun={handleTooltipCustomFun}
                                />
                            } */}

                            <Chart
                                ref={componentRef1}
                                options={getOption(module).option}
                                series={customGraphData ? optionLine?.series : optionBar?.series}
                                type={getOption(module).type}
                                width={handle?.active ? window?.screen.width : graphWidth}
                                height={handle?.active ? (window?.screen.height - 250) : (graphHeight)}
                            />
                        </Box>
                    </Box>

                </FullScreen >

            </Box >

            {/* <Box sx={{ mt: 5, mb: 3, p: 1 }}>
                <Chart options={option.options} series={option.series} type="bar" className="pie" />
            </Box> */}
        </>
    )
}