import { Stack, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Icon } from '@iconify/react';


// tabBtns IS ARRAY CONTAINING TAB RELATED INFO LIKE LABEL AND OTHER INFO
// handleChange IS USED WITH OnChange FUNCTION OF Tablist COMPONENT
// fn IS USED WITH OnClick EVENT OF Tab COMPONENT
// sx IS USED FOR STYLING TabList
// Component PROPS IS USED WITH Tab
// noScrollable TAKE BOOLEAN VALUE, IF true THEN standard IS SET ELSE scrollable IS SET IN variant PROP OF TabList




export default function GlobalTab({ tabBtns, handleChange, fn, sx, component, noScrollable }) {


    const tabButtons = (props) => {
        console.log('props.direction', props)
        if (props.direction === 'left' && !props.disabled) {
            return (<Stack direction='row' alignItems='center' sx={{ height: 'inherit' }}>
                <Icon onClick={() => props.onClick()}
                    icon="icon-park-solid:left-c"
                    style={{ color: '#fb5d2e', fontSize: '25px', cursor: 'pointer' }}
                />
            </Stack>)
        }
        if (props.direction === 'right' && !props.disabled) {
            return (
                <Stack direction='row' alignItems='center' sx={{ height: 'inherit' }}>
                    <Icon onClick={() => props.onClick()}
                        icon="icon-park-solid:right-c"
                        style={{ color: '#fb5d2e', fontSize: '25px', cursor: 'pointer' }}
                    />
                    {/* <Box component='span'>13/{groupLength}</Box> */}

                </Stack>
            )
        }
        return null
    }


    return (<>
        <TabList
            TabIndicatorProps={{
                style: {
                    backgroundColor: theme => theme.palette.action.selected,
                    height: '2.5px',

                }
            }}
            // TabIndicatorProps={{
            //     style: { display: 'none' }
            // }}
            ScrollButtonComponent={(props) => tabButtons(props)}
            variant={noScrollable ? 'standard' : 'scrollable'}
            sx={{
                width: '100%',
                px: 3,
                alignItems: 'flex-end',

                // borderBottom: (theme) => {
                //     return {
                //         lg: `2px solid ${theme.palette.text.primaryBolderBorder}`,
                //         xl: `3px solid ${theme.palette.text.PrimaryBolderBorder}`
                //     }
                // },
                backgroundColor: (theme) => theme.palette.background.paper,

                ...sx
            }}
            onChange={handleChange}>

            {tabBtns?.map((tab, index) =>

                <Tab
                    sx={{
                        color: theme => theme.palette.functionalityBtn,
                        fontSize: { xl: '15px' },
                        fontWeight: { lg: 200, xl: 700 },
                        "&.Mui-selected": {
                            color: theme => theme.palette.action.selected,
                            fontWeight: 700,
                        }
                    }}
                    // key={index}
                    label={tab?.label}
                    onClick={() => { fn(tab, index) }}
                    value={String(index + 1)}
                />
            )}

            {component}

        </TabList>

    </>)
}