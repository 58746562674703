import { createContext, useReducer, useEffect, useState } from 'react';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
//
import moment from 'moment'

import axios from 'axios';
import * as fs from 'fs';
//
import humps from 'humps';
import { isValidToken, setSession } from '../utils/jwt';
// API
import { API } from "../backend";
// import data from '@iconify/icons-eva/menu-2-fill';

// -----------------------------------------------------------

const AddUserContext = createContext();

const handlers = {
  USERS_LEVEL: (state, action) => {
    // console.log(action.payload);
    const userLevels = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      userLevels
    };
  },
  GROUPS: (state, action) => {
    // console.log(action.payload);
    const groups = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      groups
    };
  },
  Event_GROUPS: (state, action) => {
    // console.log(action.payload);
    const eventGroups = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      eventGroups
    };
  },
  TEAMS: (state, action) => {
    const teams = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      teams
    };
  },
  ADD_USER: (state, action) => {
    const user = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  GET_ALL_USERS: (state, action) => {
    const allUsers = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      allUsers
    };
  },
  GET_ALL_USERS_GROUPS_TEAMS: (state, action) => {
    const allUsersGroupsTeams = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      allUsersGroupsTeams
    };
  },
  GET_USAGE: (state, action) => {
    const usageDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      usageDetails
    };
  },
  GET_USERS_ACTIVITY_LOG: (state, action) => {
    const usersActivityLog = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      usersActivityLog
    };
  },
  USERS_NAME: (state, action) => {
    // console.log(action.payload);
    const userNames = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      userNames
    };
  },
  LOGIN_DETAILS: (state, action) => {
    const loggedUserDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      loggedUserDetails
    };
  },
  DAILY_USAGE: (state, action) => {
    const dailyUsageTableDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      dailyUsageTableDetails
    };
  },
  DAILY_CALLS_USAGE: (state, action) => {
    const dailyCallsUsageTableDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      dailyCallsUsageTableDetails
    };
  },
  FAILED_CALLS_DETAILS: (state, action) => {
    const failedCallsDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      failedCallsDetails
    };
  },
  META_COUNT_DETAILS: (state, action) => {
    const metaCountDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      metaCountDetails
    };
  },
  DAILY_USAGE_GRAPH: (state, action) => {
    const dailyUsageDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      dailyUsageDetails
    };
  },
  GET_SECURITY_ATTACHEMENT: (state, action) => {
    const securityAttachementDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      securityAttachementDetails
    };
  },
  GET_ATTACHEMENT_DETAILS: (state, action) => {
    const securityAttachementScoreDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      securityAttachementScoreDetails
    };
  },
  GET_SECURITY_ARCHIVE: (state, action) => {
    const securityArchiveDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      securityArchiveDetails
    };
  },
  PWD_MANAGEMENT: (state, action) => {
    const securityPwdDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      securityPwdDetails
    };
  },
  GET_IP_WHITELIST: (state, action) => {
    const whitelistingDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      whitelistingDetails
    };
  },
  // CALLS_DETAILS_INFO: (state, action) => {
  //   const callsDetailsInfo = action.payload;

  //   return {
  //     ...state,
  //     isAuthenticated: true,
  //     callsDetailsInfo
  //   };
  // },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

export const AddUserProvider = ({ children }) => {
  const accessToken = window.localStorage.getItem('accessToken');
  const initialState = {
    userLevels: [],
    groups: [],
    Teams: [],
    allUsers: {}
  };

  const [showAddApiClientDialog, setShowAddApiClientDialog] = useState(false);
  const [blockTitle, setBlockTitle] = useState();
  const [showAddUserButton, setShowAddUserButton] = useState();
  const [showActivityButton, setShowActivityButton] = useState();
  const [showUsageFilter, setShowUsageFilter] = useState();
  const [showDailyUsage, setShowDailyUsage] = useState(false);
  const [showDeveloperFilter, setShowDeveloperFilter] = useState(false);
  const [showSpinner, setShowSpinner] = useState();
  const [showGroupSpinner, setShowGroupSpinner] = useState();
  const [editUserSpinner, setEditUserSpinner] = useState();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [open, setOpen] = useState(false);
  const [openClientDialog, setOpenClientDialog] = useState(false);
  const [isAddMode, setIsAddMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showApiClientsPage, setShowApiClientsPage] = useState(false);
  const [showDocumentationPage, setShowDocumentationPage] = useState(false);
  const [usageDialogTitle, setUsageDialogTitle] = useState(false);

  const [showApiDialog, setShowApiDialog] = useState();
  const [showArchiveDialog, setShowArchiveDialog] = useState();
  const [showAttachmentDialog, setShowAttachmentDialog] = useState();
  const [dailyUsageFilterData, setDailyUsageFilterData] = useState();
  const [selectedActivityFilters, setSelectedActivityFilters] = useState();
  const [page, setPage] = useState(1);
  const [delOpenArchive, setDelOpenArchive] = useState(false);
  // Toggle users list
  const [showDeletedUsers, setShowDeletedUsers] = useState(false);
  // Toggle date range for daily usage graph
  const [showDayWiseData, setShowDayWiseData] = useState(true);

  const [callDate, setCallDate] = useState({
    date: '',
    anotherDay: ''
  });

  // Get groups data
  const getGroups = () => {
    const accessToken = window.localStorage.getItem('accessToken');
    axios
      .get(`${API}/groups`, {
        headers: {
          Authorization: `Token ${accessToken}`,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        const { results } = response.data;
        dispatch({
          type: 'GROUPS',
          payload: results
        });
      })
      .catch((error) => {
        // console.error(error)
      });
  };


  // Get teams data
  const getTeams = async (groupId) => {
    const accessToken = window.localStorage.getItem('accessToken');
    const data = await axios
      .get(`${API}/team/teams_filter?groups=${groupId}`, {
        headers: {
          Authorization: `Token ${accessToken}`,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log(response)
        console.log(response.data.data)
        const results = response.data.data;
        // dispatch({
        //   type: 'TEAMS',
        //   payload: data
        // });
        return results;
      })
      .catch((error) => {
        console.error(error);
        // dispatch({
        //   type: 'TEAMS',
        //   payload: null
        // });
      });

    return data;
  };

  // Get User Levels data
  const getUserLevels = () => {
    const accessToken = window.localStorage.getItem('accessToken');

    axios
      .get(`${API}/users_level`, {
        headers: {
          Authorization: `Token ${accessToken}`,
          'Content-Type': 'application/json'
        }
      })
      .then((res) => {
        // console.log(res.data);
        dispatch({
          type: 'USERS_LEVEL',
          payload: res.data.results
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Add New User
  const addUser = async (
    email,
    password,
    firstName,
    lastName,
    userLevel,
    groups,
    teams,
    supportAccess,
    billingAccess,
    readOnly,
    mustChangePassword,
    resetPassword,
    country,
    city,
    shortUsername,
    isAiAccess
  ) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const response = await axios.post(
      `${API}/users`,
      JSON.stringify(
        humps.decamelizeKeys({
          email,
          password,
          firstName,
          lastName,
          userLevel,
          groups,
          teams,
          supportAccess,
          billingAccess,
          readOnly,
          mustChangePassword,
          username: email,
          resetPassword,
          country,
          city,
          shortUsername,
          isAiAccess
        })
      ),
      {
        headers: {
          Authorization: `Token ${accessToken}`,
          'Content-Type': 'application/json'
        }
      }
    );

    const results = humps.camelizeKeys(response?.data);

    dispatch({
      type: 'LOGIN_DETAILS',
      payload: results
    });
  };

  // Edit User
  const editUser = async (
    email,
    firstName,
    lastName,
    userLevel,
    groups,
    teams,
    supportAccess,
    billingAccess,
    readOnly,
    mustChangePassword,
    id,
    resetPassword,
    country,
    city,
    shortUsername,
    isAiAccess
  ) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const data = await axios
      .put(
        `${API}/users/${id}`,
        JSON.stringify(
          humps.decamelizeKeys({
            email,
            firstName,
            lastName,
            userLevel,
            groups,
            teams,
            supportAccess,
            billingAccess,
            readOnly,
            mustChangePassword,
            username: email,
            resetPassword,
            country,
            city,
            shortUsername,
            isAiAccess
          })
        ),
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((res) => {
        const results = humps.camelizeKeys(res.data);
        return results;
        // dispatch({
        //     type: 'GET_SINGLE_SCORECARD',
        //     payload: results,
        // })
      })
      .catch((error) => {
        console.error(error);
      });

    return data;
  };

  // Delete User
  const deleteUser = async (row) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const userId = row;
    const response = await axios.delete(`${API}/users/${userId}`, {
      headers: {
        Authorization: `Token ${accessToken}`
      }
    });
  };

  // Delete multiple user
  const deleteMultipleUser = async (row) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const allUsersId = row;

    // const response = await axios.delete(`${API}/users/${userId}`, {
    //   headers: {
    //     'Authorization': `Token ${accessToken}`
    //   }
    // });

    const response = await axios.all(
      allUsersId.map((userId) =>
        axios.delete(`${API}/users/${userId}`, {
          headers: {
            Authorization: `Token ${accessToken}`
          }
        })
      )
    );
  };

  // Get All Users
  const getAllUsers = (page) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    if (accessToken) {
      axios
        .get(`${API}/users?page=${page}`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          console.log('res', res)
          const { results, count, pageRange } = humps.camelizeKeys(res.data);

          dispatch({
            type: 'GET_ALL_USERS',
            payload: { results, count, pageRange }
          });
          setShowSpinner(false);
        })
        .catch((error) => {
          setShowSpinner(false);
          console.error(error);
        });
    }
  };

  // Get All Users groups and teams
  const getAllUsersGroupsTeams = async (userId) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setEditUserSpinner(true);

    if (accessToken) {
      const data = await axios
        .get(`${API}/users/${userId}`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          const results = humps.camelizeKeys(res.data);
          setEditUserSpinner(false);

          return results;

          // dispatch({
          //   type: 'GET_ALL_USERS_GROUPS_TEAMS',
          //   payload: results
          // });
        })
        .catch((error) => {
          setEditUserSpinner(false);
          console.error(error);
        });

      return data;
    }
  };

  // Get Users groups count
  const getUserGroupsCount = async (userId) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowGroupSpinner(true);

    if (accessToken) {
      const data = await axios
        .get(`${API}/groups/user_group_list?user=${userId}`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          const { data } = humps.camelizeKeys(res.data);
          setShowGroupSpinner(false);

          return data;

          // dispatch({
          //   type: 'GET_ALL_USERS_GROUPS_TEAMS',
          //   payload: results
          // });
        })
        .catch((error) => {
          setShowGroupSpinner(false);
          console.error(error);
        });

      return data;
    }
  };

  // Get Users teams count
  const getUserTeamsCount = async (userId) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowGroupSpinner(true);

    if (accessToken) {
      const data = await axios
        .get(`${API}/team/user_teams_list?user=${userId}`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          const { data } = humps.camelizeKeys(res.data);
          setShowGroupSpinner(false);

          return data;

          // dispatch({
          //   type: 'GET_ALL_USERS_GROUPS_TEAMS',
          //   payload: results
          // });
        })
        .catch((error) => {
          setShowGroupSpinner(false);
          console.error(error);
        });

      return data;
    }
  };

  // Refresh All Users
  const refreshAllUsers = (page) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    if (accessToken) {
      axios
        .get(`${API}/users?page=${page}`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          const { results, count, pageRange } = humps.camelizeKeys(res.data);

          dispatch({
            type: 'GET_ALL_USERS',
            payload: { results, count, pageRange }
          });
          setShowSpinner(false);
        })
        .catch((error) => {
          setShowSpinner(false);
          console.error(error);
        });
    }
  };

  // Get Deleted Users
  const getDeletedUsers = (page) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    if (accessToken) {
      axios
        .get(`${API}/users?is_deleted=True`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          const { results, count, pageRange } = humps.camelizeKeys(res.data);

          dispatch({
            type: 'GET_ALL_USERS',
            payload: { results, count, pageRange }
          });
          setShowSpinner(false);
        })
        .catch((error) => {
          setShowSpinner(false);
          console.error(error);
        });
    }
  };

  // Restore the deleted users
  const restoreUser = (userId) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    if (accessToken) {
      axios.get(`${API}/users/restore_user?user_id=${userId}`, {
        headers: {
          Authorization: `Token ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      // .then((res) => {
      //   const { results, count } = humps.camelizeKeys(res.data);

      //   dispatch({
      //     type: 'GET_ALL_USERS',
      //     payload: { results, count }
      //   })
      //   setShowSpinner(false);
      // })
      // .catch((error) => {
      //   setShowSpinner(false);
      //   console.error(error);
      // })
    }
  };

  // Delete User permanently
  const deleteUserPermanently = async (userId) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    if (accessToken) {
      const data = axios
        .get(`${API}/users/hard_delete_user?user_id=${userId}`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          const { results, count } = humps.camelizeKeys(res.data);

          dispatch({
            type: 'GET_ALL_USERS',
            payload: { results, count }
          });
          setShowSpinner(false);
        })
        .catch((error) => {
          setShowSpinner(false);
          console.error(error);
        });

      return data;
    }
  };

  // Get All Users
  const getUsage = () => {
    const accessToken = window.localStorage.getItem('accessToken');
    // setShowSpinner(true);

    if (accessToken) {
      axios
        .get(`${API}/app_usage`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          // setShowSpinner(false);
          const { results } = humps.camelizeKeys(res.data);

          dispatch({
            type: 'GET_USAGE',
            payload: results
          });
        })
        .catch((error) => {
          // setShowSpinner(false);
          console.error(error);
        });
    }
  };

  // const getUsersActivityLog = (page) => {
  //   const accessToken = window.localStorage.getItem('accessToken');

  //   if (accessToken) {

  //     axios.get(`${API}/activity_log?page=${page}`, {
  //       headers: {
  //         'Authorization': `Token ${accessToken}`,
  //         'Content-Type': 'application/json'
  //       }
  //     })
  //       .then((res) => {
  //         console.log("users activity log-------", res);

  //         const { data } = humps.camelizeKeys(res);
  //         dispatch({
  //           type: 'GET_USERS_ACTIVITY_LOG',
  //           payload: data,
  //         })
  //       })
  //       .catch((error) => {
  //         console.error(error)
  //       })
  //   }
  // }

  // Search User
  const searchUsersTable = (filterList, page) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    if (accessToken) {
      axios
        .get(`${API}/users/search?value=${filterList}&page=${page}`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          const { results, count, pageRange } = humps.camelizeKeys(res.data);
          // const results = humps.camelizeKeys(res.data);

          dispatch({
            type: 'GET_ALL_USERS',
            payload: { results, count, pageRange }
          });
          setShowSpinner(false);
        })
        .catch((error) => {
          setShowSpinner(false);
          console.error(error);
        });
    }
  };

  // Search Deleted User
  const searchDeletedUsersTable = (filterList, page) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    if (accessToken) {
      axios
        .get(`${API}/users/search?value=${filterList}&page=${page}&is_deleted=True`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          const { results, count, pageRange } = humps.camelizeKeys(res.data);

          dispatch({
            type: 'GET_ALL_USERS',
            payload: { results, count, pageRange }
          });
          setShowSpinner(false);
        })
        .catch((error) => {
          setShowSpinner(false);
          console.error(error);
        });
    }
  };

  // Get All Users in xls format
  const downloadUsersXls = async () => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { 'Content-Type': 'blob' };
    const config = { method: 'GET', url: `${API}/users/download`, responseType: 'arraybuffer', headers };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `BeyondQA Users - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // Get all users activity log
  const getUsersActivityLog = (page) => {
    const accessToken = window.localStorage.getItem('accessToken');

    if (accessToken) {
      axios
        .get(`${API}/activity_log?page=${page}`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          // console.log("users activity log-------", res);

          const { data } = humps.camelizeKeys(res);
          dispatch({
            type: 'GET_USERS_ACTIVITY_LOG',
            payload: data
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  // Search users activity log
  // const searchUsersActivityLog = (filterList, page) => {
  //   const accessToken = window.localStorage.getItem('accessToken');

  //   if (accessToken) {

  //     axios.get(`${API}/activity_log/search?page=${page}&value=${filterList}`, {
  //       headers: {
  //         'Authorization': `Token ${accessToken}`,
  //         'Content-Type': 'application/json'
  //       }
  //     })
  //       .then((res) => {
  //         // console.log("search users activity log-------", res);

  //         const { data } = humps.camelizeKeys(res);
  //         dispatch({
  //           type: 'GET_USERS_ACTIVITY_LOG',
  //           payload: data,
  //         })
  //       })
  //       .catch((error) => {
  //         console.error(error)
  //       })
  //   }
  // }

  // Filter users activity log
  const filterUsersActivityLog = (obj) => {
    // console.log("filter users activity log", name, action, startDate, endDate)
    const accessToken = window.localStorage.getItem('accessToken');

    if (accessToken) {
      axios
        .get(
          `${API}/activity_log/filters?start_date=${obj.fromDate}&end_date=${obj.toDate}&name=${obj.userName}&action=${obj.action}&action_type=${obj.actionType}&value=${obj.filterName || ''}&page=${obj.page}`,
          {
            headers: {
              Authorization: `Token ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        )
        .then((res) => {
          // console.log("search users activity log-------", res);

          const { data } = humps.camelizeKeys(res);
          dispatch({
            type: 'GET_USERS_ACTIVITY_LOG',
            payload: data
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  // Search users activity log
  const searchUsersActivityLog = (obj) => {
    // console.log("filter users activity log", name, action, startDate, endDate)
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    if (accessToken) {
      axios
        .get(
          `${API}/activity_log/filters?start_date=${obj.fromDate}&end_date=${obj.toDate}&name=${obj.userName}&action=${obj.action}&action_type=${obj.actionType}&page=${obj.page}&value=${obj.filterList}`,
          {
            headers: {
              Authorization: `Token ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        )
        .then((res) => {
          // console.log("search users activity log-------", res);

          const { data } = humps.camelizeKeys(res);
          dispatch({
            type: 'GET_USERS_ACTIVITY_LOG',
            payload: data
          });
          setShowSpinner(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  // Get User Name data
  const getUserNames = () => {
    const accessToken = window.localStorage.getItem('accessToken');

    axios
      .get(`${API}/activity_log/activity_users`, {
        headers: {
          Authorization: `Token ${accessToken}`,
          'Content-Type': 'application/json'
        }
      })
      .then((res) => {
        // console.log(res.data);
        dispatch({
          type: 'USERS_NAME',
          payload: res.data.result
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Get Action Types
  const getActionTypes = () => {
    const accessToken = window.localStorage.getItem('accessToken');

    axios
      .get(`${API}/activity_log/activity_users`, {
        headers: {
          Authorization: `Token ${accessToken}`,
          'Content-Type': 'application/json'
        }
      })
      .then((res) => {
        // console.log(res.data);
        dispatch({
          type: 'USERS_NAME',
          payload: res.data.results
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Filter users activity log
  const resetPassword = (user) => {
    // console.log("filter users activity log", name, action, startDate, endDate)
    const accessToken = window.localStorage.getItem('accessToken');

    if (accessToken) {
      axios.get(`${API}/users/verification?user=${user}`, {
        headers: {
          Authorization: `Token ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
    }
  };

  // Email export result
  const exportResults = (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    const data = axios
      .get(
        `${API}/export/score_result?from_date=${moment(obj.fromDate).format('YYYY-MM-DD')}&to_date=${moment(
          obj.toDate
        ).format('YYYY-MM-DD')}&filter_by=${obj.filterBy}&score_card__groups=${obj.groups}&type=${obj.type
        }&score_card__in=${obj.scoreCard}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.error(error);
      });
    return data;
  };

  // Email export result
  const emailExportResults = (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    axios
      .get(
        `${API}/export/score_result?from_date=${moment(obj.fromDate).format('YYYY-MM-DD')}&to_date=${moment(
          obj.toDate
        ).format('YYYY-MM-DD')}&filter_by=${obj.filterBy}&score_card__groups=${obj.groups}&type=${obj.type
        }&score_card__in=${obj.scoreCard}&send_email=${1}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Get daily usage for table
  const getDailyUsage = (obj, page) => {
    const accessToken = window.localStorage.getItem('accessToken');
    axios
      .get(
        `${API}/app_usage/daily_usage_view?from_date=${obj.fromDate}&to_date=${obj.toDate}&date_range=${obj.dateRange}&page=${page}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { results, count, pageRange } = humps.camelizeKeys(response.data);

        dispatch({
          type: 'DAILY_USAGE',
          payload: { results, count, pageRange }
        });
      })
      .catch((error) => {
        // console.error(error);
        dispatch({
          type: 'DAILY_USAGE',
          payload: null
        });
      });
  };

  // Get daily calls usage for table
  const getFailedCallsUsage = (date, anotherDay) => {
    const accessToken = window.localStorage.getItem('accessToken');
    axios
      .get(
        `${API}/calls/failed_calls_details?call_date=${date}&another_day=${anotherDay}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { results } = humps.camelizeKeys(response.data);

        dispatch({
          type: 'FAILED_CALLS_DETAILS',
          payload: results
        });
      })
      .catch((error) => {
        // console.error(error);
        dispatch({
          type: 'FAILED_CALLS_DETAILS',
          payload: null
        });
      });
  };


  // Get daily calls usage for table
  const getDailyCallsUsage = (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    axios
      .get(
        `${API}/calls/call_usage_report?from_date=${obj.fromDate}&to_date=${obj.toDate}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { results } = humps.camelizeKeys(response.data);

        dispatch({
          type: 'DAILY_CALLS_USAGE',
          payload: results
        });
        setShowSpinner(false);
      })
      .catch((error) => {
        // console.error(error);
        dispatch({
          type: 'DAILY_CALLS_USAGE',
          payload: null
        });
        setShowSpinner(false);
      });
  };

  // Get daily calls usage for table
  const getMetaDataCount = (date) => {
    const accessToken = window.localStorage.getItem('accessToken');
    axios
      .get(
        `${API}/calls/get_metadata_count?date=${date}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        console.log(response)
        const results = humps.camelizeKeys(response?.data?.data);

        dispatch({
          type: 'META_COUNT_DETAILS',
          payload: results
        });
      })
      .catch((error) => {
        // console.error(error);
        dispatch({
          type: 'META_COUNT_DETAILS',
          payload: null
        });
      });
  };

  // Get daily usage for graph
  const getDailyUsageGraph = (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    axios
      .get(
        `${API}/app_usage/daily_usage_view?from_date=${obj.fromDate}&to_date=${obj.toDate}&date_range=${obj.dateRange}&time_line=${obj.timeLine}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        // console.log(response);
        const { results } = humps.camelizeKeys(response.data);

        dispatch({
          type: 'DAILY_USAGE_GRAPH',
          payload: results
        });
      })
      .catch((error) => {
        // console.error(error);
        dispatch({
          type: 'DAILY_USAGE_GRAPH',
          payload: null
        });
      });
  };

  // Get All daily usage details in xls format
  const downloadDailyUsageXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    // setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { 'Content-Type': 'blob', Authorization: `Token ${accessToken}` };
    const config = {
      method: 'GET',
      url: `${API}/app_usage/daily_usage_view_export?from_date=${obj.fromDate}&to_date=${obj.toDate}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `BeyondQA Daily Usage - ${currentDateTime}.xlsx`;

      // setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      // setShowSpinner(false);
      throw Error(error);
    }
  };

  // Get All daily usage details in xls format
  const downloadCallsUsageXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    // setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { 'Content-Type': 'blob', Authorization: `Token ${accessToken}` };
    const config = {
      method: 'GET',
      url: `${API}/calls/call_usage_xlxs_report?from_date=${obj.fromDate}&to_date=${obj.toDate}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `BeyondQA Calls Usage - ${currentDateTime}.xlsx`;

      // setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      // setShowSpinner(false);
      throw Error(error);
    }
  };

  // Get Failed calls details in xls format
  const downloadFailedCallsUsageXls = async (callDate, anotherDay) => {
    // console.log(callDate);

    const accessToken = window.localStorage.getItem('accessToken');
    // setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { 'Content-Type': 'blob', Authorization: `Token ${accessToken}` };
    const config = {
      method: 'GET',
      url: `${API}/calls/xlxs_failed_calls_details?call_date=${callDate}&another_day=${anotherDay}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `BeyondQA Failed Calls Details - ${currentDateTime}.xlsx`;

      // setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      // setShowSpinner(false);
      throw Error(error);
    }
  };

  // Get security attachment
  const getSecurityAttachement = (search, page) => {
    const accessToken = window.localStorage.getItem('accessToken');

    if (accessToken) {
      axios
        .get(`${API}/score_attachments?search=${search}&page=${page}`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          // setShowSpinner(false);
          const { results, count } = humps.camelizeKeys(res.data);

          dispatch({
            type: 'GET_SECURITY_ATTACHEMENT',
            payload: { results, count }
          });
        })
        .catch((error) => {
          // setShowSpinner(false);
          console.error(error);
        });
    }
  };

  // Get security attachment details
  const getSecurityAttachementDetails = (scoreId) => {
    const accessToken = window.localStorage.getItem('accessToken');

    if (accessToken) {
      axios
        .get(`${API}/score_attachments/score_details?score=${scoreId}`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          const results = humps.camelizeKeys(res.data);
          dispatch({
            type: 'GET_ATTACHEMENT_DETAILS',
            payload: results
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  // Add IP
  const addIP = async (ipAddress, description) => {
    const accessToken = window.localStorage.getItem('accessToken');

    await axios.post(
      `${API}/ip_whitelist`,
      JSON.stringify(
        humps.decamelizeKeys({
          ipAddress,
          description
        })
      ),
      {
        headers: {
          Authorization: `Token ${accessToken}`,
          'Content-Type': 'application/json'
        }
      }
    );
  };

  // Get security archive list
  const getSecurityArchive = (search) => {
    const accessToken = window.localStorage.getItem('accessToken');

    if (accessToken) {
      axios
        .get(`${API}/archive?search=${search}`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          const results = humps.camelizeKeys(res.data);
          dispatch({
            type: 'GET_SECURITY_ARCHIVE',
            payload: results
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  // Create archive
  const createArchive = async (fromDate, toDate, group) => {
    const accessToken = window.localStorage.getItem('accessToken');

    await axios.post(
      `${API}/archive`,
      JSON.stringify(
        humps.decamelizeKeys({
          fromDate,
          toDate,
          group
        })
      ),
      {
        headers: {
          Authorization: `Token ${accessToken}`,
          'Content-Type': 'application/json'
        }
      }
    );
  };

  // Delete archive
  const deleteArchive = async (fromDate, toDate, group, deleteScore, backup) => {
    const accessToken = window.localStorage.getItem('accessToken');

    await axios.post(
      `${API}/archive`,
      JSON.stringify(
        humps.decamelizeKeys({
          fromDate,
          toDate,
          group,
          deleteScore,
          backup
        })
      ),
      {
        headers: {
          Authorization: `Token ${accessToken}`,
          'Content-Type': 'application/json'
        }
      }
    );
  };

  // Download security archive files
  const downloadArchive = (fileId, fileName) => {
    const accessToken = window.localStorage.getItem('accessToken');

    if (accessToken) {
      axios
        .get(`${API}/archive/download_archive?id=${fileId}`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'blob'
          }
        })
        .then((res) => {
          window.open(res.data.url, '_blank');
          // const url = window.URL.createObjectURL(new Blob([res.data.url]));
          // const link = document.createElement('a');
          // link.href = url;
          // link.setAttribute('download', `${fileName}`);
          // document.body.appendChild(link);
          // link.click();
          // const results = humps.camelizeKeys(res.data);
          // dispatch({
          //   type: 'GET_SECURITY_ARCHIVE',
          //   payload: results
          // })
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  // Delete archived file
  const deleteArchiveFile = async (fileId) => {
    const accessToken = window.localStorage.getItem('accessToken');
    const response = await axios.delete(`${API}/archive/${fileId}`, {
      headers: {
        Authorization: `Token ${accessToken}`
      }
    });
  };

  // Passwords management
  const passwordsManagement = async (passExpireEnable, passExpireDays, passHistoryEnable, noUniquePass, id) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const data = await axios
      .put(
        `${API}/password_management/1`,
        JSON.stringify(
          humps.decamelizeKeys({
            passExpireEnable,
            passExpireDays,
            passHistoryEnable,
            noUniquePass
          })
        ),
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((res) => {
        const results = humps.camelizeKeys(res.data);
        return results;
        // dispatch({
        //     type: 'GET_SINGLE_SCORECARD',
        //     payload: results,
        // })
      })
      .catch((error) => {
        console.error(error);
      });

    return data;
  };

  // Support access api
  const updateSupportAccess = async (supportAccess) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const data = await axios
      .patch(
        `${API}/password_management/1`,
        JSON.stringify(
          humps.decamelizeKeys({
            supportAccess
          })
        ),
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((res) => {
        const results = humps.camelizeKeys(res.data);
        return results;
        // dispatch({
        //     type: 'GET_SINGLE_SCORECARD',
        //     payload: results,
        // })
      })
      .catch((error) => {
        console.error(error);
      });

    return data;
  };

  // Delete attachment
  const deleteAttachment = async (fileId) => {
    const accessToken = window.localStorage.getItem('accessToken');
    const response = await axios.delete(`${API}/score_attachments/${fileId}`, {
      headers: {
        Authorization: `Token ${accessToken}`
      }
    });
  };

  // Get password settings
  // const getPasswordSettings = () => {
  //   const accessToken = window.localStorage.getItem('accessToken');
  //   axios.get(`${API}/password_management/1`, {
  //     headers: {
  //       'Authorization': `Token ${accessToken}`,
  //       'Content-Type': 'application/json'
  //     }
  //   })
  //     .then((response) => {
  //       const results = response;
  //       dispatch({
  //         type: 'PWD_MANAGEMENT',
  //         payload: results
  //       });
  //     })
  //     .catch((error) => {
  //       // console.error(error)
  //     })
  // }

  // Get password settings
  const getPasswordSettings = async () => {
    const accessToken = window.localStorage.getItem('accessToken');

    const data = await axios
      .get(`${API}/password_management/1`, {
        headers: {
          Authorization: `Token ${accessToken}`,
          'Content-Type': 'application/json'
        }
      })
      .then((res) => {
        const results = humps.camelizeKeys(res.data);
        return results;
      })
      .catch((error) => {
        console.error(error);
      });

    return data;
  };

  // Delete attachments
  const deleteAttachments = async (fromDate, toDate, groups) => {
    const accessToken = window.localStorage.getItem('accessToken');

    await axios.post(
      `${API}/score_attachments/delete_attachment`,
      JSON.stringify(
        humps.decamelizeKeys({
          fromDate,
          toDate,
          groups
        })
      ),
      {
        headers: {
          Authorization: `Token ${accessToken}`,
          'Content-Type': 'application/json'
        }
      }
    );
  };

  // Get ip whitelisting list
  const getIpWhitelisting = (search) => {
    const accessToken = window.localStorage.getItem('accessToken');

    if (accessToken) {
      axios
        .get(`${API}/ip_whitelist?search=${search}`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          const results = humps.camelizeKeys(res.data);
          dispatch({
            type: 'GET_IP_WHITELIST',
            payload: results
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  // Delete ip
  const deleteIp = async (ipId) => {
    const accessToken = window.localStorage.getItem('accessToken');
    const response = await axios.delete(`${API}/ip_whitelist/${ipId}`, {
      headers: {
        Authorization: `Token ${accessToken}`
      }
    });
  };

  // Get daily calls usage for table
  // const getCallsUsageInfo = () => {
  //   const accessToken = window.localStorage.getItem('accessToken');
  //   axios
  //     .get(
  //       `${API}/calls/get_call_pre_processing_info`,
  //       {
  //         headers: {
  //           Authorization: `Token ${accessToken}`,
  //           'Content-Type': 'application/json'
  //         }
  //       }
  //     )
  //     .then((response) => {
  //       const { result } = humps.camelizeKeys(response.data);

  //       console.log(response)
  //       dispatch({
  //         type: 'CALLS_DETAILS_INFO',
  //         payload: result
  //       });
  //     })
  //     .catch((error) => {
  //       // console.error(error);
  //       dispatch({
  //         type: 'CALLS_DETAILS_INFO',
  //         payload: null
  //       });
  //     });
  // };

  /* 
  @description Developer Module Api ----
  
  */
  const [scopeApiData, setScopeApiData] = useState([]);
  const [clientSecretClientIdData, setClientSecretClientIdData] = useState([]);
  const [apiClientData, setApiClientData] = useState([]);
  const [dashboardGraphData, setDashboardGraphData] = useState([]);
  const [dashboardTableData, setDashboardTableData] = useState([]);
  const [selectPage, setSelectPage] = useState(0);

  // Developer Dashboard graph api ----

  const getDeveloperDashboardGraph = (page) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    if (accessToken) {
      axios
        .get(`${API}/api_client/developer_dashboard`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          const { data, count } = humps.camelizeKeys(res.data);
          setDashboardGraphData(data);
          setShowSpinner(false);
        })
        .catch((error) => {
          setShowSpinner(false);
          console.error(error);
        });
    }
  };

  // Developer dashboard table data ----
  const getDeveloperDashboardTableData = (page) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    if (accessToken) {
      axios
        .get(`${API}/api_client/developer_dashboard_clients`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          const { results, count } = humps.camelizeKeys(res.data);
          setDashboardTableData(results);
          setShowSpinner(false);
        })
        .catch((error) => {
          setShowSpinner(false);
          console.error(error);
        });
    }
  };

  // developer dashboard search api ---
  const searchApiDeveloperPage = (searchVal) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    if (accessToken) {
      axios
        .get(`${API}/api_client/developer_dashboard_clients_search?value=${searchVal}`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          const { results, count } = humps.camelizeKeys(res.data);
          setDashboardTableData(results);
          setShowSpinner(false);
        })
        .catch((error) => {
          setShowSpinner(false);
          console.error(error);
        });
    }
  };

  // Api client page search api --

  const searchApiClientPage = (searchVal) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    if (accessToken) {
      axios
        .get(`${API}/api_client/search?value=${searchVal}`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          const { data } = humps.camelizeKeys(res);
          setApiClientData(data);
          setShowSpinner(false);
        })
        .catch((error) => {
          setShowSpinner(false);
          console.error(error);
        });
    }
  };

  // Api-Client-Page delete api ---
  const deleteClient = async (id) => {
    const accessToken = window.localStorage.getItem('accessToken');
    const response = await axios.delete(`${API}/api_client/${id}`, {
      headers: {
        Authorization: `Token ${accessToken}`
      }
    });
  };
  const getAllScopes = (page) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    if (accessToken) {
      axios
        .get(`${API}/api_client/client_scopes`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          const { results, count } = humps.camelizeKeys(res.data);
          setScopeApiData(results);
          setShowSpinner(false);
        })
        .catch((error) => {
          setShowSpinner(false);
          console.error(error);
        });
    }
  };

  // token Generate api ---
  const generateToken = (bodyData) => {
    axios
      .post(`${API}/api/v1/authorisation/token`, bodyData)
      .then((res) => console.log('res token', res))
      .catch((error) => console.error(error));
  };

  // client secret, client id generate api ----
  const generateClientIdClientSecret = (page) => {
    // http://develop-api.beyondqa.io/api_client/client_secret_generate
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    if (accessToken) {
      axios
        .get(`${API}/api_client/client_secret_generate`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          const { data } = humps.camelizeKeys(res);
          setClientSecretClientIdData(data);
          setShowSpinner(false);
        })
        .catch((error) => {
          setShowSpinner(false);
          console.error(error);
        });
    }
  };

  // add api client
  const createClientApi = (filterObj, id) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    if (accessToken) {
      axios
        .put(`${API}/api_client/${id}`, filterObj, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          const { results, count } = humps.camelizeKeys(res.data);
          // setScopeApiData(results)
          setShowSpinner(false);
        })
        .catch((error) => {
          setShowSpinner(false);
          console.error(error);
        });
    }
  };

  // update api client page --
  const updateClientPage = (filterObj, id) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    if (accessToken) {
      axios
        .put(`${API}/api_client/${id}`, filterObj, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          const { results, count } = humps.camelizeKeys(res.data);
          // setScopeApiData(results)
          setShowSpinner(false);
        })
        .catch((error) => {
          setShowSpinner(false);
          console.error(error);
        });
    }
  };

  // table list add api client page ----
  const getApiClientTableList = (page) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    if (accessToken) {
      axios
        .get(`${API}/api_client?page=${page}`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          const { data } = humps.camelizeKeys(res);
          setApiClientData(data);
          setShowSpinner(false);
        })
        .catch((error) => {
          setShowSpinner(false);
          console.error(error);
        });
    }
  };

  // Get groups data
  const getEventGroups = () => {
    const accessToken = window.localStorage.getItem('accessToken');
    axios
      .get(`${API}/events/get_event_groups`, {
        headers: {
          Authorization: `Token ${accessToken}`,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log(response)
        const results = response.data.data;
        dispatch({
          type: 'Event_GROUPS',
          payload: results
        });
      })
      .catch((error) => {
        // console.error(error)
      });
  };


  return (
    <AddUserContext.Provider
      value={{
        groups: state.groups,
        eventGroups: state.eventGroups,
        teams: state.teams,
        userLevels: state.userLevels,
        userNames: state.userNames,
        allUsers: state.allUsers,
        allUsersGroupsTeams: state.allUsersGroupsTeams,
        usageDetails: state.usageDetails,
        usersActivityLog: state.usersActivityLog,
        loggedUserDetails: state.loggedUserDetails,
        dailyUsageDetails: state.dailyUsageDetails,
        dailyUsageTableDetails: state.dailyUsageTableDetails,
        dailyCallsUsageTableDetails: state.dailyCallsUsageTableDetails,
        failedCallsDetails: state.failedCallsDetails,
        metaCountDetails: state.metaCountDetails,
        securityAttachementDetails: state.securityAttachementDetails,
        securityAttachementScoreDetails: state.securityAttachementScoreDetails,
        securityArchiveDetails: state.securityArchiveDetails,
        securityPwdDetails: state.securityPwdDetails,
        whitelistingDetails: state.whitelistingDetails,
        // callsDetailsInfo: state.callsDetailsInfo,
        getGroups,
        getEventGroups,
        getUserLevels,
        getTeams,
        addUser,
        editUser,
        getAllUsers,
        getAllUsersGroupsTeams,
        getUserTeamsCount,
        getUserGroupsCount,
        refreshAllUsers,
        getDeletedUsers,
        restoreUser,
        deleteUserPermanently,
        getUsage,
        searchUsersTable,
        searchDeletedUsersTable,
        deleteUser,
        deleteMultipleUser,
        downloadUsersXls,
        getUsersActivityLog,
        searchUsersActivityLog,
        filterUsersActivityLog,
        getUserNames,
        getActionTypes,
        resetPassword,
        // getCallsUsageInfo,
        blockTitle,
        setBlockTitle,
        showAddUserButton,
        setShowAddUserButton,
        showActivityButton,
        setShowActivityButton,
        showSpinner,
        setShowSpinner,
        showGroupSpinner,
        setShowGroupSpinner,
        editUserSpinner,
        setEditUserSpinner,
        exportResults,
        emailExportResults,
        getDailyUsage,
        getDailyCallsUsage,
        getMetaDataCount,
        getFailedCallsUsage,
        getDailyUsageGraph,
        showUsageFilter,
        setShowUsageFilter,
        showDailyUsage,
        setShowDailyUsage,
        usageDialogTitle,
        setUsageDialogTitle,
        downloadDailyUsageXls,
        downloadCallsUsageXls,
        downloadFailedCallsUsageXls,
        dailyUsageFilterData,
        setDailyUsageFilterData,
        showAddApiClientDialog,
        setShowAddApiClientDialog,
        open,
        setOpen,
        isAddMode,
        setIsAddMode,
        isLoading,
        setIsLoading,
        selectedActivityFilters,
        setSelectedActivityFilters,
        page,
        setPage,
        showDeletedUsers,
        setShowDeletedUsers,
        showDayWiseData,
        setShowDayWiseData,
        showDeveloperFilter,
        setShowDeveloperFilter,
        showApiClientsPage,
        setShowApiClientsPage,
        showDocumentationPage,
        setShowDocumentationPage,
        getSecurityAttachement,
        getSecurityAttachementDetails,
        showApiDialog,
        setShowApiDialog,
        showArchiveDialog,
        setShowArchiveDialog,
        showAttachmentDialog,
        setShowAttachmentDialog,
        callDate, setCallDate,
        delOpenArchive,
        setDelOpenArchive,
        addIP,
        getSecurityArchive,
        createArchive,
        deleteArchive,
        downloadArchive,
        deleteArchiveFile,
        passwordsManagement,
        updateSupportAccess,
        deleteAttachment,
        getPasswordSettings,
        deleteAttachments,
        getIpWhitelisting,
        deleteIp,

        // developer module ---
        getAllScopes,
        scopeApiData,
        setScopeApiData,
        clientSecretClientIdData,
        setClientSecretClientIdData,
        apiClientData,
        setApiClientData,
        generateClientIdClientSecret,
        openClientDialog,
        setOpenClientDialog,
        dashboardGraphData,
        setDashboardGraphData,
        selectPage,
        setSelectPage,
        dashboardTableData,
        deleteClient,
        generateToken,
        createClientApi,
        updateClientPage,
        getApiClientTableList,
        getDeveloperDashboardGraph,
        getDeveloperDashboardTableData,
        searchApiClientPage,
        searchApiDeveloperPage
      }}
    >
      {children}
    </AddUserContext.Provider>
  );
}

export default AddUserContext