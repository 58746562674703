import React from "react";
import { Box, Pagination, TablePagination, Typography, Divider } from "@material-ui/core";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    pagination: {
        '& .MuiTablePagination-actions > button:first-of-type': {
            display: 'none', // Hide the "Previous" button
        },
        '& .MuiTablePagination-actions > button:last-of-type': {
            display: 'none', // Hide the "Next" button
        },
        '& .MuiTablePagination-root': {
            minHeight: '40px',
            padding: '0px 8px'
        },
        '& .MuiTablePagination-toolbar': {
            minHeight: '40px',
            height: '40px'
        }
    },
    paginationText: {
        color: '#212668', // Change this to the color you want
    }
});
const PaginationTable = (props) => {
    const { pageRange, tablePage, count, page, handlePagination, style } = props

    const classes = useStyles();
    const handleDisplayRowText = ({ from, to, count }) => {
        return <Box sx={{ display: 'flex' }}>
            <Typography variant="body2" sx={{ color: style?.color, fontWeight: style?.fontWeight, fontSize: { xl: '15px' } }}>{from} – {to}</Typography>
            <Typography variant="body2" sx={{ mx: 0.5, color: (theme) => theme.palette.functionalityBtn, fontSize: { xl: '15px' } }}>&nbsp;of&nbsp;</Typography>
            <Typography variant="body2" sx={{ color: style?.color, fontWeight: style?.fontWeight, fontSize: { xl: '15px' } }}>{count !== -1 ? count : `more than ${to}`}</Typography>

        </Box>
    }
    return <>
        <Divider />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Showing Results
                </Typography>
                <TablePagination
                    classes={{ root: classes.pagination, displayedRows: classes.paginationText, }}
                    rowsPerPageOptions={[100]}
                    component="div"
                    count={count || 0}
                    rowsPerPage={[100]}
                    page={tablePage}
                    labelDisplayedRows={(from, to, count) => handleDisplayRowText(from, to, count)}
                    sx={{ border: 'none' }}
                />
            </>
            <Pagination
                sx={{ ml: style?.ml, color: style?.color }}
                count={pageRange}
                page={page}
                onChange={(e, pageNumber) => handlePagination(e, pageNumber)}
                variant="outlined" shape="rounded" />
        </Box>
    </>
}

export const MobileViewPagination = (props) => {
    const { pageRange, tablePage, count, page, handlePagination, style } = props

    const classes = useStyles();
    const handleDisplayRowText = ({ from, to, count }) => {
        return <Box sx={{ display: 'flex' }}>
            <Typography sx={{ color: style?.color, fontWeight: style?.fontWeight }}>{from} – {to}</Typography>
            <Typography sx={{ color: (theme) => theme.palette.functionalityBtn }}>&nbsp;of&nbsp;</Typography>
            <Typography sx={{ color: style?.color, fontWeight: style?.fontWeight }}>{count !== -1 ? count : `more than ${to}`}</Typography>

        </Box>
    }
    return <>
        <Box
        // sx={{ display: pageRange < 100 ? 'flex' : 'block', alignItems: 'center' }}
        >
            <Box
                sx={{ display: 'flex', alignItems: 'center' }}
            // sx={{ flexDirection: { lg: '', md: '', sm: '', xs: "column" } }}
            >
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Showing
                </Typography>
                <TablePagination
                    classes={{ root: classes.pagination, displayedRows: classes.paginationText, }}
                    rowsPerPageOptions={[100]}
                    component="div"
                    count={count || 0}
                    rowsPerPage={[100]}
                    page={tablePage}
                    labelDisplayedRows={(from, to, count) => handleDisplayRowText(from, to, count)}
                    sx={{ border: 'none' }}
                />
            </Box>
            <Box>
                <Pagination
                    sx={{ ml: style?.ml, color: style?.color }}
                    count={pageRange}
                    page={page}
                    onChange={(e, pageNumber) => handlePagination(e, pageNumber)}
                    variant="outlined"
                    shape="rounded"
                />
            </Box>
        </Box >
    </>
}

export default PaginationTable