import PropTypes from 'prop-types';
import { useEffect, useState, useContext } from 'react';
import { Link as RouterLink, useLocation, useSearchParams } from 'react-router-dom';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { IconButton, Box, Link, Stack, Button, Drawer, Tooltip, Typography, CardActionArea } from '@material-ui/core';
import { Close, DashboardOutlined, Assignment, Forum, DesignServices, GroupsOutlined, RateReviewOutlined, EventNoteOutlined, CenterFocusStrongOutlined, AssessmentOutlined, PersonOutlineOutlined, RequestQuoteOutlined, TrendingUpOutlined, ArrowBackIos, ArrowForwardIos, MessageOutlined, FlareOutlined, Call, Visibility, AccountTree, AutoGraph } from '@material-ui/icons';

// hooks
import useAuth from '../../../hooks/useAuth';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// context 
import { SettingsContext } from '../../../contexts/SettingsContext'
// routes
import { PATH_DASHBOARD, PATH_DOCS, PATH_EMP } from '../../../routes/paths';
// components
import Logo from '../../../components/Logo';
import MyAvatar from '../../../components/MyAvatar';
import Scrollbar from '../../../components/Scrollbar';
import NavSideSectionEmp from '../../../components/NavSideSectionEmp'
// import NavSection from '../../../components/NavSection';
import { MHidden } from '../../../components/@material-extend';
//
import { DocIllustration } from '../../../assets';

// ----------------------------------------------------------------------

const DRAWER_WIDTH_XL = 280;
const DRAWER_WIDTH_LG = 220;
const COLLAPSE_WIDTH = 60;

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.complex
        })
    }
}));

const AccountStyle = styled('div')(({ theme }) => ({
    padding: theme.spacing(2, 2.5),
    borderRadius: theme.shape.borderRadiusSm,
    backgroundColor: theme.palette.grey[500_12]
}));

// ----------------------------------------------------------------------

IconCollapse.propTypes = {
    onToggleCollapse: PropTypes.func,
    collapseClick: PropTypes.bool
};

function IconCollapse({ onToggleCollapse, collapseClick }) {




    return (
        <Tooltip title={`${collapseClick ? 'Expand' : 'Collapse'} Menu`}>
            <CardActionArea
                onClick={onToggleCollapse}
                sx={{
                    width: 16,
                    height: 16,
                    display: 'flex',
                    cursor: 'pointer',
                    // borderRadius: '50%',
                    alignItems: 'center',
                    color: 'text.primary',
                    justifyContent: 'center',
                    // border: 'solid 1px currentColor',
                    // border: 'solid 1px white',
                    ...(collapseClick && {
                        borderWidth: 2
                    })
                }}
            >
                {!collapseClick && <ArrowBackIos fontSize='small' sx={{ color: 'white' }} />}
                {collapseClick && <ArrowForwardIos fontSize='small' sx={{ color: 'white' }} />}
            </CardActionArea>
        </Tooltip>
    );
}



export default function EmployeeDashboardSidebar() {
    const pathnameIndexTwo = [
        'calibrate_performance', 'edit_calibrate_performance', 'view_calibrate_performance', 'reviewPerformance', 'editScore'
    ]
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    const isAiAccess = loggedUserData?.isAiAccess

    const empsidebarConfig = [];



    // if (loggedUserData?.isAiAccess && loggedUserData?.staff?.staffType === 10 && loggedUserData?.aiEnabled) {

    //     empsidebarConfig.push({
    //         items: [
    //             { title: 'Dashboard', path: PATH_EMP.dashboard, icon: <DashboardOutlined size='small' /> },
    //             { title: 'Scores', path: PATH_EMP.scores, icon: <RateReviewOutlined size='small' /> },
    //             { title: 'Self-Score', path: `/score/select_scorecard`, icon: <EventNoteOutlined /> },
    //             {
    //                 title: 'Tasks',
    //                 path: PATH_EMP.tasks,
    //                 icon: <Assignment />

    //             },
    //             { title: 'Comments', path: PATH_EMP.comments, icon: <Forum /> },
    //             { title: 'Activities', path: PATH_EMP.activity, icon: <DesignServices /> },
    //             { title: 'Goals', path: PATH_EMP.goals, icon: <PersonOutlineOutlined /> },
    //             {
    //                 title: 'Speech AI Hub',
    //                 path: PATH_DASHBOARD.beyondqaAi.events,
    //                 icon: <MessageOutlined size='small' />,
    //                 items: [
    //                     { title: 'Events', path: PATH_DASHBOARD.beyondqaAi.events, icon: <FlareOutlined /> },
    //                     { title: 'Calls', path: '/calls', icon: <Call /> },
    //                     { title: 'Analyze', path: '/analyze', icon: <Visibility /> },
    //                     { title: 'Auto QA Rules', path: '/auto_qa_rules', icon: <AccountTree /> },
    //                     { title: 'Coaching', path: '/coaching/add_coaching?csId=AC', icon: <AutoGraph /> }

    //                 ]
    //             },
    //         ]
    //     })

    // } else {
    empsidebarConfig.push({
        items: [
            { title: 'Dashboard', path: PATH_EMP.dashboard, icon: <DashboardOutlined size='small' /> },
            { title: 'Scores', path: PATH_EMP.scores, icon: <RateReviewOutlined size='small' /> },
            { title: 'Self-Score', path: `/score/select_scorecard`, icon: <EventNoteOutlined /> },
            {
                title: 'Tasks',
                path: PATH_EMP.tasks,
                icon: <Assignment />

            },
            { title: 'Comments', path: PATH_EMP.comments, icon: <Forum /> },
            { title: 'Activities', path: PATH_EMP.activity, icon: <DesignServices /> },
            { title: 'Goals', path: PATH_EMP.goals, icon: <PersonOutlineOutlined /> },
        ]
    })
    // }


    const [searchParams, setSearchParams] = useSearchParams();


    const { pathname } = useLocation();
    const { user } = useAuth();

    const [showIcon, setShowIcon] = useState(false);
    // CONTEXT STATES
    const { setColor, showSidebar, isOpenSidebar, setIsOpenSidebar } = useContext(SettingsContext);

    const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
        useCollapseDrawer();

    useEffect(() => {
        if (isOpenSidebar) {
            setIsOpenSidebar(false);
        }
        setShowIcon(false);
        // console.log(collapseClick)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);


    // console.log('xyz', isOpenSidebar, (!pathnameIndexTwo?.includes(pathname?.split('/')[2]) || pathname?.split('/')?.length < 2) && showSidebar);


    const renderContent = (
        <Scrollbar
        // sx={{
        //     height: 1,
        //     '& .simplebar-content': {
        //         height: 1,
        //         display: 'flex',
        //         flexDirection: 'column'
        //     }
        // }}
        >
            <Stack
                spacing={2}
                sx={{
                    px: 2.5,
                    pt: { xs: 1, lg: 3 },
                    pb: { xs: 1, lg: 2 },

                    ...(isCollapse && {
                        alignItems: { xs: 'space-between', sm: 'center' }
                    })
                }}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Box component={RouterLink} to={searchParams.get("global_dash") === `True` ? "/dashboard" : "/employee/dashboard"} sx={{ display: 'inline-flex' }}>
                        <Logo />
                    </Box>

                    <MHidden width="lgUp">
                        <IconButton onClick={() => setIsOpenSidebar(false)}  >
                            <Close sx={{ color: 'white' }} />
                        </IconButton>
                    </MHidden>

                    {showIcon && <MHidden width="lgDown">
                        {!isCollapse && <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />}
                    </MHidden>}
                </Stack>

            </Stack>

            <NavSideSectionEmp navConfig={empsidebarConfig} isShow={!isCollapse} />


            <Box sx={{ flexGrow: 1 }} />
        </Scrollbar>
    );

    const getLgWidth = (wdth) => {
        if (!pathnameIndexTwo?.includes(pathname?.split('/')[2])) {
            return isCollapse ? COLLAPSE_WIDTH : wdth
        }
        return 0
    }

    return (
        <RootStyle
            onMouseEnter={() => setShowIcon(true)}
            onMouseLeave={() => setShowIcon(false)}
            sx={{
                width: {
                    xl: getLgWidth(DRAWER_WIDTH_XL),
                    // lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH_LG,
                    lg: getLgWidth(DRAWER_WIDTH_LG),
                },
                ...(collapseClick && {
                    position: 'absolute'
                })
            }}
        >
            <MHidden width="lgUp">
                <Drawer
                    open={isOpenSidebar}
                    onClose={() => setIsOpenSidebar(false)}
                    sx={
                        {
                            flexShrink: 0, width: { xs: '60%', sm: '25%' }, '& .MuiDrawer-paper': {
                                width: { xs: '60%', sm: '25%' }, backgroundColor: (theme) => theme.palette.text.sidebarSecondaryText,
                            }, backgroundColor: (theme) => theme.palette.text.sidebarSecondaryText,
                        }

                    }
                >
                    {renderContent}
                </Drawer>
            </MHidden>

            {((!pathnameIndexTwo?.includes(pathname?.split('/')[2]) || pathname?.split('/')?.length < 2) && showSidebar) && <MHidden width="lgDown">
                <Drawer
                    open
                    variant="persistent"
                    onMouseEnter={onHoverEnter}
                    onMouseLeave={onHoverLeave}
                    PaperProps={{
                        sx: {
                            width: { xl: DRAWER_WIDTH_XL, lg: DRAWER_WIDTH_LG },
                            borderTopRightRadius: { lg: '28px', xl: '50px' },
                            borderBottomRightRadius: { lg: '25px', xl: '40px' },
                            // bgcolor: 'background.default',
                            backgroundColor: (theme) => theme.palette.text.sidebarSecondaryText,
                            ...(isCollapse && {
                                width: COLLAPSE_WIDTH,

                            }),
                            ...(collapseHover && {
                                borderRight: 0,
                                backdropFilter: 'blur(6px)',
                                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                                boxShadow: (theme) => theme.customShadows.z20,
                                // bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
                                backgroundColor: (theme) => theme.palette.text.sidebarSecondaryText,


                            })
                        }
                    }}
                >
                    {renderContent}
                    {/* <Box sx={{ position: 'relative', bottom: '10%' }}>
                <List disablePadding>
                  <ListItemStyle onClick={() => navigate('/companySettings')} sx={{
                    ...(pathname === "/companySettings" && activeRootStyle)
                  }}>
                    <ListItemIconStyle><SettingsOutlined /></ListItemIconStyle>
                    {!isCollapse && <>
                      <ListItemText sx={{ color: 'inherit', '&:hover': { color: 'inherit', fontWeight: 'fontWeightMedium' } }} disableTypography primary={`Settings ${pathname.split("/")[0]}`} />
                    </>}
                  </ListItemStyle>
                  <ListItemStyle onClick={() => handleLogout()}>
                    <ListItemIconStyle><Logout /></ListItemIconStyle>
                    {!isCollapse && <>
                      <ListItemText sx={{ color: 'inherit', '&:hover': { color: 'inherit', fontWeight: 'fontWeightMedium' } }} disableTypography primary='Logout' />
                    </>}
                  </ListItemStyle>
                </List>
    
              </Box> */}
                </Drawer>
            </MHidden>}
        </RootStyle>
    );
}
