import { Stack, Typography, CircularProgress } from "@material-ui/core";
import SearchNotFound from "../../components/SearchNotFound";

export default function GlobalLoaders({ query, isLoading, sx, variant }) {
    return (<>
        <Stack direction='row' display="flex"
            justifyContent="center"
            alignItems="center" sx={{ height: { lg: variant === 'l1' ? `calc(100vh - 438px)` : `calc(100vh - 447px)` }, width: '100%', ...sx }}>

            {isLoading ?
                <Stack direction='row' display="flex"
                    justifyContent="center"
                    alignItems="center">
                    <CircularProgress />&nbsp;<Typography sx={{ fontSize: { lg: '14px', xl: '16px' } }} >Loading Data...</Typography>
                </Stack>
                : <>
                    <SearchNotFound searchQuery={query} />
                </>}

        </Stack>
    </>)
}