import React, { useState } from 'react';
import { Button, Box, Menu, MenuItem, Stack, Typography } from '@material-ui/core';
import { KeyboardArrowDown, Poll, Groups, Dns } from "@material-ui/icons";
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { PATH_DASHBOARD } from "../../../routes/paths";


// CODITIONS
const isSup = [20, 30];
const isScore = "Global Admin,Group Admin,Team Admin,Internal,Score & Reports,(Score, Reports & Analytics),(Score, Calibrate, Report & Analytics),Score Only,Score & Calibrate"
const isStaff = "Global Admin,Group Admin,Team Admin,Internal,Advanced Supervisor,Supervisor,Employee"
const isScorecard = "Global Admin,Group Admin,Internal"
const isAnalytics = "Global Admin,Group Admin,Team Admin,(Score, Reports & Analytics),(Score, Calibrate, Report & Analytics),Reports & Analytics"
const isReports = "Global Admin,Group Admin,Team Admin,Internal,Score & Reports,(Score, Reports & Analytics),(Score, Calibrate, Report & Analytics),Reports & Analytics,Reports Only"
const isCalibrate = "Global Admin,Group Admin,Team Admin,Score & Calibrate,Calibrate Only,(Score, Calibrate, Report & Analytics)"
const isAdmin = 'Global Admin,Group Admin,Team Admin,Internal'
const isPerformanceOverview = ["score", "scorecards"];
const isTeamAdministrator = "staff, 'admin";
const isDataHub = "calibration',reports, analytics";



export default function SupNavBarMenuDropDown() {
    const [searchParams] = useSearchParams();
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    const grpArr = loggedUserData?.groups?.map(obj => obj.id);


    const menuItemsArrays = [
        { indexID: 1, label: 'Evaluate', isActive: 'score', link: searchParams.get("stff_sup_id") ? PATH_DASHBOARD.general.score.index : `/score/select_emp?id=${grpArr.join(",")}&sup_dash=True`, iconSrc: "/static/illustrations/navbar/score_dd.svg", toShow: ((isScore.includes(loggedUserData?.userLevel?.name) || isSup?.includes(loggedUserData?.staff?.staffType))) },
        { indexID: 2, label: 'Staff', isActive: 'staff', link: PATH_DASHBOARD.general.Staff, iconSrc: "/static/illustrations/navbar/staff_dd.svg", toShow: (isStaff.includes(loggedUserData?.userLevel?.name) || isSup?.includes(loggedUserData?.staff?.staffType)) },
        { indexID: 1, label: 'Scorecard', isActive: 'scorecards', link: PATH_DASHBOARD.general.Scorecards, iconSrc: "/static/illustrations/navbar/scorecard_dd.svg", toShow: (!loggedUserData?.staff && (isScorecard.includes(loggedUserData?.userLevel?.name)) || loggedUserData?.staff?.staffType === 30) },
        { indexID: 3, label: 'Calibration', isActive: 'calibration', link: PATH_DASHBOARD.general.calibration.index, iconSrc: "/static/illustrations/navbar/calibration_dd.svg", toShow: (isCalibrate.includes(loggedUserData?.userLevel?.name) || isSup?.includes(loggedUserData?.staff?.staffType)) },
        { indexID: 3, label: 'Reports', isActive: 'reports', link: PATH_DASHBOARD.general.reports, iconSrc: "/static/illustrations/navbar/reports_dd.svg", toShow: (isReports.includes(loggedUserData?.userLevel?.name) || isSup?.includes(loggedUserData?.staff?.staffType)) },
        { indexID: 3, label: 'Analytics', isActive: 'analytics', link: PATH_DASHBOARD.general.analytics, iconSrc: "/static/illustrations/navbar/analytics_dd.svg", toShow: (isAnalytics.includes(loggedUserData?.userLevel?.name) || isSup?.includes(loggedUserData?.staff?.staffType)) },
        {
            indexID: 2, label: 'Admin', isActive: 'admin', link: PATH_DASHBOARD.general.admin, iconSrc: "/static/illustrations/navbar/admin_dd.svg", toShow: (searchParams.get("stff_sup_id") && isScorecard.includes(loggedUserData?.userLevel?.name))
        },
    ]

    const navbarItemsArrays = [
        { indexID: 1, label: 'Performance Overview', isActive: isPerformanceOverview, link: PATH_DASHBOARD.general.score.index, iconSrc: "/static/illustrations/navbar/score.svg", toShow: ((isScore.includes(loggedUserData?.userLevel?.name) || isSup?.includes(loggedUserData?.staff?.staffType)) || (!loggedUserData?.staff && (isScorecard.includes(loggedUserData?.userLevel?.name)) || loggedUserData?.staff?.staffType === 30)) },
        { indexID: 2, label: 'Team Administration', isActive: isTeamAdministrator, link: PATH_DASHBOARD.general.Staff, iconSrc: "/static/illustrations/navbar/staff.svg", toShow: ((isStaff.includes(loggedUserData?.userLevel?.name) || isSup?.includes(loggedUserData?.staff?.staffType)) || (searchParams.get("stff_sup_id") && isScorecard.includes(loggedUserData?.userLevel?.name))) },
        { indexID: 3, label: 'Data Hub', isActive: isDataHub, link: PATH_DASHBOARD.general.Scorecards, iconSrc: "/static/illustrations/navbar/scorecard.svg", toShow: ((isCalibrate.includes(loggedUserData?.userLevel?.name) || isReports.includes(loggedUserData?.userLevel?.name) || isAnalytics.includes(loggedUserData?.userLevel?.name)) || isSup?.includes(loggedUserData?.staff?.staffType)) }

    ]

    const getMenuIcon = (indexId, isActive) => {
        if (indexId === 1) {
            return <Poll sx={{ width: '28px', height: '28px', mx: 0, color: theme => isActive ? theme.palette.action.selected : theme.palette.functionalityBtn }} />
        }

        if (indexId === 2) {
            return <Groups sx={{ width: '28px', height: '28px', mx: 0, color: theme => isActive ? theme.palette.action.selected : theme.palette.functionalityBtn }} />
        }

        return <Dns sx={{ width: '25px', height: '25px', mx: 0, color: theme => isActive ? theme.palette.action.selected : theme.palette.functionalityBtn }} />
    }

    // REACT STATES
    const { pathname } = useLocation();
    const [menuIndex, setMenuIndex] = useState();
    const [anchorEL, setAnchorEl] = useState(null)
    const openEl = Boolean(anchorEL);

    const handleOpenMenu1 = (e, indexId) => {
        setAnchorEl(e?.currentTarget)
        setMenuIndex(indexId)
    };

    const handleCloseMenu1 = () => {
        setAnchorEl(null);
        setMenuIndex()
    };



    return (
        <>
            {
                navbarItemsArrays?.map(obj => {
                    if (obj?.toShow) {
                        return (
                            <Button sx={{ mx: 2, "& .MuiButton-startIcon": { marginRight: "0px" }, '&:hover': { backgroundColor: 'transparent' } }} startIcon={getMenuIcon(obj?.indexID, (obj?.isActive?.includes(pathname.split("/")[1])))} endIcon={<KeyboardArrowDown fontSize='small' sx={{ color: theme => (obj?.isActive?.includes(pathname.split("/")[1])) ? theme.palette.action.selected : theme.palette.functionalityBtn }} />} disableRipple onClick={(e) => handleOpenMenu1(e, obj?.indexID)} >
                                <Typography sx={{ color: theme => (obj?.isActive?.includes(pathname.split("/")[1])) ? theme.palette.action.selected : theme.palette.functionalityBtn, fontSize: { lg: '12px', newLg: '13px', xl: '15px' }, fontWeight: 'bold', pl: 1 }} >{obj?.label}</Typography>
                            </Button>
                        )
                    } return null
                })
            }

            <Menu
                open={openEl}
                anchorEl={anchorEL}
                onClose={() => handleCloseMenu1()}
                PaperProps={{
                    sx: { width: menuIndex === 1 ? 230 : 200, zIndex: 1300, }
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}>

                {
                    menuItemsArrays?.map(obj => {
                        if (menuIndex === obj?.indexID && obj?.toShow) {
                            return (
                                <Link style={{ textDecoration: 'none', color: '#000000' }} to={obj?.link}>
                                    <MenuItem sx={{ minWidth: '150px', typography: 'body2', py: 1, px: 2.5 }} onClick={() => { handleCloseMenu1(); }}>

                                        <Stack direction='flex' alignItems='center'>
                                            <Box component='img' alt="Custom SVG Icon" src={obj?.iconSrc} sx={{ mr: 2, width: { lg: '18px', newLg: '18px' }, height: { lg: '18px', newLg: '18px' } }} />

                                            <Typography sx={{ fontWeight: 'bold', color: '#00000', fontSize: { lg: '11px', xl: '13px' } }} >
                                                {obj?.label}</Typography>
                                        </Stack>
                                    </MenuItem>
                                </Link>
                            )
                        } return null
                    })
                }
            </Menu>
        </>
    )
}