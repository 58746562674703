import React, { useEffect, useRef, useState } from 'react';
import { gsap, Bounce } from 'gsap';
import '../assets/NewYearAnimation.css'; // Import the CSS from the original code

const NewYearAnimation = () => {
  const [period, setPeriod] = useState({ year: 0, days: 0, hours: 0, minutes: 0, seconds: 0 });
  const newYearRef = useRef(null);

  console.log('NewYearAnimation mounted');

  useEffect(() => {
    const calculatePeriod = () => {
      const dateFuture = new Date(new Date().getFullYear() + 1, 0, 1);
      const dateNow = new Date();
      const seconds = Math.floor((dateFuture - dateNow) / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      setPeriod({
        year: dateFuture.getFullYear(),
        days,
        hours: hours % 24,
        minutes: minutes % 60,
        seconds: seconds % 60,
      });
      console.log('State updated:', { days, hours, minutes, seconds }); // Debugging line
    };

    const interval = setInterval(calculatePeriod, 1000);
    calculatePeriod(); // Initial call

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const newYear = newYearRef.current;
    if (!newYear) return;

    const createDigitAnimation = (parent, digit) => {
      const timeline = gsap.timeline();
      const digitDiv = document.createElement('div');
      digitDiv.className = 'digit';
      digitDiv.innerText = digit;
      digitDiv.style.top = '-200%';
      parent.appendChild(digitDiv);

      timeline.to(digitDiv, { duration: 0.5, top: 0, opacity: 1, ease: Bounce.easeOut });
    };

    const createYear = (className) => {
      const yearDiv = document.createElement('div');
      yearDiv.className = className;
      newYear.appendChild(yearDiv);

      String(period.year).split('').forEach((digit) => {
        createDigitAnimation(yearDiv, digit);
      });
      return yearDiv;
    };

    createYear('year year1');
    createYear('year year2');

    // Add more animations as necessary
  }, [period.year]);

  const pluralize = (value, label) => {
    const paddedValue = String(value).padStart(2, '0');
    return `${paddedValue} ${label}${value !== 1 ? 's' : ''}`;
  };

  console.log('Rendering with period:', period); // Log the current period

  return (
    <div className="holder">
      <div className="new-year" ref={newYearRef}>
        <div className="controls">
          <div className="control days">{pluralize(period.days, 'day')}</div>
          <div className="control hours">{pluralize(period.hours, 'hour')}</div>
          <div className="control minutes">{pluralize(period.minutes, 'minute')}</div>
          <div className="control seconds">{pluralize(period.seconds, 'second')}</div>
        </div>
        <div>Debug: {JSON.stringify(period)}</div>
      </div>
    </div>
  );
};

export default NewYearAnimation;
