import { useState, useContext, useEffect } from 'react';
// MATERIAL IMPORTS
import {
    Autocomplete, Avatar,
    Box, Button,
    CircularProgress,
    Dialog, DialogTitle, DialogContent, DialogActions,
    IconButton,
    Stack,
    TextField, Typography,
} from '@material-ui/core';
import { Close, GroupAdd } from '@material-ui/icons';
// NPM IMPORTS
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack5';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
// API IMPORTS
import { newGroupAPI, editGroupAPI, getGroupCityNameApi } from '../../../../../../_apis_/authApi.js/staffApi';
import { MIconButton } from '../../../../../../components/@material-extend';
// REDUX ACTION IMPORTS 
import { newGroup, editGroup, getListAllGrp, getAllGroups } from '../../../../../../redux/action/staffAction';
// CONTEXT IMPORTS
import { StaffContext } from '../../../../../../contexts/staffContext';
import { SettingsContext } from '../../../../../../contexts/SettingsContext';
import GlobalButton from '../../../../../Common Component/GlobalButton';
// HOOKS
import useAuth from '../../../../../../hooks/useAuth';





export default function GroupDialog(type) {
    // CONTEXT STATES
    const { getGroups, getTeams, isGrpOpen, setGrpOpen, grpId, setGrpId } = useContext(StaffContext);
    const { setColor } = useContext(SettingsContext);

    // REDUX STATES
    const dispatch = useDispatch();

    // REACT STATES
    const [grpTeam, setGrpTeam] = useState([]);
    const [disBtn, setDisBtn] = useState(false)
    const [cityDetailsData, setCityDetailsData] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [locationValue, setLocationValue] = useState("");

    // SNACKBAR STATES
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // find location function
    const handleFindLocation = async (string) => {
        if (string.length > 0 && string !== formik?.values?.location) {
            const { data } = await getGroupCityNameApi(string);
            setCityDetailsData(data?.data);
        }
    }

    // FORMIK STATES
    const formik = useFormik({
        initialValues: { GrpName: '', desc: '', location: '', notes: null },
        validationSchema: Yup.object({

            GrpName: Yup.string().trim().required('*Required'),
            location: Yup.string().required('*Required'),
        }),
        onSubmit: values => {


            async function edit() {
                setDisBtn(true)
                try {

                    const newObj = {
                        name: values.GrpName?.trim(),
                        description: values.desc?.trim(),
                        location: values.location,
                        notes: values.notes?.trim(),
                        is_active: true,
                        is_deleted: false
                    }

                    const { data } = await editGroupAPI(grpId, newObj);
                    dispatch(editGroup(data))
                    dispatch(getListAllGrp())
                    setDisBtn(false)
                    enqueueSnackbar('Updated Group Successfully', {
                        variant: 'success',
                        action: (key) => (

                            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </MIconButton>
                        )
                    });
                    // console.log(data);
                    dispatch(getAllGroups());
                    handleClose();
                } catch (err) {
                    if (!navigator.onLine) {
                        enqueueSnackbar('No Internet Connection Found', {
                            variant: 'error',
                            action: (key) => (

                                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                    <Icon icon={closeFill} />
                                </MIconButton>
                            )
                        });
                        return setDisBtn(false);
                    }
                    enqueueSnackbar(`${err.response.data.message}`, {
                        variant: 'error',
                        action: (key) => (

                            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </MIconButton>
                        )
                    });
                    setDisBtn(false);
                }

            }

            if (grpId) return edit();

            async function addNew() {
                setDisBtn(true)
                try {
                    const newObj = {
                        name: values.GrpName?.trim(),
                        description: values.desc?.trim(),
                        location: values.location,
                        notes: values.notes?.trim() || null,
                        is_active: true,
                        is_deleted: false
                    }

                    const { data } = await newGroupAPI(newObj);
                    dispatch(newGroup(data))
                    dispatch(getListAllGrp());
                    setDisBtn(false)
                    enqueueSnackbar('Added Group Successfully', {
                        variant: 'success',
                        action: (key) => (

                            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </MIconButton>
                        )
                    });
                    dispatch(getAllGroups());
                    handleClose();

                } catch (err) {
                    if (!navigator.onLine) {
                        enqueueSnackbar('No Internet Connection Found', {
                            variant: 'error',
                            action: (key) => (

                                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                    <Icon icon={closeFill} />
                                </MIconButton>
                            )
                        });
                        return setDisBtn(false);
                    }
                    enqueueSnackbar(`${err.response.data.message}`, {
                        variant: 'error',
                        action: (key) => (

                            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </MIconButton>
                        )
                    });
                    setDisBtn(false);
                }


            }

            addNew()


        }
    })

    useEffect(async () => {
        if (grpId) {
            const group = getGroups?.find((obj) => {
                if (obj?.id === grpId) {
                    return obj;
                }
                return null
            })
            formik.setFieldValue('GrpName', group.name)
            formik.setFieldValue('desc', group.description)
            formik.setFieldValue('notes', group.notes)

            if (group.location !== undefined) {
                const { data } = await getGroupCityNameApi(group.location);
                setCityDetailsData(data?.data);
                setLocationValue(data?.data?.find((option) => option?.location === group.location))
                formik.setFieldValue('location', group.location)

            }


            // setGrpTeam([...group.teams]);
        }

    }, [isGrpOpen])


    const handleClose = () => {
        setGrpOpen(false)
        setGrpId(null);
        setLocationValue("");
        setCityDetailsData([]);
        setInputValue("");
        formik.resetForm();
    };

    const handleOpenForm = () => {
        setGrpOpen(true);
    }

    return (
        <div>
            <GlobalButton className="halloween-animation"
                label='Add Group'
                variant="contained"
                sx={{ px: '30px' }}
                startIcon={
                    <GroupAdd
                        sx={{
                            color: '#fff',
                            // stroke: (theme) => theme.palette.background.buttonPaper, strokeWidth: '1px'
                        }}
                    />
                }

                fn={
                    handleOpenForm
                }
            />




            <Dialog open={isGrpOpen} maxWidth="sm" fullWidth >
                <form onSubmit={formik.handleSubmit}>
                    <Stack sx={{ py: 1, pr: 1, borderBottom: theme => `2px solid ${theme.palette.text.primaryBolderBorder}` }} direction="row" alignContent="center" alignItems="center" justifyContent="space-between">
                        <DialogTitle> <h3>{grpId ? "Edit Group" : "Add New Group"}</h3></DialogTitle>
                        <IconButton sx={{ mt: 1 }} onClick={() => { handleClose(); }}><Close /></IconButton>
                    </Stack>
                    {/* <Divider /> */}
                    <DialogContent sx={{ pt: 3, pb: 2 }}>

                        <Stack alignItems="center" direction="row" sx={{ pt: 2 }}>
                            <Stack direction="column" sx={{ width: grpId ? "55%" : "100%" }} spacing={2}>
                                <Box sx={{ width: "100%" }}>
                                    <TextField
                                        fullWidth
                                        // label="Group Name"
                                        label={<>
                                            Group Name
                                            <Typography variant='body' sx={{ color: setColor?.main, fontWeight: "bold" }}>&nbsp;*</Typography>
                                        </>}
                                        name="GrpName"
                                        {...formik.getFieldProps('GrpName')}

                                    />
                                    {formik.errors.GrpName && formik.touched.GrpName ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.GrpName}</Typography> : null}
                                </Box>
                                <Box sx={{ width: "100%" }}>
                                    <TextField
                                        fullWidth
                                        label="Description"
                                        name="desc"
                                        {...formik.getFieldProps('desc')}

                                    />
                                    {formik.errors.desc && formik.touched.desc ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.desc}</Typography> : null}
                                </Box>

                            </Stack>
                            <Stack justifyContent="center" direction="row" sx={{ width: "45%", display: grpId ? "flex" : "none" }}>
                                <Avatar sx={{ width: 120, height: 120 }} />
                            </Stack>
                        </Stack>
                        <Box sx={{ width: "100%", mt: 3, mb: 2 }}>
                            {/* <TextField
                                fullWidth
                                // label="Location"
                                label={<>
                                    Location
                                    <Typography variant='body' sx={{ color: setColor?.main, fontWeight: "bold" }}>&nbsp;*</Typography>
                                </>}
                                name="location"
                                {...formik.getFieldProps('location')}

                            /> */}
                            <Autocomplete
                                fullWidth
                                sx={{ my: 2, width: "100%" }}
                                id="tags-outlined"
                                value={locationValue || ''}
                                inputValue={inputValue}
                                options={cityDetailsData}
                                // value={allSup?.map((item, index) => { if (formik.values?.newSupId === item?.id) { return allSup[index] } return null })?.filter(item => item !== null)?.join("")}
                                getOptionLabel={(option) => option?.location || ""}
                                onChange={(event, newValue) => {
                                    if (newValue === null) {
                                        formik.setFieldValue('location', '')
                                        return setLocationValue('')
                                    }
                                    setLocationValue(cityDetailsData?.find((option) => option?.location === newValue?.location));
                                    if (newValue?.location) {
                                        formik.setFieldValue('location', newValue?.location)
                                    }
                                    // console.log(newValue);
                                }}
                                onInputChange={(event, newInputValue) => {
                                    // each key stroke call location API
                                    handleFindLocation(newInputValue);
                                    setInputValue(newInputValue)


                                }}
                                // filterSelectedOptions
                                // renderTags={(value, getTagProps) =>
                                //     value.map((option, index) => {
                                //         console.log(option)
                                //         return < Chip variant="outlined" label={option.name} {...getTagProps({ index })} />

                                //     })
                                // }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Location*"
                                        fullWidth
                                        sx={{ width: "100%" }}
                                    // helperText={selectedGrp?.length > 0 ? " " : "Select a group first."}

                                    />
                                )}

                            />
                            {formik.errors.location && formik.touched.location ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.location}</Typography> : null}
                        </Box>
                        <Box sx={{ width: "100%", mt: 3, mb: 1 }}>
                            <TextField
                                fullWidth
                                label="Notes"
                                multiline
                                rows={4}
                                name="notes"
                                {...formik.getFieldProps('notes')}
                            />
                            {formik.errors.notes && formik.touched.notes ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.notes}</Typography> : null}

                        </Box>

                    </DialogContent>
                    <DialogActions>
                        <Button sx={{ color: theme => theme.palette.text.buttonText }} onClick={() => { handleClose(); }} color="inherit">
                            Cancel
                        </Button>
                        <Button className="halloween-animation" sx={{ borderRadius: '7px', border: '1px solid #dddde7', boxShadow: 'none', color: theme => theme.palette.text.buttonText, backgroundColor: theme => theme.palette.background.paper, '&:hover': { color: theme => theme.palette.text.buttonText, backgroundColor: theme => theme.palette.background.paper } }} type='submit' variant="contained" disabled={disBtn} >
                            {grpId ? "Edit Group" : "Add Group"}
                            {disBtn && <CircularProgress size={14} sx={{ ml: 1 }} />}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
